import { defineStore } from 'pinia'
import { useURLS } from '@/stores/URLS.js'
import { useReportCore } from '@/stores/ReportCore.js'
import { useDataKPI2Store } from '@/stores/DataKPI2Store.js'
import { useDataReportKPIVerbose } from '@/stores/DataReportKPIVerbose.js'
import { useBuildMetricDataScoreCards } from '@/scripts/utils/BuildMetricDataScoreCards.js'
import { useBuildMetricDataTables2 } from '@/scripts/utils/BuildMetricDataTables2.js'
import { useBuildMetricDataTimeline2 } from '@/scripts/utils/BuildMetricDataTimeline2.js'
import { useErrorHandler } from '@/stores/ErrorHandler.js'
import { shallowRef, markRaw, toRaw } from 'vue'
import { useAuth } from '@/stores/Auth.js'
import { useDataBenchmarkLeadGroupStore } from '@/stores/DataBenchmarkLeadGroupStore.js'
import { useDataPresetsReportFiltersStore } from '@/stores/DataPresetsReportFiltersStore.js'
export const useReportKPI2 = defineStore('ReportKPI2', {
  state: () => {
    return {
    

      dataBenchmarkLeadGroupStore: markRaw(useDataBenchmarkLeadGroupStore()),
     
      reportElementProps: markRaw([
        'APPOINTMENTS_COUNT',
        'ISSUED_APPOINTMENTS_COUNT',
        'QUOTES_COUNT',
        'SALES_COUNT',
        'SALES_SUM'
      ]),

   

      tableDataOwnersObj: null,
      tableDataSalesPersonsObj: null,
      tableDataLocationsObj: null,
      tableDataLeadGroupsObj: null,
      metrics: null,


      reportCore: markRaw(useReportCore()),
      dataKPI2Store: markRaw(useDataKPI2Store()),
      dataPresetsReportFiltersStore:markRaw(useDataPresetsReportFiltersStore()),
      dataReportKPIVerbose:markRaw(useDataReportKPIVerbose()),
     
     
      section1Expanded: false,
      ownerFilter: null,
      ownerFilterAlt:null,
      salesPersonFilter: null,
      salesPersonUnfilteredFilter: null,

      locationFilter: null,
      leadGroupFilter: null,
     
      scoreCards: shallowRef({}),     

      showDebugData: false,

      scorecardLabels: null,

   

      dataTableOwners: {},
      dataTableSalesPersons: {},
      dataTableSalesPersonsUnfiltered: {},
      dataTableGroups: {},
      dataTableLocations: {},

      timelineSingleMetricSet1: null,
      timelineSingleMetricSet2: null,

      dataTableDrillDownCORE: {},
      dataTableDrillDownSM: {},

      tableColumnsMetrics: markRaw([
        'Amount',
        'Sales',
        'Issued Closing Rate',
        'Sales Avg',
        'Appointments Issued',
        'Quotes',
        'Closing Rate',
        'Demo Rate',
        'NSLI',
      ]),

      currentLineChartMetric: 'Amount',
      timelineMetrics: markRaw({}),
      timelineData: {
        labels: [
          'January',
          'February',
          'March',
          'April',
          'May',
          'June',
          'July',
          'August',
          'September',
          'October',
          'November',
          'December'
        ],
        datasets: [
          {
            label: new Date().getFullYear(),
            fill: false,
            lengendBlockStyle: 'width:20px;height:20px;background-color:#ad84aa;',
            borderColor: '#ad84aa',
            yAxisID: 'y',
            tension: 0,
            pointRadius: 6,
            data: []
          },
          {
            label: new Date().getFullYear() - 1,
            fill: false,
            lengendBlockStyle: 'width:20px;height:20px;background-color:#6b7887;',
            borderColor: '#6b7887',
            yAxisID: 'y',
            tension: 0,
            pointRadius: 6,
            data: []
          }
        ]
      }
    }
  },

  actions: {
    changeTimelineMetric(metric) {
      this.currentLineChartMetric = metric
      this.timelineSingleMetricSet1 = this.timelineMetrics[metric][0]
      this.timelineSingleMetricSet2 = this.timelineMetrics[metric][1]
      this.timelineData.datasets[0].data = this.timelineMetrics[metric][0].map(
        (dataPoint) => dataPoint.value
      )
      this.timelineData.datasets[1].data = this.timelineMetrics[metric][1].map(
        (dataPoint) => dataPoint.value
      )
    },
   

    convertReportValuesToDailyAverage() {
      //if data is to be viewed as daily average ,divide by reportcore rangeLengthExSundays
      /* this.ownerTableDataMap.forEach((value) => {
         value.forEach((value2, key2) => {
           if (!isNaN(value2)) {
             value2 /= this.reportCore.rangeLengthExSundays
             value.set(key2, value2)
           }
         })
       })
       this.locationTableDataMap.forEach((value) => {
         value.forEach((value2, key2) => {
           if (!isNaN(value2)) {
             value2 /= this.reportCore.rangeLengthExSundays
             value.set(key2, value2)
           }
         })
       })
       this.leadGroupTableDataMap.forEach((value) => {
         value.forEach((value2, key2) => {
           if (!isNaN(value2)) {
             value2 /= this.reportCore.rangeLengthExSundays
             value.set(key2, value2)
           }
         })
       })
 
       for (let prop in this.metrics.rangeCurrent) {
         if (prop === 'DATE' || prop === 'OWNER' || prop === 'LOCATION' || prop === 'LEAD_GROUP') {
           continue
         }
         let item = this.metrics.rangeCurrent[prop] / this.reportCore.rangeLengthExSundays
         this.metrics.rangeCurrent[prop] = item
       }
 
       for (let prop in this.metrics.rangeSub1) {
         if (prop === 'DATE' || prop === 'OWNER' || prop === 'LOCATION' || prop === 'LEAD_GROUP') {
           continue
         }
         let item = this.metrics.rangeSub1[prop] / this.reportCore.rangeLengthExSundays
         this.metrics.rangeSub1[prop] = item
       }
 
       for (let prop in this.metrics.rangeSub2) {
         if (prop === 'DATE' || prop === 'OWNER' || prop === 'LOCATION' || prop === 'LEAD_GROUP') {
           continue
         }
         let item = this.metrics.rangeSub2[prop] / this.reportCore.rangeLengthExSundays
         this.metrics.rangeSub2[prop] = item
       }
 
       for (let prop in this.timelineMetricsTemp) {
         let tlm = this.timelineMetricsTemp[prop]
         for (let i = 0; i < 12; i++) {
           tlm[0][i] /= this.reportCore.rangeLengthExSundays
           tlm[1][i] /= this.reportCore.rangeLengthExSundays
         }
       }*/
    },

    //-------------------------------------------------------------------------------------------------

    buildReportProcess7() {
      let tableDataOwners = []
      let tableDataSalesPersons = []
      let tableDataSalesPersonsUnfiltered = []
      let tableDataLocations = []
      let tableDataLeadGroups = []

     // console.log(this.tableDataOwnersObj)

      for (let ownerName in this.tableDataOwnersObj) {
        let ownerItem = this.tableDataOwnersObj[ownerName]
        ownerItem.data.OWNER = ownerName
        if (this.ownerFilter) {
          if (ownerName === this.ownerFilter) {
            this.buildMetricsTables(ownerItem.data, tableDataOwners)
          }
        } else {
          this.buildMetricsTables(ownerItem.data, tableDataOwners)
        }

        if (!ownerItem.isMapped) {
          let unmappedItem = this.dataKPI2Store.unmappedKPIOwnersCORE[ownerName]
          let unmappedItem2 = this.dataKPI2Store.unmappedKPIOwnersSM[ownerName]
          if (unmappedItem) {
            unmappedItem.props.sales.value = this.reportCore.formatCurrency(
              ownerItem.data.SALES_SUM
            )
          }
          if (unmappedItem2) {
            unmappedItem2.props.sales.value = this.reportCore.formatCurrency(
              ownerItem.data.SALES_SUM
            )
          }
        }
      }

      //console.log(this.tableDataSalesPersonsObj)

      for (let salesPersonName in this.tableDataSalesPersonsObj) {
        let salesPersonItem = this.tableDataSalesPersonsObj[salesPersonName]
        salesPersonItem.data.SALES_PERSON = `${salesPersonName} (${salesPersonItem.ownerName})`
        salesPersonItem.data.SALES_PERSON_RAW = salesPersonName
        salesPersonItem.data.OWNER = salesPersonItem.ownerName

        if (this.ownerFilter) {
          if (salesPersonItem.ownerName === this.ownerFilter) {
            if (this.salesPersonFilter) {
              if (salesPersonName === this.salesPersonFilter) {
                this.buildMetricsTables(salesPersonItem.data, tableDataSalesPersons)
              }
            } else {
              this.buildMetricsTables(salesPersonItem.data, tableDataSalesPersons)
            }
          }
        } else {
          if (this.salesPersonFilter) {
            if (salesPersonName === this.salesPersonFilter) {
              this.buildMetricsTables(salesPersonItem.data, tableDataSalesPersons)
            }
          } else {
            this.buildMetricsTables(salesPersonItem.data, tableDataSalesPersons)
          }
        }
        if (this.salesPersonUnfilteredFilter) {
          if (salesPersonName === this.salesPersonUnfilteredFilter) {
            this.buildMetricsTables(salesPersonItem.data, tableDataSalesPersonsUnfiltered)
          }
        } else {
          this.buildMetricsTables(salesPersonItem.data, tableDataSalesPersonsUnfiltered)
        }
      }

      for (let locationName in this.tableDataLocationsObj) {
        let locationItem = this.tableDataLocationsObj[locationName]
        locationItem.data.LOCATION = locationName
        this.buildMetricsTables(locationItem.data, tableDataLocations)
      }

      for (let leadGroupName in this.tableDataLeadGroupsObj) {
        let leadGroupItem = this.tableDataLeadGroupsObj[leadGroupName]
        leadGroupItem.data.LEAD_GROUP = leadGroupName
        this.buildMetricsTables(leadGroupItem.data, tableDataLeadGroups)

        if (!leadGroupItem.isMapped) {
          let unmappedItem = this.dataKPI2Store.unmappedKPILeadGroupsCORE[leadGroupName]
          let unmappedItem2 = this.dataKPI2Store.unmappedKPILeadGroupsSM[leadGroupName]
          if (unmappedItem) {
            unmappedItem.props.sales.value = this.reportCore.formatCurrency(
              leadGroupItem.data.SALES_SUM
            )
          }
          if (unmappedItem2) {
            unmappedItem2.props.sales.value = this.reportCore.formatCurrency(
              leadGroupItem.data.SALES_SUM
            )
          }
        }
      }

      this.dataTableOwners.tableData = tableDataOwners
      this.dataTableSalesPersons.tableData = tableDataSalesPersons
      this.dataTableSalesPersonsUnfiltered.tableData = tableDataSalesPersonsUnfiltered
      this.dataTableGroups.tableData = tableDataLeadGroups

      this.dataKPI2Store.buildDebugInfo()

      this.buildMetricsTimeline(this.timeLineData)
      this.changeTimelineMetric(this.currentLineChartMetric)
      this.buildScoreCards(this.metrics)
    },

   
  

    //-------------------------------------------------------------------------------------------------

    buildReportProcess6(
      ownerKPIObj,
      locationName,
      salesPersonName,
      leadGroupName,
      dateBeingProcessed,
      dateRangesValidities
    ) {
      if (this.dataPresetsReportFiltersStore.useDimensionFilters) {
        if (this.dataPresetsReportFiltersStore.getPresetData.useDimensionFilterOwner) {
          let systemOwner = this.dataPresetsReportFiltersStore.getPresetData.dataOwnersNoAliases[ownerKPIObj.name]
          if (systemOwner) {
            let selected = systemOwner.selected
            if (this.dataPresetsReportFiltersStore.getPresetData.isInclusive) {
              if (!selected) {
                return
              }
            }
            if (this.dataPresetsReportFiltersStore.getPresetData.isExclusive) {
              if (selected) {
                return
              }
            }
          } else {
            //if useDimensionFilters is true and no system owner , i have to ignore the owner as filtering will possibly fail
            return
          }
        }

        

        //-------------------------------------

        if (this.dataPresetsReportFiltersStore.getPresetData.useDimensionFilterLeadGroup) {
          let systemLeadGroup = this.dataPresetsReportFiltersStore.getPresetData.dataLeadGroupsNoAliases[leadGroupName]
          if (systemLeadGroup) {
           
            let selected = systemLeadGroup.selected
            if (this.dataPresetsReportFiltersStore.getPresetData.isInclusive) {
              if (!selected) {
                return
              }
            }
            if (this.dataPresetsReportFiltersStore.getPresetData.isExclusive) {
              if (selected) {
                return
              }
            }
          } else {
           
            //if useDimensionFilters is true and no system leadGroup , i have to ignore the leadGroup as filtering will possibly fail
            return
          }
        }
      }

      let locationKPIObj =
        ownerKPIObj.years[dateBeingProcessed.year].months[dateBeingProcessed.month].days[
          dateBeingProcessed.day
        ].locations[locationName]

      let salesPersonKPIObj = locationKPIObj.salesPersons[salesPersonName]

      let leadGroupKPIObj = salesPersonKPIObj.leadGroups[leadGroupName]
      if (!leadGroupKPIObj) {
        return
      }
      let ownerStorage = null
      let salesPersonStorage = null
      let locationStorage = null
      let leadGroupStorage = null

      let salesPersonFilterToUse = this.salesPersonFilter || this.salesPersonUnfilteredFilter

      if (dateRangesValidities.isDateRangeValidCurrent) {
        ownerStorage = this.tableDataOwnersObj[ownerKPIObj.name]
        let createOwnerStorage = false
        if (!ownerStorage) {
          if (this.ownerFilter) {
            if (ownerKPIObj.name === this.ownerFilter) {
              if (salesPersonFilterToUse) {
                if(salesPersonName === salesPersonFilterToUse){
                  createOwnerStorage = true
                }             
              } else {
                createOwnerStorage = true
              }
            } else {
              if (salesPersonFilterToUse && salesPersonName === salesPersonFilterToUse) {
                createOwnerStorage = true
              }
            }
          } else {
            if (salesPersonFilterToUse) {
              if(salesPersonName === salesPersonFilterToUse){
                createOwnerStorage = true
              }             
            } else {
              createOwnerStorage = true
            }
          }

          if (createOwnerStorage) {
            ownerStorage = this.tableDataOwnersObj[ownerKPIObj.name] = {}
            let systemOwner = this.reportCore.dataOwners[ownerKPIObj.name]

            let isMapped = true
            let ownerType = null
            if (!systemOwner) {
              isMapped = false
            } else {
              if (systemOwner.started_at.year !== this.reportCore.currentDate.year) {
                ownerType = 'legacy'
              }

              if (systemOwner.started_at.year === this.reportCore.currentDate.year) {
                ownerType = 'new'
              }
            }

            ownerStorage.isMapped = isMapped
            ownerStorage.ownerType = ownerType
            ownerStorage.data = {}
          }
        }

        locationStorage = this.tableDataLocationsObj[locationName]

        if (!locationStorage) {
          locationStorage = this.tableDataLocationsObj[locationName] = {}
          locationStorage.data = {}
        }

        salesPersonStorage = this.tableDataSalesPersonsObj[salesPersonName]

        if (!salesPersonStorage) {
          salesPersonStorage = this.tableDataSalesPersonsObj[salesPersonName] = {}
          salesPersonStorage.ownerName = ownerKPIObj.name
          salesPersonStorage.data = {}
        }

        leadGroupStorage = this.tableDataLeadGroupsObj[leadGroupName]
        let createLeadGroupStorage = false
        if (!leadGroupStorage) {
          if (this.ownerFilter) {
            if (ownerKPIObj.name === this.ownerFilter) {
              if (salesPersonFilterToUse) {
                if(salesPersonName === salesPersonFilterToUse){
                  createLeadGroupStorage = true
                }             
              } else {
                createLeadGroupStorage = true
              }
            } else {
              if (salesPersonFilterToUse && salesPersonName === salesPersonFilterToUse) {
                createLeadGroupStorage = true
              }
            }
          } else {
            if (salesPersonFilterToUse) {
              if(salesPersonName === salesPersonFilterToUse){
                createLeadGroupStorage = true
              }             
            } else {
              createLeadGroupStorage = true
            }
          }

          if (createLeadGroupStorage) {
            leadGroupStorage = this.tableDataLeadGroupsObj[leadGroupName] = {}
            leadGroupStorage.data = {}
          }
        }
       
      }

      for (let i = 0; i < this.reportElementProps.length; i++) {
        let prop = this.reportElementProps[i]
        if (dateRangesValidities.isDateRangeValidCurrent) {
          let createOwnerData = false
          if (ownerStorage) {
            if (this.ownerFilter) {
              if (ownerKPIObj.name === this.ownerFilter) {
                if (salesPersonFilterToUse) {
                  if(salesPersonName === salesPersonFilterToUse){
                    createOwnerData = true
                  }             
                } else {
                  createOwnerData = true
                }
              } else {
                if (salesPersonFilterToUse && salesPersonName === salesPersonFilterToUse) {
                  createOwnerData = true
                }
              }
            } else {
              if (salesPersonFilterToUse) {
                if(salesPersonName === salesPersonFilterToUse){
                  createOwnerData = true
                }             
              } else {
                createOwnerData = true
              }
            }
          }
          if (createOwnerData) {
            ownerStorage.data[prop] = ownerStorage.data[prop] || 0
            ownerStorage.data[prop] += leadGroupKPIObj.kpiData[prop] || 0
          }

          locationStorage.data[prop] = locationStorage.data[prop] || 0
          locationStorage.data[prop] += leadGroupKPIObj.kpiData[prop] || 0

          salesPersonStorage.data[prop] = salesPersonStorage.data[prop] || 0
          salesPersonStorage.data[prop] += leadGroupKPIObj.kpiData[prop] || 0

          let createLeadGroupData = false
          if (leadGroupStorage) {
            if (this.ownerFilter) {
              if (ownerKPIObj.name === this.ownerFilter) {
                if (salesPersonFilterToUse) {
                  if(salesPersonName === salesPersonFilterToUse){
                    createLeadGroupData = true
                  }             
                } else {
                  createLeadGroupData = true
                }
              } else {
                if (salesPersonFilterToUse && salesPersonName === salesPersonFilterToUse) {
                  createLeadGroupData = true
                }
              }
            } else {
              if (salesPersonFilterToUse) {
                if(salesPersonName === salesPersonFilterToUse){
                  createLeadGroupData = true
                }             
              } else {
                createLeadGroupData = true
              }
            }
          }
          if (createLeadGroupData) {
            leadGroupStorage.data[prop] = leadGroupStorage.data[prop] || 0
            leadGroupStorage.data[prop] += leadGroupKPIObj.kpiData[prop] || 0
          }

          let createMetricRangeCurrentData = false

          if (this.ownerFilter) {
            if (ownerKPIObj.name === this.ownerFilter) {
              if (salesPersonFilterToUse) {
                if(salesPersonName === salesPersonFilterToUse){
                  createMetricRangeCurrentData = true
                }             
              } else {
                createMetricRangeCurrentData = true
              }
            } else {
              if (salesPersonFilterToUse && salesPersonName === salesPersonFilterToUse) {
                createMetricRangeCurrentData = true
              }
            }
          } else {
            if (salesPersonFilterToUse) {
              if(salesPersonName === salesPersonFilterToUse){
                createMetricRangeCurrentData = true
              }             
            } else {
              createMetricRangeCurrentData = true
            }
          }

          if (createMetricRangeCurrentData) {
            this.metrics.rangeCurrent[prop] = this.metrics.rangeCurrent[prop] || 0
            this.metrics.rangeCurrent[prop] += leadGroupKPIObj.kpiData[prop] || 0
          }
        }

        if (dateRangesValidities.isDateRangeValidPOP) {
          let createMetricRangePOPData = false

          if (this.ownerFilter) {
            if (ownerKPIObj.name === this.ownerFilter) {
              if (salesPersonFilterToUse) {
                if(salesPersonName === salesPersonFilterToUse){
                  createMetricRangePOPData = true
                }             
              } else {
                createMetricRangePOPData = true
              }
            } else {
              if (salesPersonFilterToUse && salesPersonName === salesPersonFilterToUse) {
                createMetricRangePOPData = true
              }
            }
          } else {
            if (salesPersonFilterToUse) {
              if(salesPersonName === salesPersonFilterToUse){
                createMetricRangePOPData = true
              }             
            } else {
              createMetricRangePOPData = true
            }
          }

          if (createMetricRangePOPData) {
            this.metrics.rangeSub2[prop] = this.metrics.rangeSub2[prop] || 0
            this.metrics.rangeSub2[prop] += leadGroupKPIObj.kpiData[prop] || 0
          }
        }

        if (dateRangesValidities.isDateRangeValidYOY) {
          let createMetricRangeYOYData = false

          if (this.ownerFilter) {
            if (ownerKPIObj.name === this.ownerFilter) {
              if (salesPersonFilterToUse) {
                if(salesPersonName === salesPersonFilterToUse){
                  createMetricRangeYOYData = true
                }             
              } else {
                createMetricRangeYOYData = true
              }
            } else {
              if (salesPersonFilterToUse && salesPersonName === salesPersonFilterToUse) {
                createMetricRangeYOYData = true
              }
            }
          } else {
            if (salesPersonFilterToUse) {
              if(salesPersonName === salesPersonFilterToUse){
                createMetricRangeYOYData = true
              }             
            } else {
              createMetricRangeYOYData = true
            }
          }

          if (createMetricRangeYOYData) {
            this.metrics.rangeSub1[prop] = this.metrics.rangeSub1[prop] || 0
            this.metrics.rangeSub1[prop] += leadGroupKPIObj.kpiData[prop] || 0
          }
        }

        if (dateRangesValidities.isDateRangeValidTimelineCurrent) {
          let createTimelineYearCurrentData = false

          if (this.ownerFilter) {
            if (ownerKPIObj.name === this.ownerFilter) {
              if (salesPersonFilterToUse) {
                if(salesPersonName === salesPersonFilterToUse){
                  createTimelineYearCurrentData = true
                }             
              } else {
                createTimelineYearCurrentData = true
              }
            } else {
              if (salesPersonFilterToUse && salesPersonName === salesPersonFilterToUse) {
                createTimelineYearCurrentData = true
              }
            }
          } else {
            if (salesPersonFilterToUse) {
              if(salesPersonName === salesPersonFilterToUse){
                createTimelineYearCurrentData = true
              }             
            } else {
              createTimelineYearCurrentData = true
            }
          }

          if (createTimelineYearCurrentData) {
            this.timeLineData.timelineYearCurrent[dateBeingProcessed.month][prop] =
              this.timeLineData.timelineYearCurrent[dateBeingProcessed.month][prop] || 0
            this.timeLineData.timelineYearCurrent[dateBeingProcessed.month][prop] +=
              leadGroupKPIObj.kpiData[prop] || 0
          }
        }

        if (dateRangesValidities.isDateRangeValidTimelinePrevious) {
          let createTimelineYearPreviousData = false

          if (this.ownerFilter) {
            if (ownerKPIObj.name === this.ownerFilter) {
              if (salesPersonFilterToUse) {
                if(salesPersonName === salesPersonFilterToUse){
                  createTimelineYearPreviousData = true
                }             
              } else {
                createTimelineYearPreviousData = true
              }
            } else {
              if (salesPersonFilterToUse && salesPersonName === salesPersonFilterToUse) {
                createTimelineYearPreviousData = true
              }
            }
          } else {
            if (salesPersonFilterToUse) {
              if(salesPersonName === salesPersonFilterToUse){
                createTimelineYearPreviousData = true
              }             
            } else {
              createTimelineYearPreviousData = true
            }
          }

          if (createTimelineYearPreviousData) {
            this.timeLineData.timelineYearPrevious[dateBeingProcessed.month][prop] =
              this.timeLineData.timelineYearPrevious[dateBeingProcessed.month][prop] || 0
            this.timeLineData.timelineYearPrevious[dateBeingProcessed.month][prop] +=
              leadGroupKPIObj.kpiData[prop] || 0
          }
        }
      }
    },

    //-------------------------------------------------------------------------------------------------

    buildReportProcess5b(
      ownerKPIObj,
      locationName,
      salesPersonName,
      dateBeingProcessed,
      dateRangesValidities
    ) {
      let locationKPIObj = ownerKPIObj.years[dateBeingProcessed.year]
      if (locationKPIObj) {
        locationKPIObj = locationKPIObj.months[dateBeingProcessed.month]
      } else {
        return
      }
      if (locationKPIObj) {
        locationKPIObj = locationKPIObj.days[dateBeingProcessed.day]
      } else {
        return
      }
      if (locationKPIObj) {
        locationKPIObj = locationKPIObj.locations[locationName]
      } else {
        return
      }

      if (!locationKPIObj) {
        return
      }

      let salesPersonKPIObj = locationKPIObj.salesPersons[salesPersonName]
      if (!salesPersonKPIObj) {
        return
      }

      if (this.leadGroupFilter) {
        //console.log(this.leadGroupFilter)
        this.buildReportProcess6(
          ownerKPIObj,
          locationName,
          salesPersonName,
          this.leadGroupFilter,
          dateBeingProcessed,
          dateRangesValidities
        )
      } else {
        for (let leadGroupName in salesPersonKPIObj.leadGroups) {
          this.buildReportProcess6(
            ownerKPIObj,
            locationName,
            salesPersonName,
            leadGroupName,
            dateBeingProcessed,
            dateRangesValidities
          )
        }
      }
    },

    buildReportProcess5(ownerKPIObj, locationName, dateBeingProcessed, dateRangesValidities) {
      let locationKPIObj = ownerKPIObj.years[dateBeingProcessed.year]
      if (locationKPIObj) {
        locationKPIObj = locationKPIObj.months[dateBeingProcessed.month]
      } else {
        return
      }
      if (locationKPIObj) {
        locationKPIObj = locationKPIObj.days[dateBeingProcessed.day]
      } else {
        return
      }
      if (locationKPIObj) {
        locationKPIObj = locationKPIObj.locations[locationName]
      } else {
        return
      }

      if (!locationKPIObj) {
        return
      }

      // if (this.salesPersonFilter || this.salesPersonUnfilteredFilter) {
      //console.log(this.leadGroupFilter)
      /* this.buildReportProcess5b(
         ownerKPIObj,
         locationName,
         this.salesPersonFilter || this.salesPersonUnfilteredFilter,
         dateBeingProcessed,
         dateRangesValidities
       )*/
      // } else {
      for (let salesPersonName in locationKPIObj.salesPersons) {
        this.buildReportProcess5b(
          ownerKPIObj,
          locationName,
          salesPersonName,
          dateBeingProcessed,
          dateRangesValidities
        )
      }
      // }
    },

    buildReportProcess4(ownerName, dataSource, dateBeingProcessed, dateRangesValidities) {
      let ownerKPIObj = dataSource[ownerName]

      if (this.locationFilter) {
        // console.log(this.locationFilter)
        if (ownerKPIObj) {
          this.buildReportProcess5(
            ownerKPIObj,
            this.locationFilter,
            dateBeingProcessed,
            dateRangesValidities
          )
        }
      } else {
        if (ownerKPIObj) {
          let locationRef = ownerKPIObj.years[dateBeingProcessed.year]
          if (locationRef) {
            locationRef = locationRef.months[dateBeingProcessed.month]
          } else {
            return
          }
          if (locationRef) {
            locationRef = locationRef.days[dateBeingProcessed.day]
          } else {
            return
          }
          if (locationRef) {
            locationRef = locationRef.locations
          } else {
            return
          }

          for (let locationName in locationRef) {
            this.buildReportProcess5(
              ownerKPIObj,
              locationName,
              dateBeingProcessed,
              dateRangesValidities
            )
          }
        }
      }
    },

    //-------------------------------------------------------------------------------------------------

    buildReportProcess3(dateBeingProcessed) {
      let dateRangesValidities = {}
      dateRangesValidities.isDateRangeValidCurrent = true
      dateRangesValidities.isDateRangeValidPOP = true
      dateRangesValidities.isDateRangeValidYOY = true
      dateRangesValidities.isDateRangeValidTimelineCurrent = true
      dateRangesValidities.isDateRangeValidTimelinePrevious = true

      if (dateBeingProcessed > this.reportCore.dateRangeEnd) {
        dateRangesValidities.isDateRangeValidCurrent = false
      }

      if (dateBeingProcessed < this.reportCore.dateRangeStart) {
        dateRangesValidities.isDateRangeValidCurrent = false
      }

      if (dateBeingProcessed > this.reportCore.periodBeforeEnd) {
        dateRangesValidities.isDateRangeValidPOP = false
      }

      if (dateBeingProcessed < this.reportCore.periodBeforeStart) {
        dateRangesValidities.isDateRangeValidPOP = false
      }

      if (dateBeingProcessed > this.reportCore.oneYearBeforeEnd) {
        dateRangesValidities.isDateRangeValidYOY = false
      }

      if (dateBeingProcessed < this.reportCore.oneYearBeforeStart) {
        dateRangesValidities.isDateRangeValidYOY = false
      }
      if (dateBeingProcessed.year === this.reportCore.currentDate.year) {
        dateRangesValidities.isDateRangeValidTimelinePrevious = false
      }
      if (dateBeingProcessed.year === this.reportCore.currentDate.year - 1) {
        dateRangesValidities.isDateRangeValidTimelineCurrent = false
      }

      //------------------------------------------------------------------

      let dataSource = null

      let blendDate = null
      let dataSourceKey = null

      /* if (this.ownerFilter) {
        if (this.reportCore.useDataSourceOverride) {
          blendDate = this.reportCore.dataBlendDate
          dataSourceKey = this.reportCore.dataSourceCurrent
        } else {
          //get user , get preferred source info , if it exists , otherwise default SM
          let systemOwner = this.reportCore.dataOwners[this.ownerFilter]
          if (systemOwner) {
            blendDate = systemOwner.UserDefaultDataSource.blend_date_sd
            dataSourceKey =
              systemOwner.UserDefaultDataSource.value || this.reportCore.DATA_SOURCE_BLEND
          } else {
            //unmapped owners get system defaults , which are same as overrides
            blendDate = this.reportCore.dataBlendDate
            dataSourceKey = this.reportCore.dataSourceCurrent
          }
        }

        if (dataSourceKey === this.reportCore.DATA_SOURCE_CORE) {
          dataSource = this.processedDataCORE
        }
        if (dataSourceKey === this.reportCore.DATA_SOURCE_SM) {
          dataSource = this.processedDataSM
        }

        if (dataSourceKey === this.reportCore.DATA_SOURCE_BLEND) {
          dataSource = this.processedDataCORE

          if (dateBeingProcessed >= blendDate) {
            dataSource = this.processedDataSM
          }
        }

        this.buildReportProcess4(
          this.ownerFilter,
          dataSource,
          dateBeingProcessed,
          dateRangesValidities
        )*/
      //} else {
      for (let ownerName in this.reportCore.dataOwnersNoAliases) {
        let systemOwner = this.reportCore.dataOwnersNoAliases[ownerName]
        if (this.reportCore.useDataSourceOverride) {
          blendDate = this.reportCore.dataBlendDate
          dataSourceKey = this.reportCore.dataSourceCurrent
        } else {
          blendDate = systemOwner.UserDefaultDataSource.blend_date_sd
          dataSourceKey =
            systemOwner.UserDefaultDataSource.value || this.reportCore.DATA_SOURCE_BLEND
        }
        if (dataSourceKey === this.reportCore.DATA_SOURCE_CORE) {
          dataSource = this.dataKPI2Store.processedDataCORE
        }
        if (dataSourceKey === this.reportCore.DATA_SOURCE_SM) {
          dataSource = this.dataKPI2Store.processedDataSM
        }

        if (dataSourceKey === this.reportCore.DATA_SOURCE_BLEND) {
          dataSource = this.dataKPI2Store.processedDataCORE

          if (dateBeingProcessed >= blendDate) {
            dataSource = this.dataKPI2Store.processedDataSM
          }
        }

        if (dataSource[ownerName]) {
          this.buildReportProcess4(ownerName, dataSource, dateBeingProcessed, dateRangesValidities)
        } else {
          // console.log(`ownerName ${ownerName} not found in kpi data source ${dataSourceKey}`)
        }
      }

      //once system owners are looped , need to loop kpi sets and process unmapped owners
      //as the above loop will miss them

      if (this.reportCore.useDataSourceOverride) {
        blendDate = this.reportCore.dataBlendDate
        dataSourceKey = this.reportCore.dataSourceCurrent
      } else {
        blendDate = this.reportCore.dataBlendDateUnmapped
        dataSourceKey = this.reportCore.dataSourceUnmapped
      }

      if (dataSourceKey === this.reportCore.DATA_SOURCE_CORE) {
        dataSource = this.dataKPI2Store.processedDataCORE
      }
      if (dataSourceKey === this.reportCore.DATA_SOURCE_SM) {
        dataSource = this.dataKPI2Store.processedDataSM
      }

      if (dataSourceKey === this.reportCore.DATA_SOURCE_BLEND) {
        dataSource = this.dataKPI2Store.processedDataCORE

        if (dateBeingProcessed >= blendDate) {
          dataSource = this.dataKPI2Store.processedDataSM
        }
      }
      for (let ownerName in dataSource) {
        let kpiOwner = dataSource[ownerName]
        if (!kpiOwner.isMapped) {
          this.buildReportProcess4(ownerName, dataSource, dateBeingProcessed, dateRangesValidities)
        }
      }
      //}
    },

    //-------------------------------------------------------------------------------------------------

    buildReportProcess2() {
      //year/month values to loop
      //you always need all months from the curent year and previous year because of the timeline on the report
      //all POP and YOY ranges will fall within these ranges

      let yearMonthDayValues = this.reportCore.getYearMonthDayValuesForKPIRange()

      for (let i = 0; i < yearMonthDayValues.length; i++) {
        this.buildReportProcess3(yearMonthDayValues[i])
      }
    },

    //-------------------------------------------------------------------------------------------------

    buildReportProcess1() {
     

      const auth = useAuth()
      //not sure if this is even needed here because data is filtered on the backend based on this permission.. check..?
      if (!auth.hasPermission('read_kpi_2_report_all_owners')) {
        this.ownerFilter = auth.user.name
        if (auth.hasRole('salesperson')) {
          console.log(this.reportCore.dataSalesPersons)
          this.ownerFilter = this.reportCore.dataSalesPersons[auth.user.name]?.parentName
        }
        if (auth.hasRole('staff')) {
        //  console.log(this.reportCore.dataStaffs)
          this.ownerFilter = this.reportCore.dataStaffs[auth.user.name]?.parentName
        }
      }

      this.reportCore.calculateGrowthDateRanges()

      this.tableDataOwnersObj = markRaw({})
      this.tableDataLocationsObj = markRaw({})
      this.tableDataSalesPersonsObj = markRaw({})
      this.tableDataLeadGroupsObj = markRaw({})

      this.timeLineData = markRaw({})
      this.timeLineData.timelineYearCurrent = {}
      this.timeLineData.timelineYearPrevious = {}
      for (let i = 0; i < 12; i++) {
        this.timeLineData.timelineYearCurrent[i] = {}
        this.timeLineData.timelineYearPrevious[i] = {}
      }

      //-------------------------------------------------------------------

      this.metrics = markRaw({})
      this.metrics.rangeCurrent = {}
      this.metrics.rangeSub1 = {}
      this.metrics.rangeSub2 = {}

      this.dataKPI2Store.clearDebugInfo()

      this.buildReportProcess2() // buildReportProcess 3,4,5,6 called from here

      // loop all owners in kpi data or only one owner if filtered

      // check if owner is mapped or not and set new or legacy on this.tableDataOwnersObj

      // loop all locations in kpi data or only one location if filtered

      // loop all lead groups in kpi data or only one leadgroup if filtered

      //now process owner , location , lead group , kpi into data for tables

      this.buildReportProcess7()

      this.dataReportKPIVerbose.invalidate({queryKey:"KPIVerboseQuery1"},{ownerFilter:this.ownerFilter,leadGroupFilter:this.leadGroupFilter,salesPersonFilter:this.salesPersonFilter});

     

      this.reportCore.setLoadHeavy(false)
    },

    //---------------------------------------------------------------------------------------------

    buildMetricsTimeline(timelineMetricsTemp) {
      useBuildMetricDataTimeline2(
        this.timelineMetrics,
        timelineMetricsTemp,
        'Amount',
        'USD',
        'SALES_SUM'
      )
      useBuildMetricDataTimeline2(
        this.timelineMetrics,
        timelineMetricsTemp,
        'Sales',
        'INT',
        'SALES_COUNT'
      )

      useBuildMetricDataTimeline2(
        this.timelineMetrics,
        timelineMetricsTemp,
        'Appt Closing Rate',
        '%',
        ['SALES_COUNT', 'APPOINTMENTS_COUNT']
      )

      useBuildMetricDataTimeline2(
        this.timelineMetrics,
        timelineMetricsTemp,
        'Issued Closing Rate',
        '%',
        ['SALES_COUNT', 'ISSUED_APPOINTMENTS_COUNT']
      )

      useBuildMetricDataTimeline2(this.timelineMetrics, timelineMetricsTemp, 'Sales Avg', 'USD', [
        'SALES_SUM',
        'SALES_COUNT'
      ])

      useBuildMetricDataTimeline2(
        this.timelineMetrics,
        timelineMetricsTemp,
        'Appointments',
        'INT',
        'APPOINTMENTS_COUNT'
      )

      useBuildMetricDataTimeline2(
        this.timelineMetrics,
        timelineMetricsTemp,
        'Appointments Issued',
        'INT',

        'ISSUED_APPOINTMENTS_COUNT'
      )

      useBuildMetricDataTimeline2(
        this.timelineMetrics,
        timelineMetricsTemp,
        'Quotes',
        'INT',
        'QUOTES_COUNT'
      )

      useBuildMetricDataTimeline2(this.timelineMetrics, timelineMetricsTemp, 'Issued Rate', '%', [
        'ISSUED_APPOINTMENTS_COUNT',
        'APPOINTMENTS_COUNT'
      ])
      useBuildMetricDataTimeline2(this.timelineMetrics, timelineMetricsTemp, 'Closing Rate', '%', [
        'SALES_COUNT',
        'QUOTES_COUNT'
      ])

      useBuildMetricDataTimeline2(this.timelineMetrics, timelineMetricsTemp, 'Demo Rate', '%', [
        'QUOTES_COUNT',
        'ISSUED_APPOINTMENTS_COUNT'
      ])

      useBuildMetricDataTimeline2(this.timelineMetrics, timelineMetricsTemp, 'NSLI', 'USD', [
        'SALES_SUM',
        'ISSUED_APPOINTMENTS_COUNT'
      ])
    },

    buildMetricsTables(metrics, targetData) {
      let ob = {}

      useBuildMetricDataTables2(ob, 'Owner', 'STRING', metrics, 'OWNER')
      useBuildMetricDataTables2(ob, 'Location', 'STRING', metrics, 'LOCATION')
      useBuildMetricDataTables2(ob, 'Lead Group', 'STRING', metrics, 'LEAD_GROUP')
      useBuildMetricDataTables2(ob, 'Sales Person', 'STRING', metrics, 'SALES_PERSON')
      useBuildMetricDataTables2(ob, 'Sales Person Raw', 'STRING', metrics, 'SALES_PERSON_RAW')

      useBuildMetricDataTables2(ob, 'Amount', 'USD', metrics, 'SALES_SUM')
      useBuildMetricDataTables2(ob, 'Sales', 'INT', metrics, 'SALES_COUNT')
      useBuildMetricDataTables2(ob, 'Appt Closing Rate', '%', metrics, [
        'SALES_COUNT',
        'APPOINTMENTS_COUNT'
      ])

      useBuildMetricDataTables2(ob, 'Issued Closing Rate', '%', metrics, [
        'SALES_COUNT',
        'ISSUED_APPOINTMENTS_COUNT'
      ])

      useBuildMetricDataTables2(ob, 'Sales Avg', 'USD', metrics, ['SALES_SUM', 'SALES_COUNT'])

      useBuildMetricDataTables2(ob, 'Appointments', 'INT', metrics, 'APPOINTMENTS_COUNT')

      useBuildMetricDataTables2(
        ob,
        'Appointments Issued',
        'INT',
        metrics,
        'ISSUED_APPOINTMENTS_COUNT'
      )

      useBuildMetricDataTables2(ob, 'Quotes', 'INT', metrics, 'QUOTES_COUNT')

      useBuildMetricDataTables2(ob, 'Issued Rate', '%', metrics, [
        'ISSUED_APPOINTMENTS_COUNT',
        'APPOINTMENTS_COUNT'
      ])
      useBuildMetricDataTables2(ob, 'Closing Rate', '%', metrics, ['SALES_COUNT', 'QUOTES_COUNT'])

      useBuildMetricDataTables2(ob, 'Demo Rate', '%', metrics, [
        'QUOTES_COUNT',
        'ISSUED_APPOINTMENTS_COUNT'
      ])

      useBuildMetricDataTables2(ob, 'NSLI', 'USD', metrics, [
        'SALES_SUM',
        'ISSUED_APPOINTMENTS_COUNT'
      ])

      targetData.push(ob)
    },

    buildScoreCards(metrics) {
      this.scorecardLabels = {
        subValue1: 'yoy',
        subValue2: this.reportCore.momRangeValid ? 'mom' : 'pop',
        subMetric1: 'YOY',
        subMetric2: this.reportCore.momRangeValid ? 'MOM' : 'POP'
      }
      //Amount --------------------------------
      this.scoreCards.amount = useBuildMetricDataScoreCards('Amount', 'USD', metrics, 'SALES_SUM')

      //Sales --------------------------------
      this.scoreCards.sales = useBuildMetricDataScoreCards('Sales', '', metrics, 'SALES_COUNT')

      //Appt Closing Rate --------------------------------
      this.scoreCards.app_close_rate = useBuildMetricDataScoreCards(
        'Appt Closing Rate',
        '%',
        metrics,
        ['SALES_COUNT', 'APPOINTMENTS_COUNT']
      )

      //Issued Closing Rate --------------------------------
      this.scoreCards.issued_close_rate = useBuildMetricDataScoreCards(
        'Issued Closing Rate',
        '%',
        metrics,
        ['SALES_COUNT', 'ISSUED_APPOINTMENTS_COUNT']
      )

      //Sales Avg --------------------------------
      this.scoreCards.sales_avg = useBuildMetricDataScoreCards('Sales Avg', 'USD', metrics, [
        'SALES_SUM',
        'SALES_COUNT'
      ])

      //Appointments --------------------------------
      this.scoreCards.appointments = useBuildMetricDataScoreCards(
        'Appointments',
        '',
        metrics,
        'APPOINTMENTS_COUNT'
      )

      //Appointments Issued --------------------------------
      this.scoreCards.appointments_issued = useBuildMetricDataScoreCards(
        'Appointments Issued',
        '',
        metrics,
        'ISSUED_APPOINTMENTS_COUNT'
      )

      //Quotes --------------------------------
      this.scoreCards.quotes = useBuildMetricDataScoreCards('Quotes', '', metrics, 'QUOTES_COUNT')

      //Issued Rate --------------------------------
      this.scoreCards.issued_rate = useBuildMetricDataScoreCards('Issued Rate', '%', metrics, [
        'ISSUED_APPOINTMENTS_COUNT',
        'APPOINTMENTS_COUNT'
      ])

      //Closing Rate  --------------------------------
      this.scoreCards.closing_rate = useBuildMetricDataScoreCards('Closing Rate', '%', metrics, [
        'SALES_COUNT',
        'QUOTES_COUNT'
      ])

      this.scoreCards.demo_rate = useBuildMetricDataScoreCards('Demo Rate', '%', metrics, [
        'QUOTES_COUNT',
        'ISSUED_APPOINTMENTS_COUNT'
      ])

      this.scoreCards.nsia = useBuildMetricDataScoreCards('NSLI', 'USD', metrics, [
        'SALES_SUM',
        'ISSUED_APPOINTMENTS_COUNT'
      ])



      if(this.leadGroupFilter){
        for(let scorecardKey in this.scoreCards){
          let scorecard = this.scoreCards[scorecardKey]
          let yearToUse = this.reportCore.dateRangeEnd.year
          if(this.dataBenchmarkLeadGroupStore.benchmarkData[yearToUse]){
            if(this.dataBenchmarkLeadGroupStore.benchmarkData[yearToUse].benchmarks[scorecard.title]){
              let leadGroupItem = this.dataBenchmarkLeadGroupStore.benchmarkData[yearToUse].benchmarks[scorecard.title].leadGroups.find(element => element.LeadGroup.name === this.leadGroupFilter)
           
              if(leadGroupItem){
                if(leadGroupItem.active){
                  scorecard.benchmark = leadGroupItem.value/100
                }
                
              }
            }
          }
        }
      }

    },

    //---------------------------------------------------------------------------------------------

    

   

   

    
   

    async refreshQueryCache($event, key) {
      let asyncProcessSuccessful = false
      asyncProcessSuccessful = await this.dataKPI2Store.refreshQueryCache($event, key)
      if (!asyncProcessSuccessful) return

      this.buildReport()
    },

    async loadDrillDownData() {
      this.reportCore.setLoadHeavy(true, 'KPI Drilldown Data', 'snowflake query')

      this.dataTableDrillDownCORE.tableData = []
      this.dataTableDrillDownSM.tableData = []
      const urls = useURLS()
      let requestdata = {}
      requestdata.dateRangeStart = this.reportCore.dateRangeStart.getDateString()
      requestdata.dateRangeEnd = this.reportCore.dateRangeEnd.getDateString()

      let ownerAliases = this.reportCore.dataOwnersAliases[this.ownerFilter]
      if (ownerAliases) {
        requestdata.ownerFilter = ownerAliases
      } else if (this.ownerFilter) {
        requestdata.ownerFilter = this.ownerFilter
      } else {
        requestdata.ownerFilter = ''
      }

      let locationAliases = this.reportCore.dataLocationsAliases[this.locationFilter]
      if (locationAliases) {
        requestdata.locationFilter = locationAliases
      } else if (this.locationFilter) {
        requestdata.locationFilter = this.locationFilter
      } else {
        requestdata.locationFilter = ''
      }

      let leadGroupAliases = this.reportCore.dataLeadGroupsAliases[this.leadGroupFilter]

      if (leadGroupAliases) {
        requestdata.leadGroupFilter = leadGroupAliases
      } else if (this.leadGroupFilter) {
        requestdata.leadGroupFilter = this.leadGroupFilter
      } else {
        requestdata.leadGroupFilter = ''
      }

      //console.log(requestdata)

      let result = null
      try {
        result = await this.reportCore.loadServerData(
          urls.getURL('kpi1_report', 'kpi1DrillDown'),
          requestdata
        )
        if (result) {
          this.reportCore.setLoadHeavy(false)
          //console.log(result)
          this.processDrillDownData(result)
        }
      } catch (error) {
        this.reportCore.setLoadHeavy(false)
        console.log(error)
      }
    },

    processDrillDownData(result) {
      let headers = []
      let header = {}
      header.field = 'NAME'
      header.header = 'NAME'
      headers.push(header)

      header = {}
      header.field = 'SALES'
      header.header = 'SALES'
      headers.push(header)

      header = {}
      header.field = 'DATE'
      header.header = 'DATE'
      headers.push(header)

      header = {}
      header.field = 'JOB_ID'
      header.header = 'JOB_ID'
      headers.push(header)
      header = {}
      header.field = 'PROPOSAL_STATUS'
      header.header = 'PROPOSAL_STATUS'
      headers.push(header)

      header = {}
      header.field = 'CONTACT_ID'
      header.header = 'CONTACT_ID'
      headers.push(header)

      header = {}
      header.field = 'LOCATION'
      header.header = 'LOCATION'
      headers.push(header)
      this.dataTableDrillDownCORE = {}
      this.dataTableDrillDownCORE.tableHeaders = headers
      this.dataTableDrillDownCORE.tableData = result.resultCORE
      this.dataTableDrillDownSM = {}
      this.dataTableDrillDownSM.tableHeaders = headers
      this.dataTableDrillDownSM.tableData = result.resultSM
    },

   

    doubleRaf(callback, ...args) {
      requestAnimationFrame(() => {
        requestAnimationFrame(() => {
          try {
            callback(...args)
          } catch (error) {
            useErrorHandler().processScriptError(error)
          }
        })
      })
    },

    async buildReport() {

     
      

      //check if kpi data and benchmark data is loaded
      let validateBuildDataKPIIsLoaded = this.dataKPI2Store.validateDataIsLoaded();
      //console.log(`validateBuildDataKPIIsLoaded ${validateBuildDataKPIIsLoaded}`)

      let validateBuildDataBenchmarkLeadGroupIsLoaded = this.dataBenchmarkLeadGroupStore.validateDataIsLoaded();
     // console.log(`validateBuildDataBenchmarkLeadGroupIsLoaded ${validateBuildDataBenchmarkLeadGroupIsLoaded}`)

      if (validateBuildDataKPIIsLoaded && validateBuildDataBenchmarkLeadGroupIsLoaded) {
        this.reportCore.setLoadHeavy(true, 'KPI Report',"process")
        this.doubleRaf(() => this.buildReportProcess1())
      } else {

        if (!validateBuildDataKPIIsLoaded) {
          await this.dataKPI2Store.loadDataAll();
        }
        if (!validateBuildDataBenchmarkLeadGroupIsLoaded) {
          await this.dataBenchmarkLeadGroupStore.loadData();
        }
        this.reportCore.setLoadHeavy(true, 'KPI Report',"process")
        this.doubleRaf(() => this.buildReportProcess1())
      }


    },
    async loadDataAll() {
      
      await this.dataKPI2Store.loadDataAll();
      this.doubleRaf(() => this.buildReportProcess1())

    }
  }
})
