<template>
    <div class="w-100 mx-auto printable" style="max-width: 99%" :class="applicationStore.getPadding">
      <div class="content-bg-11" :class="{
        'pa-4': !applicationStore.grid.tabletAndUnder,
        'elevation-2': !applicationStore.grid.tabletAndUnder,
        'rounded-2': !applicationStore.grid.tabletAndUnder
      }">
        <div class="pa-4 d-flex flex-column" style="background-color: var(--content-bg-13)">
          <div class="d-flex align-items-center" style="cursor: pointer"
            @click="reportKPIDeclinedStore.section1Expanded = !reportKPIDeclinedStore.section1Expanded">
            <div class="flex-ui-1 text-type-10">
            
              <div class="icon-color-1" style="font-weight: 700; font-size: 20px">KPI Declined Report</div>
            </div>
  
            <span class="ms-auto mdi icon icon-color-1" :class="{
              ' mdi-plus-circle': !reportKPIDeclinedStore.section1Expanded,
              ' mdi-minus-circle': reportKPIDeclinedStore.section1Expanded
            }">
            </span>
          </div>
  
          <div v-if="reportKPIDeclinedStore.section1Expanded">
            <div class="d-flex align-items-center flex-wrap text-type-10 p-0">
              <div class="icon-color-1">Current Filters Applied:</div>
  
              <div class="flex-ui-1 ml-2">
                <div class="fs-7 mr-2 text-type-10 icon-color-1">owner:</div>
                <div style="color: #ffca00">
                  {{ reportKPIDeclinedStore.ownerFilter || 'All Owners' }} ,
                </div>
              </div>
  
            
  
  
              <div class="d-flex align-items-center flex-wrap text-type-10 p-0">
                <div class="flex-ui-1 ml-2">
                  <div class="fs-7 mr-2 text-type-10 icon-color-1">range:</div>
                  <div style="color: #ffca00">
                    {{ reportCore.dateRangeStart.getDateString() }}
                  </div>
                </div>
  
                <div class="flex-ui-1">
                  <div class="fs-7 mx-2 text-type-10 icon-color-1">to</div>
                  <div style="color: #ffca00">
                    {{ reportCore.dateRangeEnd.getDateString() }}
                  </div>
                </div>
  
              </div>
  
  
  
            </div>
          </div>
        </div>
  
        <div class="mt-min pa-1 py-3 px-3 d-flex flex-column justify-items-start"
          style="background-color: var(--content-bg-13)">
  
  <MonthSelectionComponent  ref="monthSelectionComponent" @change="onMonthSelectionChange" />
  
         
  
  
  
  
        
        </div>
        <div class="d-flex mt-4 mb-1 flex-wrap justify-content-around gap-2">
          <div class="d-flex flex-column justify-content-left" style="flex: 1 1 0; width: 100%">
            <div>
              <ReportTableComponent configKey="reportKPIDeclinedTableOwner" />
            </div>
          </div>
        </div>
        <ReportKPIVerboseComponent :requestKeys="{queryKey:'KPIVerboseQuery3',parserKey:'KPIVerboseParser3'}" />
  
  
  
  
             
             
        <div class="pa-4 mt-2 mb-2 d-flex flex-column content-bg-10">
          <div class="d-flex flex-wrap fs-8 fw-bold p-0" :class="{
      'align-items-center': !applicationStore.grid.tabletAndUnder,
      'align-items-start': applicationStore.grid.tabletAndUnder,
      'flex-column': applicationStore.grid.tabletAndUnder
    }">
            <div class="text-color-1 fs-6">Selected Range:</div>
  
            <div class="flex-ui-1 ml-2">
              <div class="fs-7 mr-2 text-type-10 text-color-1">week:</div>
              <div style="color: #ff6600">
                {{ reportCore.dateRangeStart.getDateString()  }}
              </div>
              <div class="fs-7 mx-2 text-type-10 text-color-1">to</div>
              <div style="color: #ff6600">
                {{ reportCore.dateRangeEnd.getDateString()  }}
              </div>
            </div>
  
           
          </div>
         
        </div>
  
  
        <div class="text-color-2" style="text-align:left;font-weight: 700; font-size: 20px">Grouped</div>
  
        <div class="my-2 w-100" style="
              display: grid;
              grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
              grid-gap: 0.5rem 0.5rem;
            ">
          <ScoreCardComponent v-for="(scorcardItem ,index) in reportKPIDeclinedStore?.scoreCardsGrouped" :key="`scorecard_grouped__${index}`" style="flex: 0 0 0" :componentData="scorcardItem"
          ></ScoreCardComponent> 
  
        </div>

        <div class="text-color-2" style="text-align:left;font-weight: 700; font-size: 20px">UnGrouped</div>
  
        <div class="my-2 w-100" style="
              display: grid;
              grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
              grid-gap: 0.5rem 0.5rem;
            ">
          <ScoreCardComponent v-for="(scorcardItem ,index) in reportKPIDeclinedStore?.scoreCardsUnGrouped" :key="`scorecard_ungrouped_${index}`" style="flex: 0 0 0" :componentData="scorcardItem"
          ></ScoreCardComponent> 
  
        </div>
  
  
  
  
        <div v-if="auth.hasPermission('view_report_kpi_declined_debug')" class="mt-min pa-4 d-flex flex-column"
          style="background-color: var(--content-bg-13)">
          <div class="d-flex align-items-center" style="cursor: pointer"
            @click="reportKPIDeclinedStore.showDebugData = !reportKPIDeclinedStore.showDebugData">
            <div class="flex-ui-1 text-type-10">
              <div class="icon-color-1" style="font-weight: 700; font-size: 20px">Debug</div>
            </div>
  
            <span class="ms-auto mdi icon icon-color-1" :class="{
              ' mdi-plus-circle': !reportKPIDeclinedStore.showDebugData,
              ' mdi-minus-circle': reportKPIDeclinedStore.showDebugData
            }">
            </span>
          </div>
  
        </div>
  
  
  
  
  
  
        <div v-if="reportKPIDeclinedStore.showDebugData && auth.hasPermission('view_report_kpi_declined_debug')" class="mt-4">
  
  
          <div v-if="dataKPI2Store.unmappedKPIOwnersArrayCORE?.length">
            <div class="fs-6 text-color-2 fw-bold">unmapped owners CORE</div>
            <ListOfGrid :listData="dataKPI2Store.unmappedKPIOwnersArrayCORE" classDynamic="data-table-4"></ListOfGrid>
  
          </div>
  
          <div v-if="dataKPI2Store.unmappedKPIOwnersArraySM?.length">
            <div class="fs-6 text-color-2 fw-bold">unmapped owners SM</div>
            <ListOfGrid :listData="dataKPI2Store.unmappedKPIOwnersArraySM" classDynamic="data-table-4"></ListOfGrid>
  
          </div>
  
          <div v-if="dataKPI2Store.unmappedKPILeadGroupsArrayCORE?.length">
            <div class="fs-6 text-color-2 fw-bold">unmapped lead groups CORE</div>
            <ListOfGrid :listData="dataKPI2Store.unmappedKPILeadGroupsArrayCORE" classDynamic="data-table-4"></ListOfGrid>
  
          </div>
  
          <div v-if="dataKPI2Store.unmappedKPILeadGroupsArraySM?.length">
            <div class="fs-6 text-color-2 fw-bold">unmapped lead groups SM</div>
            <ListOfGrid :listData="dataKPI2Store.unmappedKPILeadGroupsArraySM" classDynamic="data-table-4"></ListOfGrid>
  
          </div>
  
  
  
  
  
  
  
  
  
        </div>
  
  
      </div>
    </div>
  </template>
  
  <script setup>
  import ReportTableComponent from '@/components/ui/ReportTableComponent.vue'
  import ReportKPIVerboseComponent from '@/components/ui/ReportKPIVerboseComponent.vue'
  import { useReportKPIDeclinedStore } from '@/stores/ReportKPIDeclinedStore.js'
  import { useReportCore } from '@/stores/ReportCore.js'
  import { useApplicationStore } from '@/stores/ApplicationStore.js'
  import ScoreCardComponent from '@/components/charts/ScoreCardComponent.vue'
  import { useAuth } from '@/stores/Auth.js'
  import { useDataKPI2Store } from '@/stores/DataKPI2Store.js'
  import ListOfGrid from '@/components/ui/ListOfGrid.vue'
  import MonthSelectionComponent from '@/components/ui/MonthSelectionComponent.vue'
  import { onMounted,ref } from 'vue'
  
  const reportCore = useReportCore()
  const reportKPIDeclinedStore = useReportKPIDeclinedStore()
  const applicationStore = useApplicationStore()
  
  const dataKPI2Store = useDataKPI2Store();
  const auth = useAuth()
  
  const monthSelectionComponent = ref(null)
  
  function onMonthSelectionChange(dateSelectionMonth){
  //console.log(dateSelectionMonth)
  reportKPIDeclinedStore.onMonthDateSelected(dateSelectionMonth)
  }
  
  
  
  
  onMounted(() => {
    reportKPIDeclinedStore.onViewMounted()
    monthSelectionComponent.value.reset()
   
  })
  </script>
  