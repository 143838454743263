import {  reactive } from 'vue'
export default class KPIVerboseModel {
    constructor(options) {

      this.queryKey = options.queryKey;
      this.parserKey = options.parserKey;
      this.processedData = null;
      this.renderKey = 0;
      this.currentSelected = null;
      this.contactObjects = reactive({ data: [] });
      this.loading = false
      this.loadingMessage = "loading"
      this.optionsCache = null

    }

    async reset() {
      this.processedData = null;
      this.contactObjects.data = []
      this.currentSelected = null;
      this.optionsCache = null
    }


    async invalidate(options) {
        if(options){
            this.optionsCache = options
          }
          if (this.optionsCache) {
            this.loading = true;
            await this.query.invalidate(this)
            this.parser.processData(this)
            this.loading = false;

          }
     
    }
  }