import {
  createRouter,
  createWebHistory,
  createWebHashHistory,
} from "vue-router";
import ApplicationLandingView from "@/views/ApplicationLandingView.vue";
import ApplicationView from "@/views/ApplicationView.vue";
import ReportKPI1View from "@/views/ReportKPI1View.vue";
import ReportKPI2View from "@/views/ReportKPI2View.vue";
import ReportBudgetView from "@/views/ReportBudgetView.vue";
import ReportMarketingView from "@/views/ReportMarketingView.vue";
import KPIBenchmarkLeadGroupManagementView from "@/views/KPIBenchmarkLeadGroupManagementView.vue";
import ReportKPIBenchmarkLeadGroupView from "@/views/ReportKPIBenchmarkLeadGroupView.vue";
import ReportRoyaltyView from "@/views/ReportRoyaltyView.vue";
import ReportKPIDeclinedView from "@/views/ReportKPIDeclinedView.vue";


import ReportDashboardAdminView from "@/views/ReportDashboardAdminView.vue";
import UserBrowseView from "@/views/UserBrowseView.vue";
import RunTimeRedirectView from "@/views/RunTimeRedirectView.vue";


import ForceAnErrorView from "@/views/ForceAnErrorView.vue";


import LocationBrowseView from "@/views/LocationBrowseView.vue";
import LeadGroupBrowseView from "@/views/LeadGroupBrowseView.vue";
import BudgetSaleManagementView from "@/views/BudgetSaleManagementView.vue";
import MarketingSpendManagementView from "@/views/MarketingSpendManagementView.vue";


import PageNotFoundView from "@/views/PageNotFoundView.vue";
import ErrorPageView from "@/views/ErrorPageView.vue";
import AccessDeniedView from "@/views/AccessDeniedView.vue";
import DataUpdateView from "@/views/DataUpdateView.vue";
import CacheWriteIssueView from "@/views/CacheWriteIssueView.vue";

import ReportFilterPresetManagement from "@/views/ReportFilterPresetManagement.vue";



import auth from "./middleware/auth.middleware";
import permissionPageMiddleware from "./middleware/view_page_base.middleware";
const routes = [
  {
    path: "/applicationlanding/:windowkey",
    name: "applicationlanding",
    component: ApplicationLandingView,
   
   
  },
  {
    path: "/",
    redirect: "/applicationlanding/login",
  },
  {
    path: "/error-view",
    component: ErrorPageView,
  }
  ,
  {
    path: "/access-denied",
    component: AccessDeniedView,
  },
  {
    path: "/application",
    redirect: "/application/runtime-redirect",
    name: "application",    
    component: ApplicationView,
    beforeEnter:auth,   
    children: [
      {
        path: "error-view",
        component: ErrorPageView,
      },
      {
        path: "runtime-redirect",
        component: RunTimeRedirectView,
      },
      {
        path: "forceanerror",
        component: ForceAnErrorView,
      },

      

      
      {
        path: "access-denied",
        component: AccessDeniedView,
      },
      {
        path: "data-update",
        component: DataUpdateView,
      },
      {
        path: "cache-creation-issue",
        component: CacheWriteIssueView,
      },
      
      {
        path: "report-dashboard-admin",
        component: ReportDashboardAdminView,
      },
      {
        path: "kpi1_report",
        component: ReportKPI1View,
        meta: { permission_page_view: 'view_page_kpi1_report' },
        beforeEnter:permissionPageMiddleware

      },
      {
        path: "kpi2_report",
        component: ReportKPI2View,
        meta: { permission_page_view: 'view_page_kpi2_report' },
        beforeEnter:permissionPageMiddleware

      },
      {
        path: "budget_sale_report",
        component: ReportBudgetView,
        meta: { permission_page_view: 'view_page_budget_sale_report' },
        beforeEnter:permissionPageMiddleware
      },
      {
        path: "marketing_spend_report",
        component: ReportMarketingView,
        meta: { permission_page_view: 'view_page_marketing_spend_report' },
        beforeEnter:permissionPageMiddleware
      },

      
      {
        path: "user_management",
        component: UserBrowseView,
        meta: { permission_page_view: 'view_page_user_management' },
        beforeEnter:permissionPageMiddleware
      },
      {
        path: "location_management",
        component: LocationBrowseView,
        meta: { permission_page_view: 'view_page_location_management' },
        beforeEnter:permissionPageMiddleware
      },
      {
        path: "leadgroup_management",
        component: LeadGroupBrowseView,
        meta: { permission_page_view: 'view_page_lead_group_management' },
        beforeEnter:permissionPageMiddleware
      },
      
     
      {
        path: "budget_sale_management",
        component: BudgetSaleManagementView,
        meta: { permission_page_view: 'view_page_budget_sale_management' },
        beforeEnter:permissionPageMiddleware
      }

      ,

      {
        path: "marketing_spend_management",
        component: MarketingSpendManagementView,
        meta: { permission_page_view: 'view_page_marketing_spend_management' },
        beforeEnter:permissionPageMiddleware
      }

      ,

      {
        path: "benchmark_lead_group_management",
        component: KPIBenchmarkLeadGroupManagementView,
        meta: { permission_page_view: 'view_benchmark_lead_group_management' },
        beforeEnter:permissionPageMiddleware
      }

      ,

      {
        path: "benchmark_lead_group_report",
        component: ReportKPIBenchmarkLeadGroupView,
        meta: { permission_page_view: 'view_benchmark_lead_group' },
        beforeEnter:permissionPageMiddleware
      }

      ,

      {
        path: "report_filter_preset_management",
        component: ReportFilterPresetManagement,
        meta: { permission_page_view: 'view_user_report_filter_preset_management' },
        beforeEnter:permissionPageMiddleware
      }

      ,

      {
        path: "report_royalty",
        component: ReportRoyaltyView,
        meta: { permission_page_view: 'view_owner_royalty_percentages_report' },
        beforeEnter:permissionPageMiddleware
      }

      ,

     {
        path: "report_kpi_declined",
        component: ReportKPIDeclinedView,
        meta: { permission_page_view: 'view_report_kpi_declined' },
        beforeEnter:permissionPageMiddleware
      }

      
      
      
     
      
      


     
    ],
  },
  {
    path: "/404",
    name: "PageNotFound",
    component: PageNotFoundView,
  },
  {
    path: "/:catchAll(.*)", // Unrecognized path automatically matches 404
    redirect: "/404",
  },
];

//set origin and pathname
window.location_origin = location.origin;
window.location_pathname = location.pathname;
let lastChar = window.location_pathname.substring(
  window.location_pathname.length - 1
);
if (lastChar === "/") {
  window.location_pathname = window.location_pathname.substring(
    0,
    window.location_pathname.length - 1
  );
}

window.location_pathname =
  window.location_pathname.indexOf(".html") !== -1
    ? window.location_pathname.substring(
        0,
        window.location_pathname.length - 10
      )
    : window.location_pathname;

window.location_url = window.location_origin + window.location_pathname;

lastChar = window.location_url.substring(window.location_url.length - 1);

if (lastChar !== "/") {
  window.location_url += "/";
}

/*const router = createRouter({
  history: createWebHistory(import.meta.env.BASE_URL),
  routes,
});*/


const router = createRouter({
  history:createWebHashHistory(),
  // createWebHistory(window.location_pathname),
  routes
})

export default router;
