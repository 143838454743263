import { useReportCore } from '@/stores/ReportCore.js'
import { markRaw } from 'vue';
export default class KPIVerboseParser1Class {
    constructor() {
      
    }

    processData(model) {
        let reportCore = markRaw(useReportCore());

      let contactObjects = []

      for (let ownerKey in model.processedData) {
        let ownerItem = model.processedData[ownerKey]
        //  console.log(ownerItem)

        for (let contactID in ownerItem.contacts) {
          let contact = ownerItem.contacts[contactID]
          let isPushed = false
          contact.validSaleValue = null
          contact.validServiceServiceValue = null
          contact.validServiceChangeValue = null

          if (contact.hasValidSale) {
            contact.saleKPIItemIndexs.forEach((element) => {
              let kpiSalesItem = contact.kpiDataVerbose[element]
              let dateToCheck = kpiSalesItem.date

              if (
                dateToCheck >= reportCore.dateRangeStart &&
                dateToCheck <= reportCore.dateRangeEnd
              ) {
                if (!isPushed) {
                  isPushed = true
                  contactObjects.push(contact)
                }
                contact.validSaleValue = contact.validSaleValue || 0
                contact.validSaleValue += kpiSalesItem.metric || 0
              }
            })
          }

          if (contact.hasValidServiceChange) {
            contact.serviceChangeKPIItemIndexs.forEach((element) => {
              let kpiServiceChangeItem = contact.kpiDataVerbose[element]

              let dateToCheck = kpiServiceChangeItem.date

              if (
                dateToCheck >= reportCore.dateRangeStart &&
                dateToCheck <= reportCore.dateRangeEnd
              ) {
                if (!isPushed) {
                  isPushed = true
                  contactObjects.push(contact)
                }
                contact.validServiceChangeValue = contact.validServiceChangeValue || 0
                contact.validServiceChangeValue += kpiServiceChangeItem.metric || 0
              }
            })
          }

          // valid service service

          if (contact.hasValidServiceService) {
            contact.serviceServiceKPIItemIndexs.forEach((element) => {
              let kpiServiceServiceItem = contact.kpiDataVerbose[element]
              let dateToCheck = kpiServiceServiceItem.date
              if (
                dateToCheck >= reportCore.dateRangeStart &&
                dateToCheck <= reportCore.dateRangeEnd
              ) {
                if (!isPushed) {
                  isPushed = true
                  contactObjects.push(contact)
                }
                contact.validServiceServiceValue = contact.validServiceServiceValue || 0
                contact.validServiceServiceValue += kpiServiceServiceItem.metric
              }
            })
          }

          if (contact.validSaleValue) {
            contact.validSaleValue = reportCore.formatCurrency(contact.validSaleValue)
          }
          if (contact.validServiceChangeValue) {
            contact.validServiceChangeValue = reportCore.formatCurrency(
              contact.validServiceChangeValue
            )
          }
          if (contact.validServiceServiceValue) {
            contact.validServiceServiceValue = reportCore.formatCurrency(
              contact.validServiceServiceValue
            )
          }
        }
      }

      //console.log(contactObjects)

     
        contactObjects.sort(
          (a, b) =>
            b.sortDateValueOf - a.sortDateValueOf
        )
     

      model.contactObjects.data = contactObjects
    }
  }