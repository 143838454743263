import { defineStore } from 'pinia'
import { useURLS } from '@/stores/URLS.js'
import { useRequests } from '@/stores/Requests.js'
import { useReportCore } from '@/stores/ReportCore.js'
import { useAuth } from '@/stores/Auth.js'
import { useToasts } from '@/stores/Toasts.js'
import { markRaw } from 'vue'
export const useDataPresetsTablesStore = defineStore('DataPresetsTablesStore', {
  state: () => {
    return {
      requests: markRaw(useRequests()),
      urls: markRaw(useURLS()),
      reportCore: markRaw(useReportCore()),
      auth: markRaw(useAuth()),
      toasts: markRaw(useToasts()),
      selectedMetrics: {

        report_kpi_declined:{
          owner: null,
        },
        report_royalty: {
          owner: null,
         
        },
        report_kpi_1: {
          owner: null,
          leadgroup: null,
          timeline: null
        },
        report_kpi_2: {
          owner: null,
          sales_person: null,
          sales_person_unfiltered: null,
          leadgroup: null,
          timeline: null
        },
        report_budget: {
          owner: null,
          leadgroup: null
        },
        report_marketing: {
          owner: null,
          leadgroup: null,
          timeline: null
        },
        report_benchmarks_leadgroups: {
          owner: null,
          leadgroupappsetrate:null,
          leadgroupissuedrate:null,
          leadgroupdemorate:null,
          leadgroupclosingrate: null,
          timeline: null
        }
      },
      presetNamesNew: {
        report_kpi_declined:{
          owner: null,
        },
        report_royalty: {
          owner: null,
         
        },
        report_kpi_1: {
          owner: null,
          leadgroup: null,
          timeline: null
        },
        report_kpi_2: {
          owner: null,
          sales_person: null,
          sales_person_unfiltered: null,
          timeline: null
        },
        report_budget: {
          owner: null,
          leadgroup: null
        },
        report_marketing: {
          owner: null,
          leadgroup: null,
          timeline: null
        },
        report_benchmarks_leadgroups: {
          owner: null,
          leadgroup: null,
          timeline: null
        }
      },
      presetsDefaults: markRaw({

        report_kpi_declined:{
          owner: [
            {
              name: 'system',
              value: 'declined count,P&M,Customer Canceled,Finance Turn Down',     
              is_default:false,        

            }
          ],
        },
        report_royalty: {
          owner: [
            {
              name: 'system',
              value: 'Sales,Amount,Change,Service,Royalties',     
              is_default:false,        

            }
          ],
        },
        report_kpi_1: {
          owner: [
            {
              name: 'system',
              value: 'Amount,Closing Rate',     
              is_default:false,        

            }
          ],

          leadgroup: [
            {
              name: 'system',
              value: 'Amount,Appt Closing Rate',
              is_default:false,  
            
            }
          ],
          timeline: [
            {
              name: 'system',
              value: 'Amount',
              is_default:false,  
             
            }
          ]
        },
        report_kpi_2: {
          owner: [
            {
              name: 'system',
              value: 'Amount,Sales',
              is_default:false,  
             
            }
          ],
          sales_person: [
            {
              name: 'system',
              value: 'Amount,Sales',
              is_default:false,  
              
            }
          ],
          sales_person_unfiltered: [
            {
              name: 'system',
              value: 'Amount,Sales',
              is_default:false,  
             
            }
          ],
          leadgroup: [
            {
              name: 'system',
              value: 'Amount,Sales',
              is_default:false,  
             
            }
          ],
          timeline: [
            {
              name: 'system',
              value: 'Amount',
              is_default:false,  
             
            }
          ]
        },
        report_budget: {
          owner: [
            {
              name: 'system',
              value: 'Sales Last Year,Sales Budget,Sales This Year,Budget % Delta,YOY',
              is_default:false,  
             
            }
          ],
          leadgroup: [
            {
              name: 'system',
              value: 'Sales Last Year,Sales Budget,Sales This Year,Budget % Delta,YOY',
              is_default:false,  
             
            }
          ]
        },
        report_marketing: {
          owner: [
            {
              name: 'system',
              value: 'Spend Percentage,Spend Amount,Sales Amount',
              is_default:false,  
             
            }
          ],
          leadgroup: [
            {
              name: 'system',
              value: 'Spend Percentage,Spend Amount,Sales Amount',
              is_default:false,  
              
            }
          ],
          timeline: [
            {
              name: 'system',
              value: 'Spend Amount',
              is_default:false,  
             
            }
          ]
        },
        report_benchmarks_leadgroups: {
          owner: [
            {
              name: 'system',
              value: 'Score Weighted',
              is_default:false,  
             
            }
          ],
          leadgroupappsetrate: [
            {
              name: 'system',
              value: 'Appointments,Leads,Appt Set Rate,Benchmark,Appointments Expected,Score,Grade',
              is_default:false,  
             
            }
          ],
          leadgroupissuedrate: [
            {
              name: 'system',
              value: 'Appointments Issued,Appointments,Issued Rate,Benchmark,Appointments Issued Expected,Score,Grade',
              is_default:false,  
             
            }
          ],
          leadgroupdemorate: [
            {
              name: 'system',
              value: 'Quotes,Appointments Issued,Demo Rate,Benchmark,Quotes Expected,Score,Grade',
              is_default:false,  
             
            }
          ],
          leadgroupclosingrate: [
            {
              name: 'system',
              value: 'Sales,Quotes,Closing Rate,Benchmark,Sales Expected,Score,Grade',
              is_default:false,  
             
            }
          ],
          timeline: [
            {
              name: 'system',
              value: 'Appt Set Rate',
              is_default:false,  
             
            }
          ]
        }
      }),
      presets: {},
     
    }
  },

  getters: {
    getPresets: (state) => {
      return (options) => {
        let presetValues = {}
        let report = state.presetsDefaults[options.report_key]
        let table = report[options.table_key]
        presetValues.system = table
        presetValues.user = []

        //now user values
        report = state.presets[options.report_key]

        if (report) {
          let table = report[options.table_key]
          if (table) {
            presetValues.user = table
          }
        }
        return presetValues
      }
    }
  },

  actions: {


   

    //--------------------------------------------------------------------
    process(result) {
     // console.log(result)
     
      for (let i = 0; i < result.length; i++) {
        let item = result[i]

        let report = this.presets[item.report_key]
        if (!report) {
          report = this.presets[item.report_key] = {}
        }

        let table = report[item.table_key]
        if (!table) {
          table = report[item.table_key] = []
        }


        if (item.UserPresetsTablesDefaults.length === 0) { 
          //        
        } else {
          item.is_default = true
        }
        table.push(item)
      }
    },
    //--------------------------------------------------------------------

    async add(presetKeys, selectedTableMetricsOwner) {
      //add preset , it is reactive , so no need to reload presets from backend

      let newPresetName =  this.presetNamesNew[presetKeys.report_key][presetKeys.table_key]
      if(newPresetName === null || newPresetName === undefined || newPresetName === ""){
        return
      }

      let report = this.presets[presetKeys.report_key]
      if (!report) {
        report = this.presets[presetKeys.report_key] = {}
      }

      let table = report[presetKeys.table_key]
      if (!table) {
        table = report[presetKeys.table_key] = []
      }
      if (!selectedTableMetricsOwner) {
        selectedTableMetricsOwner = this.selectedMetrics[presetKeys.report_key][presetKeys.table_key]
      }


      let newPreset = {}
      newPreset.name =
        this.presetNamesNew[presetKeys.report_key][presetKeys.table_key]
      newPreset.value = selectedTableMetricsOwner.toString()
      newPreset.user_id = this.auth.user.user_id
      newPreset.is_global = false;
      newPreset.is_default = false;
      newPreset.table_key = presetKeys.table_key;
      newPreset.report_key = presetKeys.report_key;
      table.push(newPreset)

      this.reportCore.setLoadHeavy(true, 'Add Preset Data')
      try {
        let result = await this.requests.post({
          url: this.urls.getURL('user_presets_tables', 'add'),
          data:newPreset
        })

        if (result) {
          this.reportCore.setLoadHeavy(false)
          newPreset.id = result.data.id         
          this.toasts.setSuccessMessage(result.data.message)
        }
      } catch (error) {
        console.log(error)
        this.reportCore.setLoadHeavy(false)
      }
    },
    //--------------------------------------------------------------------

    setPresetAsDefault(preset, presetKeys) {
      //user
      let report = this.presets[presetKeys.report_key]
      if (report) {
        let table = report[presetKeys.table_key]
        table.forEach((element) => {
          element.is_default = false
        })
      }

      //system
      report = this.presetsDefaults[presetKeys.report_key]
      let table = report[presetKeys.table_key]
      table[0].is_default = false

      preset.is_default = true

      this.update(preset, presetKeys)
    },

    async update(preset, presetKeys) {
      let data = { ...preset }
      data.report_key = presetKeys.report_key
      data.table_key = presetKeys.table_key
      data.user_id = this.auth.user.user_id

      this.reportCore.setLoadHeavy(true, 'Update Preset Data')
      try {
        let result = await this.requests.post({
          url: this.urls.getURL('user_presets_tables', 'update'),
          data
        })

        if (result) {
          

          this.reportCore.setLoadHeavy(false)
          this.toasts.setSuccessMessage(result.data.message)
        }
      } catch (error) {
        console.log(error)
        this.reportCore.setLoadHeavy(false)
      }
    },
    async updateisGlobal(preset, presetKeys) {
      let data = { ...preset }
      data.report_key = presetKeys.report_key
      data.table_key = presetKeys.table_key
      data.user_id = this.auth.user.user_id

      this.reportCore.setLoadHeavy(true, 'Update Preset Data')
      try {
        let result = await this.requests.post({
          url: this.urls.getURL('user_presets_tables', 'update_global'),
          data
        })

        if (result) {
          this.reportCore.setLoadHeavy(false)
          this.toasts.setSuccessMessage(result.data.message)
        }
      } catch (error) {
        console.log(error)
        this.reportCore.setLoadHeavy(false)
      }
    },
    //--------------------------------------------------------------------

    async delete(preset, presetKeys) {
      let report = this.presets[presetKeys.report_key]
      if (report) {
        let table = report[presetKeys.table_key]
        table = table.filter((element) => {
          return element.id !== preset.id
        })
        report[presetKeys.table_key] = table
      }

      this.reportCore.setLoadHeavy(true, 'Delete Preset Data')
      try {
        let result = await this.requests.post({
          url: this.urls.getURL('user_presets_tables', 'delete'),
          data: preset
        })

        if (result) {
          this.reportCore.setLoadHeavy(false)
          //call this because if no more user presets , then system must become default
          //its not actually setting the table to the default , just getting the ui correct..

         

          this.getDefaultPreset(presetKeys)
          this.toasts.setSuccessMessage(result.data.message)
        }
      } catch (error) {
        console.log(error)
        this.reportCore.setLoadHeavy(false)
      }
    },
    //--------------------------------------------------------------------
    getDefaultPreset(presetKeys) {

      let report = this.presets[presetKeys.report_key]
      if (report) {
        let table = report[presetKeys.table_key]
        if (table) {         
          let defaultPreset = table.find((element) => {
            return element.is_default === true

          })
          if (defaultPreset) {
            this.selectedMetrics[presetKeys.report_key][presetKeys.table_key] = defaultPreset.value.split(',')
           return
          }
        }
      }
      //if no user defaults , then system is default
      report = this.presetsDefaults[presetKeys.report_key]
      let table = report[presetKeys.table_key]
      table[0].is_default = true
      table[0] = { ...table[0] }//this is supposed to cause the ui to update, but doesnt always work, not sure if its an id issue? still have to figure it out
      this.selectedMetrics[presetKeys.report_key][presetKeys.table_key] = table[0].value.split(',')
      return
    },
    //--------------------------------------------------------------------

    getSelections(presetKeys) {
      return this.selectedMetrics[presetKeys.report_key][presetKeys.table_key]

    },

    setSelectionToPreset(preset, presetKeys) {
      this.selectedMetrics[presetKeys.report_key][presetKeys.table_key] = preset.value.split(',')


    }
  }
})
