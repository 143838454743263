<template>
  <div
    :key="KPIVerboseModel?.renderKey"
    class="mt-min pa-4 d-flex flex-column"
    style="background-color: var(--content-bg-13)"
  >
    <div class="d-flex align-items-center" style="cursor: pointer" @click="sectionExpandHandler">
      <div class="flex-ui-1 text-type-10">
        <div class="icon-color-1" style="font-weight: 700; font-size: 20px">Drilldown</div>
      </div>

      <span
        class="ms-auto mdi icon icon-color-1"
        :class="{
          ' mdi-plus-circle': !sectionExpanded,
          ' mdi-minus-circle': sectionExpanded
        }"
      >
      </span>
    </div>

    <div v-if="sectionExpanded"></div>
  </div>

  <div class="mt-1 bg pa-2 rounded-2" v-if="sectionExpanded">
    <div
      v-if="KPIVerboseModel.loading"
      class="mt-2 fs-7 mr-2 text-type-10 icon-color-1"
    >
      {{ KPIVerboseModel.loadingMessage }}
    </div>
    <div v-if="!KPIVerboseModel.optionsCache?.ownerFilter" class="fs-7 mr-2 text-type-10 icon-color-1">select an owner</div>
    <div v-if="!KPIVerboseModel.loading && KPIVerboseModel.optionsCache?.ownerFilter" class="mt-2">
      <div
        v-for="(contactItem, index, key) in KPIVerboseModel.contactObjects.data"
        :key="`drilldownitem ${index}`"
        class="mb-2"
        style="cursor: pointer; position: relative"
        @click="expandItem(contactItem, $event)"
      >
        <div class="pa-2 mt-4" style="border-bottom: 1px solid #ccc">
          <div
            class="flex-ui-1 flex-wrap"
            :class="{
              'flex-column': applicationStore.grid.tabletAndUnder,
              'align-items-center': !applicationStore.grid.tabletAndUnder
            }"
          >
            <div>
              <div class="ownerlabel fs-5 mr-2 text-type-10">
                {{ contactItem.name }}
              </div>

              <div class="mt-1 flex-ui-1 flex-wrap">
                <div class="flex-ui-1 px-2" style="color: #ccc; border-left: 1px solid #ccc">
                  <div style="color: #ccc" class="fs-8  ">ID:</div>
                  <div  class="ml-2 fs-10 icon-color-1 text-type-10">{{ contactItem.id }}</div>
                </div>

                <div class="flex-ui-1 px-2" style="color: #ccc; border-left: 1px solid #ccc">
                  <div style="color: #ccc" class="fs-8  ">lead:</div>
                  <div  class="ml-2 fs-10 icon-color-1 text-type-10">{{ contactItem.leadgroup }}</div>
                </div>

                <div class="flex-ui-1 px-2" style="color: #ccc; border-left: 1px solid #ccc">
                  <div style="color: #ccc" class="fs-8  ">source:</div>
                  <div  class=" icon-color-1 ml-2 fs-10 text-type-10">{{ contactItem.sourceName }}</div>
                </div>
              </div>
            </div>

            <div
              :class="{
                'ms-auto': !applicationStore.grid.tabletAndUnder,
                'd-flex': !applicationStore.grid.tabletAndUnder,
                'align-items-end': !applicationStore.grid.tabletAndUnder,
                'flex-column': !applicationStore.grid.tabletAndUnder,
                'mt-1': applicationStore.grid.tabletAndUnder
              }"
            >
             

              <div  class="d-flex flex-wrap gap-2"              
              :class="{
                'flex-row-reverse': !applicationStore.grid.tabletAndUnder,               
              }"              
              >
                <div v-if="contactItem.validSaleValue" class="pa-2 d-flex align-items-baseline " 
                 :style="getValuesStyle(contactItem,1)"
                >
                  <div style="color: #ccc" class=" fs-9  mr-2">Sale</div>
                  <div class="icon-color-1 fs-5 text-type-10 ">{{ contactItem.validSaleValue }}</div>
                </div>
                <div v-if="contactItem.validServiceChangeValue" class="pa-2 d-flex align-items-baseline"
                 :style="getValuesStyle(contactItem,2)"
                >
                  <div style="color: #ccc" class="fs-9  mr-2 ">Change</div>
                  <div class="icon-color-1 fs-5 text-type-10  ">
                    {{ contactItem.validServiceChangeValue }}
                  </div>
                </div>
                <div v-if="contactItem.validServiceServiceValue" class="pa-2 d-flex align-items-baseline  "
                :style="getValuesStyle(contactItem,3)"
                >
                  <div  style="color: #ccc" class=" fs-9  mr-2">Service</div>
                  <div class="icon-color-1 fs-5 text-type-10 ">
                    {{ contactItem.validServiceServiceValue }}
                  </div>
                </div>
              </div>

              <div class="mt-1 flex-ui-1" >
                <div
                  v-for="(validmetric, index) in contactItem.metricValids"
                  :key="`valid_metric ${contactItem.id} ${index}`"
                >
                  <div class="d-flex mr-3 align-items-center" style="color: #ccc">
                    <div class="mr-1 fs-10 text-type-10 icon-color-1">{{ validmetric.label }}:</div>
                    <div :style="getCircleStyle(validmetric.valid)" class="circle fs-10"></div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div v-if="contactItem.expanded">
          <div v-if="contactItem.location">
            <a
              style="display: block; background-color: #fff"
              class="pa-1 my-1 fw-bold"
              :href="`https://serviceminder.com/o/${contactItem.location}/contacts/details/${contactItem.id}`"
              target="_blank"
              rel="noopener noreferrer"
              >open contact on Service Minder</a
            >
          </div>
          <div
            v-for="(kpiItem, index) in contactItem.kpiDataVerbose"
            :key="`${contactItem.id}_${index}`"
            class="px-1 py-min"
            :class="{ 'mb-2': applicationStore.grid.tabletAndUnder }"
            :style="getStyleKPIItemContainer(kpiItem)"
          >
            <div class="w-100 d-grid" :style="getGridStyle">
              <div
                v-for="(kpiItemProp, key, index2) in kpiItem"
                :key="`${contactItem.id}_${index}_${index2}`"
                class="pa-1"
                :style="getStyleKPIItem(kpiItem)"
              >
                <div class="mb-0 fs-9 text-align-left" style="color: #ccc">{{ key }}</div>
                <div class="mb-0 fs-7 fw-bold text-align-left" style="color: #fff">
                  {{ kpiItemProp }}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>

import { useDataReportKPIVerbose } from '@/stores/DataReportKPIVerbose.js'
import { useReportCore } from '@/stores/ReportCore.js'
import { useApplicationStore } from '@/stores/ApplicationStore.js'
import { useScrollToElement } from '@/scripts/utils/ScrollToElement.js'
import { ref,reactive, onMounted, watch, computed, toRaw } from 'vue'

const props = defineProps({
  
  requestKeys: {
    type: Object,
    default: ()=>{
      return {parserKey:"KPIVerboseParser1",queryKey:"KPIVerboseQuery1"}
    }
  }
})

//const reportKPIVerbose = useReportKPIVerbose()
const applicationStore = useApplicationStore()
const reportCore = useReportCore()
const dataReportKPIVerbose = useDataReportKPIVerbose()
const sectionExpanded = ref(false)
var KPIVerboseModel = {};



onMounted(() => {
  KPIVerboseModel = reactive(dataReportKPIVerbose.getModel(props.requestKeys))
 
  KPIVerboseModel.reset()
})





async function sectionExpandHandler() {
  sectionExpanded.value = !sectionExpanded.value

 

  if ( sectionExpanded.value) {
    KPIVerboseModel.invalidate()
  }


}

function expandItem(contactItem, event) {
  console.log(contactItem)
  if (KPIVerboseModel.currentSelected) {
    if (KPIVerboseModel.currentSelected === contactItem) {
      if (KPIVerboseModel.currentSelected.expanded) {
        KPIVerboseModel.currentSelected.expanded = false
        KPIVerboseModel.currentSelected = null
        return
      }
    } else {
      KPIVerboseModel.currentSelected.expanded = false
    }
  }
  KPIVerboseModel.currentSelected = contactItem

  KPIVerboseModel.currentSelected.expanded = true

  useScrollToElement(event.currentTarget, toRaw(applicationStore.contentDomElement))

  //KPIVerboseModel.renderKey++;
}

function getValuesStyle(contactItem,index){
  //index is 1 based

  let multiMode = false;
  let validValuesCount = 0
  if(contactItem.validSaleValue){
    validValuesCount++;
  }
  if(contactItem.validServiceChangeValue){
    validValuesCount++;
  }
  if(contactItem.validServiceServiceValue){
    validValuesCount++;
  }
  if(validValuesCount>1){
    multiMode = true;
  }

  //contactItem.validSaleValue
  //contactItem.validServiceChangeValue
  //contactItem.validServiceServiceValue

  //upcomeing logic : if there was a declined , this would cause value to be red
  if(index === 1){
   // if(multiMode){
      return 'background-color:#03c403'
   // }
  }
  if(index === 2){
   // if(multiMode){
      return 'background-color:#ff4406'
   // }
  }

  if(index === 3){
   // if(multiMode){
      return 'background-color:#ff4406'
   // }
  }






}

const getSaleTextAlign = computed(() => {
  if (!applicationStore.grid.tabletAndUnder) {
    return 'text-align:right;'
  } else {
    return ''
  }
})

const getGridStyle = computed(() => {
  if (!applicationStore.grid.tabletAndUnder) {
    return 'flex: 1 1 0;grid-template-columns: repeat(auto-fit, minmax(90px, 1fr));gap: 0.2rem;'
  } else {
    return 'flex: 1 1 0;grid-template-columns: 1fr 1fr;;gap: 0.2rem;'
  }
})


function getStyleKPIItem(kpiItem) {
  let dateToCheck = kpiItem.date
  if (dateToCheck >= reportCore.dateRangeStart && dateToCheck <= reportCore.dateRangeEnd) {
    return 'background-color: #2c8b9b'
  } else {
    return 'background-color: #2A6393'
  }
}

function getStyleKPIItemContainer(kpiItem) {
  if (!applicationStore.grid.tabletAndUnder) {
    return ''
  } else {
    let dateToCheck = kpiItem.date
    if (dateToCheck >= reportCore.dateRangeStart && dateToCheck <= reportCore.dateRangeEnd) {
      return 'background-color: #144169'
    } else {
      return 'background-color: #133f66'
    }
  }
}



//---------------------------------------------------------------------------------------

function getCircleStyle(metricValid) {
  if (metricValid) {
    return 'background-color: #29ab29'
  } else {
    return 'background-color: #333'
  }
}
</script>

<style scoped>
.bg {
  background-color: #165489;
}

.ownerlabel {
  color: #ffca00;
}

.circle {
  display: flex;
  justify-content: center;
  align-items: center;
  flex: 0 0 auto;
  width: 10px;
  height: 10px;
  color: white;
  font-size: 10px;
  font-weight: 600;
  border-radius: 50%;
  text-align: center;
  line-height: 100px;
}

.contact-grid {
  display: grid;
  grid-template-columns: 1fr 1fr; /* Two equal columns */
  grid-template-rows: repeat(3, auto); /* Three rows */
  background-color: transparent;
  gap: 1px; /* Small gap between cells */
  padding: 10px;
}

.contact-grid div {
  background-color: transparent;
  border: 1px solid rgba(255, 255, 255, 0.5); /* Semi-transparent border on all grid cells */
  padding: 5px; /* Optional padding for each cell */
  display: flex;
  align-items: center;
  justify-content: center;
}
</style>
