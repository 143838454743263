import { useAuth } from '@/stores/Auth.js'
export function useSideNavUI() {
  let auth = useAuth();

  function getSystemUIChildren() {
    let children = []

    let ownerUI = {
      display: {
        label: 'Users'
      },
      key: 'users',
        
      radio_group: 'content',
      component_type: 'Button1',
      interaction: {
        toggle:false,  
        action: {
          path: '/application/user_management'
        }
      }
    }

    let locationUI = {
      display: {
        label: 'Locations'
      },
      key: 'locations',
      
      radio_group: 'content',
      component_type: 'Button1',
      interaction: {
        toggle:false,    
        action: {
          path: '/application/location_management'
        }
      }
    }

    let leadGroupUI = {
      display: {
        label: 'Lead Groups'
      },
      key: 'leadgroups',
     
      radio_group: 'content',
      component_type: 'Button1',
      interaction: {
        toggle:false,     
        action: {
          path: '/application/leadgroup_management'
        }
      }
    }

    let budgetSaleUI = {
      display: {
        label: 'Budget Sales'
      },
      key: 'budget_sale_management',
    
      radio_group: 'content',
      component_type: 'Button1',
      interaction: {
        toggle:false,     
        action: {
          path: '/application/budget_sale_management'
        }
      }
    }

    let marketingSpendUI = {
      display: {
        label: 'Marketing Spend'
      },
      key: 'marketing_spend_management',
    
      radio_group: 'content',
      component_type: 'Button1',
      interaction: {
        toggle:false,     
        action: {
          path: '/application/marketing_spend_management'
        }
      }
    }

    let benchmarkLeadGroupsManagementUI = {
      display: {
        label: 'Benchmarks Lead Groups'
      },
      key: 'benchmark_lead_groups_management',
    
      radio_group: 'content',
      component_type: 'Button1',
      interaction: {
        toggle:false,     
        action: {
          path: '/application/benchmark_lead_group_management'
        }
      }
    }

    let reportFilterPresetManagementUI = {
      display: {
        label: 'Report Filter Presets'
      },
      key: 'report_filter_preset_management',
    
      radio_group: 'content',
      component_type: 'Button1',
      interaction: {
        toggle:false,     
        action: {
          path: '/application/report_filter_preset_management'
        }
      }
    }

   

    
    

   
    

   

    if (auth.hasPermission('view_page_user_management')) {
      children.push(ownerUI)
    }
    if (auth.hasPermission('view_page_location_management')) {
      children.push(locationUI)
    }
    if (auth.hasPermission('view_page_lead_group_management')) {
      children.push(leadGroupUI)
    }

    if (auth.hasPermission('view_page_budget_sale_management')) {
      children.push(budgetSaleUI)
    }

    if (auth.hasPermission('view_page_marketing_spend_management')) {
      children.push(marketingSpendUI)
    }

    if (auth.hasPermission('view_benchmark_lead_group_management')) {
      children.push(benchmarkLeadGroupsManagementUI)
    }

    if (auth.hasPermission('view_user_report_filter_preset_management')) {
      children.push(reportFilterPresetManagementUI)
    }

    

   

    


   

    return children
  }

  function getAnalyticsUIChildren() {
    let children = []
    let kpi1UI = {
      display: {
        label: 'KPI Report'
      },
      key: 'kpi_owners', 
     
      component_type: 'Button1',
      radio_group: 'content',
      interaction: {
        toggle:false,     
        action: {
          path: '/application/kpi1_report'
        }
      }
    }

    let kpi2UI = {
      display: {
        label: 'Sales Persons Report'
      },
      key: 'kpi_sales_agents', 
     
      component_type: 'Button1',
      radio_group: 'content',
      interaction: {
        toggle:false,     
        action: {
          path: '/application/kpi2_report'
        }
      }
    }

    let budgetReportUI = {
      display: {
        label: 'Budget Report'
      },
      key: 'budgetreport',
     
     
      component_type: 'Button1',
      radio_group: 'content',
      interaction: {
        toggle:false,     
        action: {
          path: '/application/budget_sale_report'
        }
      }
    }

    let marketingReportUI = {
      display: {
        label: 'Marketing Report'
      },
      key: 'marketingreport',
     
     
      component_type: 'Button1',
      radio_group: 'content',
      interaction: {
        toggle:false,     
        action: {
          path: '/application/marketing_spend_report'
        }
      }
    }


    let benchmarkLeadGroupsUI = {
      display: {
        label: 'Benchmarks Lead Groups'
      },
      key: 'benchmark_lead_groups',
    
      radio_group: 'content',
      component_type: 'Button1',
      interaction: {
        toggle:false,     
        action: {
          path: '/application/benchmark_lead_group_report'
        }
      }
    }

    let ownerRoyaltyUI = {
      display: {
        label: 'Royalties'
      },
      key: 'owner_royalties_report',
    
      radio_group: 'content',
      component_type: 'Button1',
      interaction: {
        toggle:false,     
        action: {
          path: '/application/report_royalty'
        }
      }
    }

    let KPIDeclinedUI = {
      display: {
        label: 'KPI Declined'
      },
      key: 'KPIDeclinedUI',
    
      radio_group: 'content',
      component_type: 'Button1',
      interaction: {
        toggle:false,     
        action: {
          path: '/application/report_kpi_declined'
        }
      }
    }

    if (auth.hasPermission('view_page_kpi1_report')) {
      children.push(kpi1UI)
    }
    if (auth.hasPermission('view_page_kpi2_report')) {
      children.push(kpi2UI)
    }

    if (auth.hasPermission('view_page_budget_sale_report')) {
      children.push(budgetReportUI)
    }

    if (auth.hasPermission('view_page_marketing_spend_report')) {
      children.push(marketingReportUI)
    }

    if (auth.hasPermission('view_benchmark_lead_group')) {
      children.push(benchmarkLeadGroupsUI)
    }

    if (auth.hasPermission('view_owner_royalty_percentages_report')) {
      children.push(ownerRoyaltyUI)
    }

    if (auth.hasPermission('view_report_kpi_declined')) {
      children.push(KPIDeclinedUI)
    }

    return children
  }

  //----------------------------------------------
  let systemUIChildren = getSystemUIChildren()

  let systemUI = {
    display: {
      label: 'System'
    },

    key: 'system',
    radio_group: 'root',
    component_type: 'Accordion1',
    children: getSystemUIChildren()
  }
  //----------------------------------------------

  let analyticsUIChildren = getAnalyticsUIChildren();
  let analyticsUI = {
    display: {
      label: 'Analytics'
    },

    key: 'analytics',
   enabled:true,
    radio_group: 'root',
    component_type: 'Accordion1',
    children: getAnalyticsUIChildren()
  }

  let uiData = [];
  if(systemUIChildren.length){
    uiData.push(systemUI)
  }
  if(analyticsUIChildren.length){
    uiData.push(analyticsUI)
  }

  return uiData
}
