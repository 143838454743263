import { defineStore } from 'pinia'
import { markRaw, computed } from 'vue'
import { useReportKPI1 } from '@/stores/ReportKPI1.js'
import { useReportKPI2 } from '@/stores/ReportKPI2.js'
import { useReportMarketing } from '@/stores/ReportMarketing.js'
import { useReportBudget } from '@/stores/ReportBudget.js'
import { useReportKPIBenchmarkLeadGroup } from '@/stores/ReportKPIBenchmarkLeadGroup.js'
import { useDataPresetsTablesStore } from '@/stores/DataPresetsTablesStore.js'
import { useReportRoyaltyStore } from '@/stores/ReportRoyaltyStore.js'
import { useReportKPIDeclinedStore } from '@/stores/ReportKPIDeclinedStore.js'

export const useDynamicConfigStore = defineStore('DynamicConfigStore', {
  state: () => {
    return {
      configs: markRaw({
        reportKPI1TableOwner: {
          relatedStore: useReportKPI1(),
          presetStore: useDataPresetsTablesStore(),
          dataTable: useReportKPI1().dataTableOwners,
          vShowKey: "Owners",
          presetKeys: { report_key: 'report_kpi_1', table_key: 'owner' },
          tableColumnsMetrics: useReportKPI1().tableColumnsMetrics,
          tableHeaderKey:"Owner",
          selectionFilters: [
            {
              selectionFilter: "ownerFilter",
              dimensionStatic: "Owner",
            }
          ],
          tableName: "Owners",
          classDynamic: "data-table-1",
          sortField: "Amount"
        },

        reportKPI1TableLeadGroup: {
          relatedStore: useReportKPI1(),
          presetStore: useDataPresetsTablesStore(),
          dataTable: useReportKPI1().dataTableGroups,
          vShowKey: "Lead Groups",
          presetKeys: { report_key: 'report_kpi_1', table_key: 'leadgroup' },
          tableColumnsMetrics: useReportKPI1().tableColumnsMetrics,
          tableName: "Lead Groups",
          tableHeaderKey:"Lead Group",
          selectionFilters: [
            {
              selectionFilter: "leadGroupFilter",
              dimensionStatic: "Lead Group",
            }
          ],


          classDynamic: "data-table-2",
          sortField: "Amount"
        },


        reportMarketingTableOwner: {
          relatedStore: useReportMarketing(),
          presetStore: useDataPresetsTablesStore(),
          dataTable: useReportMarketing().dataTableOwners,
          vShowKey: "Owners",
          presetKeys: { report_key: 'report_marketing', table_key: 'owner' },
          tableColumnsMetrics: useReportMarketing().tableColumnsMetrics,
          tableName: "Owners",
          tableHeaderKey:"Owner",
          selectionFilters: [
            {
              selectionFilter: "ownerFilter",
              dimensionStatic: "Owner",
            }
          ],

          classDynamic: "data-table-1",
          sortField: "Spend Amount"
        },


        reportMarketingTableLeadGroup: {
          relatedStore: useReportMarketing(),
          presetStore: useDataPresetsTablesStore(),
          dataTable: useReportMarketing().dataTableGroups,
          vShowKey: "Lead Groups",
          presetKeys: { report_key: 'report_marketing', table_key: 'leadgroup' },
          tableColumnsMetrics: useReportMarketing().tableColumnsMetrics,
          tableName: "Lead Groups",
          tableHeaderKey:"Lead Group",
          selectionFilters: [
            {
              selectionFilter: "leadGroupFilter",
              dimensionStatic: "Lead Group",
            }
          ],

          classDynamic: "data-table-2",
          sortField: "Spend Amount"
        },

        //---------------------------------------------------

        reportKPI2TableOwner: {
          relatedStore: useReportKPI2(),
          presetStore: useDataPresetsTablesStore(),
          dataTable: useReportKPI2().dataTableOwners,
          vShowKey: "Owners",
          presetKeys: { report_key: 'report_kpi_2', table_key: 'owner' },
          tableColumnsMetrics: useReportKPI2().tableColumnsMetrics,
          tableHeaderKey:"Owner",
          selectionFilters: [
            {
              selectionFilter: "ownerFilter",
              dimensionStatic: "Owner",
            }
          ],

          tableName: "Owners",

          classDynamic: "data-table-1",
          sortField: "Amount"
        },

        reportKPI2TableSalesPerson: {
          relatedStore: useReportKPI2(),
          presetStore: useDataPresetsTablesStore(),
          dataTable: useReportKPI2().dataTableSalesPersons,
          vShowKey: "Sales Persons",
          presetKeys: { report_key: 'report_kpi_2', table_key: 'sales_person' },
          tableColumnsMetrics: useReportKPI2().tableColumnsMetrics,
          tableHeaderKey:"Sales Person",
          selectionFilters: [
            {
              selectionFilter: "salesPersonFilter",
              dimensionStatic: "Sales Person",
              dimensionStaticAlt: "Sales Person Raw",
            },
            {
              selectionFilter: "ownerFilterAlt",
              dimensionStatic: "Owner",
            }
          ],

          tableName: "Sales Persons",

          classDynamic: "data-table-3",
          sortField: "Amount"
        },

        reportKPI2TableSalesPersonUnfiltered: {
          relatedStore: useReportKPI2(),
          presetStore: useDataPresetsTablesStore(),
          dataTable: useReportKPI2().dataTableSalesPersonsUnfiltered,
          vShowKey: "Sales Persons All",
          presetKeys: { report_key: 'report_kpi_2', table_key: 'sales_person_unfiltered' },
          tableColumnsMetrics: useReportKPI2().tableColumnsMetrics,
          tableHeaderKey:"Sales Person",
          selectionFilters: [
            {
              selectionFilter: "salesPersonUnfilteredFilter",
              dimensionStatic: "Sales Person",
              dimensionStaticAlt: "Sales Person Raw",
            },
            {
              selectionFilter: "ownerFilterAlt",
              dimensionStatic: "Owner",
            }
          ],

          tableName: "Sales Persons All",

          classDynamic: "data-table-2",
          sortField: "Amount"
        },

        reportKPI2TableLeadGroup: {
          relatedStore: useReportKPI2(),
          presetStore: useDataPresetsTablesStore(),
          dataTable: useReportKPI2().dataTableGroups,
          vShowKey: "Lead Groups",
          presetKeys: { report_key: 'report_kpi_2', table_key: 'leadgroup' },
          tableColumnsMetrics: useReportKPI2().tableColumnsMetrics,
          tableHeaderKey:"Lead Group",
          selectionFilters: [
            {
              selectionFilter: "leadGroupFilter",
              dimensionStatic: "Lead Group",
            }
          ],

          tableName: "Lead Groups",

          classDynamic: "data-table-2",
          sortField: "Amount"
        },

        reportBudgetTableOwner: {
          relatedStore: useReportBudget(),
          presetStore: useDataPresetsTablesStore(),
          dataTable: useReportBudget().dataTableOwners,
          vShowKey: "Owners",
          presetKeys: { report_key: 'report_budget', table_key: 'owner' },
          tableColumnsMetrics: useReportBudget().tableColumnsMetrics,
          tableHeaderKey:"Owner",
          selectionFilters: [
            {
              selectionFilter: "ownerFilter",
              dimensionStatic: "Owner",
            }
          ],

          tableName: "Owners",

          classDynamic: "data-table-1",
          sortField: "Sales This Year"
        },

        reportBudgetTableLeadGroup: {
          relatedStore: useReportBudget(),
          presetStore: useDataPresetsTablesStore(),
          dataTable: useReportBudget().dataTableGroups,
          vShowKey: "Lead Groups",
          presetKeys: { report_key: 'report_budget', table_key: 'leadgroup' },
          tableColumnsMetrics: useReportBudget().tableColumnsMetrics,
          tableName: "Lead Groups",
          tableHeaderKey:"Lead Group",
          selectionFilters: [
            {
              selectionFilter: "leadGroupFilter",
              dimensionStatic: "Lead Group",
            }
          ],


          classDynamic: "data-table-2",
          sortField: "Sales This Year"
        },
        reportKPIBenchmarkLeadGroupOwner: {
          relatedStore: useReportKPIBenchmarkLeadGroup(),
          presetStore: useDataPresetsTablesStore(),
          dataTable: useReportKPIBenchmarkLeadGroup().dataTableOwners,
          vShowKey: "Owners",
          presetKeys: { report_key: 'report_benchmarks_leadgroups', table_key: 'owner' },
          tableColumnsMetrics: useReportKPIBenchmarkLeadGroup().tableColumnsOwnerMetrics,
          tableName: "Owners",
          tableHeaderKey:"Owner",
          selectionFilters: [
            {
              selectionFilter: "ownerFilter",
              dimensionStatic: "Owner",
            }
          ],

          classDynamic: "data-table-1",
          sortField: "Score Weighted"

        },

        reportKPIBenchmarkLeadGroupLeadGroupAppSetRate: {
          relatedStore: useReportKPIBenchmarkLeadGroup(),
          presetStore: useDataPresetsTablesStore(),
          dataTable: useReportKPIBenchmarkLeadGroup().dataTableGroupsApptSetRate,
          vShowKey: "Lead Group Appt Set Rate",
          presetKeys: { report_key: 'report_benchmarks_leadgroups', table_key: 'leadgroupappsetrate' },
          tableColumnsMetrics: useReportKPIBenchmarkLeadGroup().tableColumnsLeadGroupsApptSetRateMetrics,
          tableName: "Lead Groups Appt Set Rate",
          tableHeaderKey:"Lead Group",
          selectionFilters: [
            {
              selectionFilter: "leadGroupFilter",
              dimensionStatic: "Lead Group",
            }
          ],

          classDynamic: "data-table-2",
          sortField: "Score"

        },
        reportKPIBenchmarkLeadGroupLeadGroupIssuedRate: {
          relatedStore: useReportKPIBenchmarkLeadGroup(),
          presetStore: useDataPresetsTablesStore(),
          dataTable: useReportKPIBenchmarkLeadGroup().dataTableGroupsIssuedRate,
          vShowKey: "Lead Group Issued Rate",
          presetKeys: { report_key: 'report_benchmarks_leadgroups', table_key: 'leadgroupissuedrate' },
          tableColumnsMetrics: useReportKPIBenchmarkLeadGroup().tableColumnsLeadGroupsIssuedRateMetrics,
          tableName: "Lead Groups Issued Rate",
          tableHeaderKey:"Lead Group",
          selectionFilters: [
            {
              selectionFilter: "leadGroupFilter",
              dimensionStatic: "Lead Group",
            }
          ],

          classDynamic: "data-table-2",
          sortField: "Score"

        },
        reportKPIBenchmarkLeadGroupLeadGroupDemoRate: {
          relatedStore: useReportKPIBenchmarkLeadGroup(),
          presetStore: useDataPresetsTablesStore(),
          dataTable: useReportKPIBenchmarkLeadGroup().dataTableGroupsDemoRate,
          vShowKey: "Lead Group Demo Rate",
          presetKeys: { report_key: 'report_benchmarks_leadgroups', table_key: 'leadgroupdemorate' },
          tableColumnsMetrics: useReportKPIBenchmarkLeadGroup().tableColumnsLeadGroupsDemoRateMetrics,
          tableName: "Lead Groups Demo Rate",
          tableHeaderKey:"Lead Group",
          selectionFilters: [
            {
              selectionFilter: "leadGroupFilter",
              dimensionStatic: "Lead Group",
            }
          ],

          classDynamic: "data-table-2",
          sortField: "Score"

        },
        reportKPIBenchmarkLeadGroupLeadGroupClosingRate: {
          relatedStore: useReportKPIBenchmarkLeadGroup(),
          presetStore: useDataPresetsTablesStore(),
          dataTable: useReportKPIBenchmarkLeadGroup().dataTableGroupsClosingRate,
          vShowKey: "Lead Group Closing Rate",
          presetKeys: { report_key: 'report_benchmarks_leadgroups', table_key: 'leadgroupclosingrate' },
          tableColumnsMetrics: useReportKPIBenchmarkLeadGroup().tableColumnsLeadGroupsClosingRateMetrics,
          tableName: "Lead Groups Closing Rate",
          tableHeaderKey:"Lead Group",
          selectionFilters: [
            {
              selectionFilter: "leadGroupFilter",
              dimensionStatic: "Lead Group",
            }
          ],

          classDynamic: "data-table-2",
          sortField: "Score"

        },

        reportRoyaltyTableOwner: {
          relatedStore: useReportRoyaltyStore(),
          presetStore: useDataPresetsTablesStore(),
          dataTable: useReportRoyaltyStore().dataTableOwners,
          vShowKey: "Owners",
          presetKeys: { report_key: 'report_royalty', table_key: 'owner' },
          tableColumnsMetrics: useReportRoyaltyStore().tableColumnsMetrics,
          tableHeaderKey:"Owner",
          selectionFilters: [
            {
              selectionFilter: "ownerFilter",
              dimensionStatic: "Owner",
            }
          ],

          tableName: "Owners",

          classDynamic: "data-table-1",
          sortField: "Royalties"
        },

        reportKPIDeclinedTableOwner: {
          relatedStore: useReportKPIDeclinedStore(),
          presetStore: useDataPresetsTablesStore(),
          dataTable: useReportKPIDeclinedStore().dataTableOwners,
          vShowKey: "Owners",
          presetKeys: { report_key: 'report_kpi_declined', table_key: 'owner' },
          tableColumnsMetrics: useReportKPIDeclinedStore().tableColumnsMetrics,
          tableHeaderKey:"Owner",
          selectionFilters: [
            {
              selectionFilter: "ownerFilter",
              dimensionStatic: "Owner",
            }
          ],

          tableName: "Owners",

          classDynamic: "data-table-1",
          sortField: "Owner",
          sortOrder:1
        },

      })
    }
  },

  actions: {
    getConfig(configKey) {
      return this.configs[configKey]
    }
  }
})
