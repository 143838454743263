import { defineStore } from 'pinia'

import { useURLS } from '@/stores/URLS.js'
import { useDataReportKPIVerbose } from '@/stores/DataReportKPIVerbose.js'
import { useReportCore } from '@/stores/ReportCore.js'
import { useDataKPI2Store } from '@/stores/DataKPI2Store.js'
import { useDataBenchmarkLeadGroupStore } from '@/stores/DataBenchmarkLeadGroupStore.js'
import { useKPIBenchmarkLeadGroupManagement } from '@/stores/KPIBenchmarkLeadGroupManagement.js'
import { useDataPresetsReportFiltersStore } from '@/stores/DataPresetsReportFiltersStore.js'
import { useBuildMetricDataTables2 } from '@/scripts/utils/BuildMetricDataTables2.js'
import { shallowRef, markRaw } from 'vue'
import { useAuth } from '@/stores/Auth.js'
import { useToasts } from '@/stores/Toasts.js'
import { useErrorHandler } from '@/stores/ErrorHandler.js'

export const useReportKPIBenchmarkLeadGroup = defineStore('ReportKPIBenchmarkLeadGroup', {
  state: () => {
    return {
      reportCore: markRaw(useReportCore()),
      dataKPI2Store: markRaw(useDataKPI2Store()),
      dataBenchmarkLeadGroupStore: markRaw(useDataBenchmarkLeadGroupStore()),
      toasts: markRaw(useToasts()),
      dataPresetsReportFiltersStore:markRaw(useDataPresetsReportFiltersStore()),
      kpiBenchmarkLeadGroupManagement: markRaw(useKPIBenchmarkLeadGroupManagement()),
      dataReportKPIVerbose:markRaw(useDataReportKPIVerbose()),

      gaugeConfig: {
        maxValue: 850,
        segments: [
          { percentage: 0.6824, value: 580, color: '#FF0000' },
          { percentage: 0.1059, value: 90, color: '#FF8000' },
          { percentage: 0.0824, value: 70, color: '#FFFF00' },
          { percentage: 0.0706, value: 60, color: '#80FF00' },
          { percentage: 0.0588, value: 50, color: '#00FF00' }
        ],
        renderKey: 0,
        useLables: true,
        useWedgeStrokes: true,
        needleValue: 0,
        textPadding: 30,
        textPaddingLabel: 20,
        textSize: 64,
        textSizeLabel: 14,
        textColor: 'black',
        textColorLabel: '#888888',
        wedgeStrokeWidth: 10,
        canvasSize: 250
      },

      dataTableOwners: {},
      dataTableGroups: {},

      dataTableGroupsApptSetRate: {},
      dataTableGroupsIssuedRate: {},
      dataTableGroupsDemoRate: {},
      dataTableGroupsClosingRate: {},

      dataTableLocations: {},

      tableDataOwnersObj: null,
      tableDataLocationsObj: null,
      tableDataSalesPersonsObj: null,
      tableDataLeadGroupsObj: null,

      tableDataLeadGroups: null,

      scoreWeightedScalarApptSetRate: 1,
      scoreWeightedScalarIssuedRate: 1.2,
      scoreWeightedScalarClosingRate: 6,
      scoreWeightedScalarDemoRate: 0.3,

      currentLineChartMetric: 'Appt Set Rate Score',

      metrics: markRaw({}),

      section1Expanded: false,

      showDebugData: false,

      ownerFilter: null,
      locationFilter: null,
      leadGroupFilter: null,

      scoreCards: shallowRef({}),

      reportElementProps: markRaw([
        'APPOINTMENTS_COUNT',
        'ISSUED_APPOINTMENTS_COUNT',
        'QUOTES_COUNT',
        'SALES_COUNT',
        'LEADS_COUNT',
        'SALES_SUM'
      ]),

      tableColumnsOwnerMetrics: markRaw(['Score Weighted', 'Appt Set Rate Score', 'Issued Rate Score', 'Demo Rate Score', 'Closing Rate Score']),

      benchmarkCurrent: null,

      currentLineChartMetricTitle: null,

      currentLineChartMetricMap: markRaw({
        'Appt Set Rate Score': 'Appt Set Rate',
        'Issued Rate Score': 'Issued Rate',
        'Demo Rate Score': 'Demo Rate',
        'Closing Rate Score': 'Closing Rate',
      }),

      timelineOptionsMetrics: markRaw([
        'Appt Set Rate Score',
        'Closing Rate Score',
        'Demo Rate Score',
        'Issued Rate Score',
        'Score Weighted'
      ]),

      tableColumnsLeadGroupsMetricCurrent: null,

      //Appt Set Rate

      tableColumnsLeadGroupsApptSetRateMetrics: markRaw([
        'Appointments',
        'Leads',
        'Appt Set Rate',
        'Benchmark',
        'Appointments Expected',
        'Score',
        'Grade'
      ]),

      //Issued Rate
      tableColumnsLeadGroupsIssuedRateMetrics: markRaw([
        'Appointments Issued',
        'Appointments',
        'Issued Rate',
        'Benchmark',
        'Appointments Issued Expected',
        'Score',

        'Grade'
      ]),

      //Closing Rate
      tableColumnsLeadGroupsClosingRateMetrics: markRaw([
        'Sales',
        'Quotes',
        'Closing Rate',
        'Benchmark',
        'Sales Expected',
        'Score',
        'Grade'
      ]),

      //Demo Rate
      tableColumnsLeadGroupsDemoRateMetrics: markRaw([
        'Quotes',
        'Appointments Issued',
        'Demo Rate',
        'Benchmark',
        'Quotes Expected',
        'Score',
        'Grade'
      ]),

      timeLineDataObj: null,

      showFocusedChartData: false,
      chartKey: 0,
      chartOptions: null,

      timelineMetrics: markRaw({}),

      timelineData: {
        labels: [
          'January',
          'February',
          'March',
          'April',
          'May',
          'June',
          'July',
          'August',
          'September',
          'October',
          'November',
          'December'
        ],
        datasets: [
          {
            label: new Date().getFullYear(),
            fill: false,
            lengendBlockStyle: 'width:20px;height:20px;background-color:#ad84aa;',
            borderColor: '#ad84aa',
            yAxisID: 'y',
            tension: 0,
            pointRadius: 6,
            data: []
          },
          {
            label: new Date().getFullYear() - 1,
            fill: false,
            lengendBlockStyle: 'width:20px;height:20px;background-color:#6b7887;',
            borderColor: '#6b7887',
            yAxisID: 'y',
            tension: 0,
            pointRadius: 6,
            data: []
          }
        ]
      }
    }
  },
  getters: {
    leadGrouptableVShow(state) {
      if (!state.benchmarkCurrent) {
        return ""
      }
      return `Lead Group ${state.benchmarkCurrent.name}`


    },


  },
  actions: {
    changeTimelineMetric(metric) {
      this.currentLineChartMetricTitle = metric
      metric = this.currentLineChartMetric = this.currentLineChartMetricMap[metric] || metric
       
      this.timelineSingleMetricSet1 = this.timelineMetrics[metric][0]
      this.timelineSingleMetricSet2 = this.timelineMetrics[metric][1]
      this.timelineData.datasets[0].data = this.timelineMetrics[metric][0].map(
        (dataPoint) => dataPoint.value
      )
      this.timelineData.datasets[1].data = this.timelineMetrics[metric][1].map(
        (dataPoint) => dataPoint.value
      )
    },

    async validateInputBenchmarkWeightValue(benchmark) {
      await this.kpiBenchmarkLeadGroupManagement.validateInputBenchmarkWeightValue(benchmark)
      this.buildReportProcess7()
    },

    selectAndInvalidateBenchmark(benchmark) {
      this.selectBenchmark(benchmark)
      this.invalidateBenchmark()
    },

    selectBenchmark(benchmark) {
      //build process 7 calls this function without argument
      //on first run this.benchmarkCurrent would be set either
      //after that it is always set

      if (!benchmark) {
        benchmark = this.benchmarkCurrent
      }

      if (!benchmark) {
        let data = this.dataBenchmarkLeadGroupStore.benchmarkDataCurrent

        for (let prop in data) {
          benchmark = data[prop]
          break
        }
      }

      if (this.benchmarkCurrent) {
        this.benchmarkCurrent.selected = false
      }
      this.benchmarkCurrent = benchmark
      this.benchmarkCurrent.selected = true
    },
    invalidateBenchmark() {
      // let key = this.benchmarkCurrent.name.split(' ').join('')
      // this.tableColumnsLeadGroupsMetricCurrent = this[`tableColumnsLeadGroups${key}Metrics`]
      /*
            let headers = []
            let header = {}
            header.field = 'Lead Group'
            header.header = 'Lead Group'
            headers.push(header)
            this.tableColumnsLeadGroupsMetricCurrent.forEach((element) => {
              let header = {}
              header.field = element
              header.header = element
              headers.push(header)
            })
            this.dataTableGroups.tableHeaders = headers*/

      //this.dataTableGroups.tableData = this.tableDataLeadGroups[this.benchmarkCurrent.name]
    },



    async refreshQueryCache($event, key) {
      let asyncProcessSuccessful = false
      asyncProcessSuccessful = await this.dataKPI2Store.refreshQueryCache($event, key)
      if (!asyncProcessSuccessful) return
      asyncProcessSuccessful = await this.dataBenchmarkLeadGroupStore.loadData()
      if (!asyncProcessSuccessful) return

      this.ownerFilter = null
      this.locationFilter = null
      this.leadGroupFilter = null

      this.buildReport()
    },

    async loadAllData() {
      //gets all kpi data
      let asyncProcessSuccessful = false
      asyncProcessSuccessful = await this.dataKPI2Store.loadDataAll()
      if (!asyncProcessSuccessful) return
      asyncProcessSuccessful = await this.dataBenchmarkLeadGroupStore.loadData()
      if (!asyncProcessSuccessful) return

      this.ownerFilter = null
      this.locationFilter = null
      this.leadGroupFilter = null

      this.buildReport()
    },

    updateChartFocusMode(val) {
      if (val) {
        this.chartOptions.scales.y.suggestedMax = this.chartSuggestedYMax
        this.chartOptions.scales.y.min = this.chartYMin
      } else {
        this.chartOptions.scales.y.suggestedMax = 0
        this.chartOptions.scales.y.min = 0
      }
      this.chartKey++
    },

    //-------------------------------------------------------------------------------------------------

    getLeadGroupStorage(ownerName, locationName, leadGroupName) {
      let ownerStorage = this.getOwnerStorage(ownerName)
      let locationStorage = ownerStorage.locations[locationName]
      if (!locationStorage.leadgroups) {
        locationStorage.leadgroups = {}
      }

      let leadGroupStorage = locationStorage.leadgroups[leadGroupName]

      if (!leadGroupStorage) {
        leadGroupStorage = locationStorage.leadgroups[leadGroupName] = {}
        let systemLeadGroup = this.reportCore.dataLeadGroups[leadGroupName]
        let isMapped = true
        if (!systemLeadGroup) {
          isMapped = false
        }

        leadGroupStorage.isMapped = isMapped
        leadGroupStorage.name = leadGroupName
        leadGroupStorage.ownerName = ownerName
        leadGroupStorage.ownerType = ownerStorage.ownerType
        leadGroupStorage.data = {}
        leadGroupStorage.dataRolling22 = {}
        leadGroupStorage.dataRolling30 = {}
        leadGroupStorage.dataRolling60 = {}
      }

      return leadGroupStorage
    },

    getLocationStorage(ownerName, locationName) {
      let ownerStorage = this.getOwnerStorage(ownerName)
      if (!ownerStorage.locations) {
        ownerStorage.locations = {}
      }

      let locationStorage = ownerStorage.locations[locationName]

      if (!locationStorage) {
        locationStorage = ownerStorage.locations[locationName] = {}
        let systemLocation = this.reportCore.dataLocations[locationName]
        let isMapped = true
        if (!systemLocation) {
          isMapped = false
        }

        locationStorage.isMapped = isMapped
        locationStorage.name = locationName
        locationStorage.ownerName = ownerName
        locationStorage.ownerType = ownerStorage.ownerType
        locationStorage.data = {}
      }

      return locationStorage
    },

    getOwnerStorage(ownerName) {
      let ownerStorage = this.tableDataOwnersObj[ownerName]
      if (!ownerStorage) {
        ownerStorage = this.tableDataOwnersObj[ownerName] = {}
        let systemOwner = this.reportCore.dataOwners[ownerName]

        let isMapped = true
        let ownerType = null
        if (!systemOwner) {
          isMapped = false
        } else {
          if (systemOwner.started_at.year !== this.reportCore.currentDate.year) {
            ownerType = 'legacy'
          }

          if (systemOwner.started_at.year === this.reportCore.currentDate.year) {
            ownerType = 'new'
          }
        }

        ownerStorage.isMapped = isMapped
        ownerStorage.ownerType = ownerType
        ownerStorage.name = ownerName
        ownerStorage.data = {}
        ownerStorage.dataRolling22 = {}
        ownerStorage.dataRolling30 = {}
        ownerStorage.dataRolling60 = {}
      }

      return ownerStorage
    },

    //-------------------------------------------------------------------------------------------------

    buildReportProcess7() {
      this.selectBenchmark()

      let tableDataOwners = []

      let tableDataLocations = []

      this.tableDataLeadGroups = {}

      for (let benchmark in this.kpiBenchmarkLeadGroupManagement.kpiBenchmarkOptions) {
        this.tableDataLeadGroups[benchmark] = []
      }

      for (let ownerName in this.tableDataOwnersObj) {
        let ownerItem = this.tableDataOwnersObj[ownerName]
        ownerItem.data.OWNER = ownerName

        this.buildMetricsTableOwners(ownerItem, tableDataOwners)
        if (!ownerItem.isMapped) {
          let unmappedItem = this.dataKPI2Store.unmappedKPIOwnersCORE[ownerName]
          let unmappedItem2 = this.dataKPI2Store.unmappedKPIOwnersSM[ownerName]
          if (unmappedItem) {
            unmappedItem.props.sales.value = this.reportCore.formatCurrency(
              ownerItem.data.SALES_SUM
            )
          }
          if (unmappedItem2) {
            unmappedItem2.props.sales.value = this.reportCore.formatCurrency(
              ownerItem.data.SALES_SUM
            )
          }
        }
      }

      for (let locationName in this.tableDataLocationsObj) {
        let locationItem = this.tableDataLocationsObj[locationName]
        locationItem.data.LOCATION = locationName
        // this.buildMetricsTables(locationItem.data, tableDataLocations)
      }

      for (let leadGroupName in this.tableDataLeadGroupsObj) {
        let leadGroupItem = this.tableDataLeadGroupsObj[leadGroupName]
        leadGroupItem.data.LEAD_GROUP = leadGroupName
        this.buildMetricsTableLeadGroups(leadGroupItem.data, this.tableDataLeadGroups)

        if (!leadGroupItem.isMapped) {
          let unmappedItem = this.dataKPI2Store.unmappedKPILeadGroupsCORE[leadGroupName]
          let unmappedItem2 = this.dataKPI2Store.unmappedKPILeadGroupsSM[leadGroupName]
          if (unmappedItem) {
            unmappedItem.props.sales.value = this.reportCore.formatCurrency(
              leadGroupItem.data.SALES_SUM
            )
          }
          if (unmappedItem2) {
            unmappedItem2.props.sales.value = this.reportCore.formatCurrency(
              leadGroupItem.data.SALES_SUM
            )
          }
        }
      }

      this.dataTableOwners.tableData = tableDataOwners
      this.dataTableLocations.tableData = tableDataLocations
      this.dataTableGroups.tableData = this.tableDataLeadGroups[this.benchmarkCurrent.name]

      this.dataTableGroupsApptSetRate.tableData = this.tableDataLeadGroups['Appt Set Rate']
      this.dataTableGroupsIssuedRate.tableData = this.tableDataLeadGroups['Issued Rate']
      this.dataTableGroupsDemoRate.tableData = this.tableDataLeadGroups['Demo Rate']
      this.dataTableGroupsClosingRate.tableData = this.tableDataLeadGroups['Closing Rate']



      this.dataKPI2Store.buildDebugInfo()

      this.invalidateBenchmark()
      this.buildScoreCards(this.tableDataLeadGroups)
      this.buildTimelines()
      this.changeTimelineMetric(this.currentLineChartMetric)
      this.buildGauge()

      let yearToUse = this.reportCore.dateRangeEnd.year
      if (!this.dataBenchmarkLeadGroupStore.benchmarkData[yearToUse]) {
        this.toasts.setErrorMessage(`No benchmark data was found for the year ${yearToUse}`)
      }

      //this.buildMetricsTimeline(this.timeLineData)
      // this.changeTimelineMetric(this.currentLineChartMetric)
      // this.buildScoreCards(this.metrics)
    },

    //-------------------------------------------------------------------------------------------------

    buildReportProcess6(
      ownerKPIObj,
      locationName,
      salesPersonName,
      leadGroupName,
      dateBeingProcessed,
      dateRangesValidities
    ) {
      if (this.dataPresetsReportFiltersStore.useDimensionFilters) {
        if (this.dataPresetsReportFiltersStore.getPresetData.useDimensionFilterOwner) {
          let systemOwner = this.dataPresetsReportFiltersStore.getPresetData.dataOwnersNoAliases[ownerKPIObj.name]
          if (systemOwner) {
            let selected = systemOwner.selected
            if (this.dataPresetsReportFiltersStore.getPresetData.isInclusive) {
              if (!selected) {
                return
              }
            }
            if (this.dataPresetsReportFiltersStore.getPresetData.isExclusive) {
              if (selected) {
                return
              }
            }
          } else {
            //if useDimensionFilters is true and no system owner , i have to ignore the owner as filtering will possibly fail
            return
          }
        }

        

        //-------------------------------------

        if (this.dataPresetsReportFiltersStore.getPresetData.useDimensionFilterLeadGroup) {
          let systemLeadGroup = this.dataPresetsReportFiltersStore.getPresetData.dataLeadGroupsNoAliases[leadGroupName]
          if (systemLeadGroup) {
           
            let selected = systemLeadGroup.selected
            if (this.dataPresetsReportFiltersStore.getPresetData.isInclusive) {
              if (!selected) {
                return
              }
            }
            if (this.dataPresetsReportFiltersStore.getPresetData.isExclusive) {
              if (selected) {
                return
              }
            }
          } else {
          
            //if useDimensionFilters is true and no system leadGroup , i have to ignore the leadGroup as filtering will possibly fail
            return
          }
        }
      }

      let locationKPIObj =
        ownerKPIObj.years[dateBeingProcessed.year].months[dateBeingProcessed.month].days[
          dateBeingProcessed.day
        ].locations[locationName]

        let salesPersonKPIObj = locationKPIObj.salesPersons[salesPersonName]

        let leadGroupKPIObj = salesPersonKPIObj.leadGroups[leadGroupName]
        if (!leadGroupKPIObj) {
          return
        }
     //   console.log(leadGroupKPIObj)
      let ownerStorage = null
      let ownerLeadgroupStorage = null
      let locationStorage = null
      let leadGroupStorage = null

      if (dateRangesValidities.isDateRangeValidCurrent) {
        ownerStorage = this.tableDataOwnersObj[ownerKPIObj.name]
        if (!ownerStorage) {
          ownerStorage = this.tableDataOwnersObj[ownerKPIObj.name] = {}
          let systemOwner = this.reportCore.dataOwners[ownerKPIObj.name]

          let isMapped = true
          let ownerType = null
          if (!systemOwner) {
            isMapped = false
          } else {
            if (systemOwner.started_at.year !== this.reportCore.currentDate.year) {
              ownerType = 'legacy'
            }

            if (systemOwner.started_at.year === this.reportCore.currentDate.year) {
              ownerType = 'new'
            }
          }

          ownerStorage.isMapped = isMapped
          ownerStorage.ownerType = ownerType
          ownerStorage.data = {}
          ownerStorage.leadGroups = {}
        }

        ownerLeadgroupStorage = ownerStorage.leadGroups[leadGroupName]
        if (!ownerLeadgroupStorage) {
          ownerLeadgroupStorage = ownerStorage.leadGroups[leadGroupName] = {}
          ownerLeadgroupStorage.data = {}
        }

        locationStorage = this.tableDataLocationsObj[locationName]

        if (!locationStorage) {
          locationStorage = this.tableDataLocationsObj[locationName] = {}
          locationStorage.data = {}
        }

        leadGroupStorage = this.tableDataLeadGroupsObj[leadGroupName]

        if (!leadGroupStorage) {
          leadGroupStorage = this.tableDataLeadGroupsObj[leadGroupName] = {}
          leadGroupStorage.data = {}
        }
      }

      let timelineStorageCurrent = null
      let timelineStoragePrevious = null

      if (dateRangesValidities.isDateRangeValidTimelineCurrent) {
        timelineStorageCurrent =
          this.timeLineData.timelineYearCurrent[dateBeingProcessed.month][leadGroupName]
        if (!timelineStorageCurrent) {
          timelineStorageCurrent = this.timeLineData.timelineYearCurrent[dateBeingProcessed.month][
            leadGroupName
          ] = {}
        }
      }

      if (dateRangesValidities.isDateRangeValidTimelinePrevious) {
        timelineStoragePrevious =
          this.timeLineData.timelineYearPrevious[dateBeingProcessed.month][leadGroupName]
        if (!timelineStoragePrevious) {
          timelineStoragePrevious = this.timeLineData.timelineYearPrevious[
            dateBeingProcessed.month
          ][leadGroupName] = {}
        }
      }

      for (let i = 0; i < this.reportElementProps.length; i++) {
        let prop = this.reportElementProps[i]
        if (dateRangesValidities.isDateRangeValidCurrent) {
          ownerStorage.data[prop] = ownerStorage.data[prop] || 0
          ownerStorage.data[prop] += leadGroupKPIObj.kpiData[prop] || 0

          locationStorage.data[prop] = locationStorage.data[prop] || 0
          locationStorage.data[prop] += leadGroupKPIObj.kpiData[prop] || 0

          leadGroupStorage.data[prop] = leadGroupStorage.data[prop] || 0
          leadGroupStorage.data[prop] += leadGroupKPIObj.kpiData[prop] || 0

          ownerLeadgroupStorage.data[prop] = ownerLeadgroupStorage.data[prop] || 0
          ownerLeadgroupStorage.data[prop] += leadGroupKPIObj.kpiData[prop] || 0

          this.metrics.rangeCurrent[prop] = this.metrics.rangeCurrent[prop] || 0
          this.metrics.rangeCurrent[prop] += leadGroupKPIObj.kpiData[prop] || 0
        }

        if (dateRangesValidities.isDateRangeValidPOP) {
          this.metrics.rangeSub2[prop] = this.metrics.rangeSub2[prop] || 0
          this.metrics.rangeSub2[prop] += leadGroupKPIObj.kpiData[prop] || 0
        }

        if (dateRangesValidities.isDateRangeValidYOY) {
          this.metrics.rangeSub1[prop] = this.metrics.rangeSub1[prop] || 0
          this.metrics.rangeSub1[prop] += leadGroupKPIObj.kpiData[prop] || 0
        }

        if (dateRangesValidities.isDateRangeValidTimelineCurrent) {
          timelineStorageCurrent[prop] = timelineStorageCurrent[prop] || 0
          timelineStorageCurrent[prop] += leadGroupKPIObj.kpiData[prop] || 0
        }

        if (dateRangesValidities.isDateRangeValidTimelinePrevious) {
          timelineStoragePrevious[prop] = timelineStoragePrevious[prop] || 0
          timelineStoragePrevious[prop] += leadGroupKPIObj.kpiData[prop] || 0
        }
      }
    },

    //--------------------------------------------------------------------------------

    buildReportProcess5b(
      ownerKPIObj,
      locationName,
      salesPersonName,
      dateBeingProcessed,
      dateRangesValidities
    ) {
      let locationKPIObj = ownerKPIObj.years[dateBeingProcessed.year]
      if (locationKPIObj) {
        locationKPIObj = locationKPIObj.months[dateBeingProcessed.month]
      } else {
        return
      }
      if (locationKPIObj) {
        locationKPIObj = locationKPIObj.days[dateBeingProcessed.day]
      } else {
        return
      }
      if (locationKPIObj) {
        locationKPIObj = locationKPIObj.locations[locationName]
      } else {
        return
      }

      if (!locationKPIObj) {
        return
      }

      let salesPersonKPIObj = locationKPIObj.salesPersons[salesPersonName]
      if (!salesPersonKPIObj) {
        return
      }

      if (this.leadGroupFilter) {
        //console.log(this.leadGroupFilter)
        this.buildReportProcess6(
          ownerKPIObj,
          locationName,
          salesPersonName,
          this.leadGroupFilter,
          dateBeingProcessed,
          dateRangesValidities
        )
      } else {
        for (let leadGroupName in salesPersonKPIObj.leadGroups) {

          //if lead group name is not in unique stor on benchmarks continue
          if (!this.dataBenchmarkLeadGroupStore.leadGroupsUnique[leadGroupName]) {
            continue
          }
          this.buildReportProcess6(
            ownerKPIObj,
            locationName,
            salesPersonName,
            leadGroupName,
            dateBeingProcessed,
            dateRangesValidities
          )
        }
      }
    },

    //-------------------------------------------------------------------------------------------------

    buildReportProcess5(ownerKPIObj, locationName, dateBeingProcessed, dateRangesValidities) {
      let locationKPIObj = ownerKPIObj.years[dateBeingProcessed.year]
      if (locationKPIObj) {
        locationKPIObj = locationKPIObj.months[dateBeingProcessed.month]
      } else {
        return
      }
      if (locationKPIObj) {
        locationKPIObj = locationKPIObj.days[dateBeingProcessed.day]
      } else {
        return
      }
      if (locationKPIObj) {
        locationKPIObj = locationKPIObj.locations[locationName]
      } else {
        return
      }

      if (!locationKPIObj) {
        return
      }

      for (let salesPersonName in locationKPIObj.salesPersons) {
        this.buildReportProcess5b(
          ownerKPIObj,
          locationName,
          salesPersonName,
          dateBeingProcessed,
          dateRangesValidities
        )
      }
    },

    buildReportProcess4(ownerName, dataSource, dateBeingProcessed, dateRangesValidities) {
      let ownerKPIObj = dataSource[ownerName]

      if (this.locationFilter) {
        if (ownerKPIObj) {
          this.buildReportProcess5(
            ownerKPIObj,
            this.locationFilter,
            dateBeingProcessed,
            dateRangesValidities
          )
        }
      } else {
        if (ownerKPIObj) {
          let locationRef = ownerKPIObj.years[dateBeingProcessed.year]
          if (locationRef) {
            locationRef = locationRef.months[dateBeingProcessed.month]
          } else {
            return
          }
          if (locationRef) {
            locationRef = locationRef.days[dateBeingProcessed.day]
          } else {
            return
          }
          if (locationRef) {
            locationRef = locationRef.locations
          } else {
            return
          }

          for (let locationName in locationRef) {
            this.buildReportProcess5(
              ownerKPIObj,
              locationName,
              dateBeingProcessed,
              dateRangesValidities
            )
          }
        }
      }
    },

    //-------------------------------------------------------------------------------------------------

    buildReportProcess3(dateBeingProcessed) {
      let dateRangesValidities = {}
      dateRangesValidities.isDateRangeValidCurrent = true
      dateRangesValidities.isDateRangeValidPOP = true
      dateRangesValidities.isDateRangeValidYOY = true
      dateRangesValidities.isDateRangeValidTimelineCurrent = true
      dateRangesValidities.isDateRangeValidTimelinePrevious = true

      if (dateBeingProcessed > this.reportCore.dateRangeEnd) {
        dateRangesValidities.isDateRangeValidCurrent = false
      }

      if (dateBeingProcessed < this.reportCore.dateRangeStart) {
        dateRangesValidities.isDateRangeValidCurrent = false
      }

      if (dateBeingProcessed > this.reportCore.periodBeforeEnd) {
        dateRangesValidities.isDateRangeValidPOP = false
      }

      if (dateBeingProcessed < this.reportCore.periodBeforeStart) {
        dateRangesValidities.isDateRangeValidPOP = false
      }

      if (dateBeingProcessed > this.reportCore.oneYearBeforeEnd) {
        dateRangesValidities.isDateRangeValidYOY = false
      }

      if (dateBeingProcessed < this.reportCore.oneYearBeforeStart) {
        dateRangesValidities.isDateRangeValidYOY = false
      }
      if (dateBeingProcessed.year === this.reportCore.currentDate.year) {
        dateRangesValidities.isDateRangeValidTimelinePrevious = false
      }
      if (dateBeingProcessed.year === this.reportCore.currentDate.year - 1) {
        dateRangesValidities.isDateRangeValidTimelineCurrent = false
      }

      //------------------------------------------------------------------

      let dataSource = null

      let blendDate = null
      let dataSourceKey = null

      if (this.ownerFilter) {
        if (this.reportCore.useDataSourceOverride) {
          blendDate = this.reportCore.dataBlendDate
          dataSourceKey = this.reportCore.dataSourceCurrent
        } else {
          //get user , get preferred source info , if it exists , otherwise default SM
          let systemOwner = this.reportCore.dataOwners[this.ownerFilter]
          if (systemOwner) {
            blendDate = systemOwner.UserDefaultDataSource.blend_date_sd
            dataSourceKey =
              systemOwner.UserDefaultDataSource.value || this.reportCore.DATA_SOURCE_BLEND
          } else {
            //unmapped owners get system defaults , which are same as overrides
            blendDate = this.reportCore.dataBlendDate
            dataSourceKey = this.reportCore.dataSourceCurrent
          }
        }

        if (dataSourceKey === this.reportCore.DATA_SOURCE_CORE) {
          dataSource = this.dataKPI2Store.processedDataCORE
        }
        if (dataSourceKey === this.reportCore.DATA_SOURCE_SM) {
          dataSource = this.dataKPI2Store.processedDataSM
        }

        if (dataSourceKey === this.reportCore.DATA_SOURCE_BLEND) {
          dataSource = this.dataKPI2Store.processedDataCORE

          if (dateBeingProcessed >= blendDate) {
            dataSource = this.dataKPI2Store.processedDataSM
          }
        }

        this.buildReportProcess4(
          this.ownerFilter,
          dataSource,
          dateBeingProcessed,
          dateRangesValidities
        )
      } else {
        for (let ownerName in this.reportCore.dataOwnersNoAliases) {
          let systemOwner = this.reportCore.dataOwnersNoAliases[ownerName]
          if (this.reportCore.useDataSourceOverride) {
            blendDate = this.reportCore.dataBlendDate
            dataSourceKey = this.reportCore.dataSourceCurrent
          } else {
            blendDate = systemOwner.UserDefaultDataSource.blend_date_sd
            dataSourceKey =
              systemOwner.UserDefaultDataSource.value || this.reportCore.DATA_SOURCE_BLEND
          }
          if (dataSourceKey === this.reportCore.DATA_SOURCE_CORE) {
            dataSource = this.dataKPI2Store.processedDataCORE
          }
          if (dataSourceKey === this.reportCore.DATA_SOURCE_SM) {
            dataSource = this.dataKPI2Store.processedDataSM
          }

          if (dataSourceKey === this.reportCore.DATA_SOURCE_BLEND) {
            dataSource = this.dataKPI2Store.processedDataCORE

            if (dateBeingProcessed >= blendDate) {
              dataSource = this.dataKPI2Store.processedDataSM
            }
          }

          if (dataSource[ownerName]) {
            this.buildReportProcess4(
              ownerName,
              dataSource,
              dateBeingProcessed,
              dateRangesValidities
            )
          } else {
            // console.log(`ownerName ${ownerName} not found in kpi data source ${dataSourceKey}`)
          }
        }

        //once system owners are looped , need to loop kpi sets and process unmapped owners
        //as the above loop will miss them

        if (this.reportCore.useDataSourceOverride) {
          blendDate = this.reportCore.dataBlendDate
          dataSourceKey = this.reportCore.dataSourceCurrent
        } else {
          blendDate = this.reportCore.dataBlendDateUnmapped
          dataSourceKey = this.reportCore.dataSourceUnmapped
        }

        if (dataSourceKey === this.reportCore.DATA_SOURCE_CORE) {
          dataSource = this.dataKPI2Store.processedDataCORE
        }
        if (dataSourceKey === this.reportCore.DATA_SOURCE_SM) {
          dataSource = this.dataKPI2Store.processedDataSM
        }

        if (dataSourceKey === this.reportCore.DATA_SOURCE_BLEND) {
          dataSource = this.dataKPI2Store.processedDataCORE

          if (dateBeingProcessed >= blendDate) {
            dataSource = this.dataKPI2Store.processedDataSM
          }
        }
        for (let ownerName in dataSource) {
          let kpiOwner = dataSource[ownerName]
          if (!kpiOwner.isMapped) {
            this.buildReportProcess4(
              ownerName,
              dataSource,
              dateBeingProcessed,
              dateRangesValidities
            )
          }
        }
      }
    },

    //-------------------------------------------------------------------------------------------------

    buildReportProcess2() {
      //year/month values to loop
      //you always need all months from the curent year and previous year because of the timeline on the report
      //all POP and YOY ranges will fall within these ranges

      let yearMonthDayValues = this.reportCore.getYearMonthDayValuesForKPIRange()

      for (let i = 0; i < yearMonthDayValues.length; i++) {
        this.buildReportProcess3(yearMonthDayValues[i])
      }
    },

    //-------------------------------------------------------------------------------------------------

    buildReportProcess1() {
      const auth = useAuth()
      //not sure if this is even needed here because data is filtered on the backend based on this permission.. check..?
      if (!auth.hasPermission('read_benchmark_lead_group_all_owners')) {
        this.ownerFilter = auth.user.name
        if (auth.hasRole('staff')) {
          this.ownerFilter = this.reportCore.dataStaffs[auth.user.name]?.parentName
        }
      }

      this.reportCore.calculateGrowthDateRanges()

      this.tableDataOwnersObj = markRaw({})
      this.tableDataLocationsObj = markRaw({})
      this.tableDataSalesPersonsObj = markRaw({})
      this.tableDataLeadGroupsObj = markRaw({})

      this.timeLineData = markRaw({})
      this.timeLineData.timelineYearCurrent = {}
      this.timeLineData.timelineYearPrevious = {}
      for (let i = 0; i < 12; i++) {
        this.timeLineData.timelineYearCurrent[i] = {}
        this.timeLineData.timelineYearPrevious[i] = {}
      }

      //-------------------------------------------------------------------

      this.metrics = markRaw({})
      this.metrics.rangeCurrent = {}
      this.metrics.rangeSub1 = {}
      this.metrics.rangeSub2 = {}

      this.dataKPI2Store.clearDebugInfo()

      this.buildReportProcess2()

      this.buildReportProcess7()

      

      this.dataReportKPIVerbose.invalidate({queryKey:"KPIVerboseQuery1"},{ownerFilter:this.ownerFilter});

      this.reportCore.setLoadHeavy(false)
    },

    buildTimelines() {
      //this.timelineMetrics
      //this.timeLineData.timelineYearCurrent

      let yearToUse = this.reportCore.dateRangeEnd.year
      let benchmarkData = this.dataBenchmarkLeadGroupStore.benchmarkDataByLeadGroup
      let props = [
        [
          ['Appt Set Rate', '%'],
          ['Appointments', 'INT', 'APPOINTMENTS_COUNT'],
          ['Leads', 'INT', 'LEADS_COUNT'],
          ['Appt Set Rate', '%', ['APPOINTMENTS_COUNT', 'LEADS_COUNT']],
          'Appointments Expected'
        ],
        [
          ['Issued Rate', '%'],
          ['Appointments Issued', 'INT', 'ISSUED_APPOINTMENTS_COUNT'],
          ['Appointments', 'INT', 'APPOINTMENTS_COUNT'],
          ['Issued Rate', '%', ['ISSUED_APPOINTMENTS_COUNT', 'APPOINTMENTS_COUNT']],
          'Appointments Issued Expected'
        ],
        [
          ['Closing Rate', '%'],
          ['Sales', 'INT', 'SALES_COUNT'],
          ['Quotes', 'INT', 'QUOTES_COUNT'],
          ['Closing Rate', '%', ['SALES_COUNT', 'QUOTES_COUNT']],
          'Sales Expected'
        ],
        [
          ['Demo Rate', '%'],
          ['Quotes', 'INT', 'QUOTES_COUNT'],
          ['Appointments Issued', 'INT', 'ISSUED_APPOINTMENTS_COUNT'],
          ['Demo Rate', '%', ['QUOTES_COUNT', 'ISSUED_APPOINTMENTS_COUNT']],
          'Quotes Expected'
        ]
      ]

      this.timelineMetrics =markRaw({})
      props.forEach((element) => {
        let benchmarkKey = element[0][0]
        this.timelineMetrics[benchmarkKey] = []
        this.timelineMetrics[benchmarkKey][0] = []
        this.timelineMetrics[benchmarkKey][1] = []
      })

      for (let monthProp in this.timeLineData.timelineYearCurrent) {
        let month = this.timeLineData.timelineYearCurrent[monthProp]
        let leadGroupResults = {}
        for (let leadGroupKey in month) {
          let leadGroupData = month[leadGroupKey]
          let leadGroupResult = leadGroupResults[leadGroupKey]
          if (!leadGroupResult) {
            leadGroupResult = leadGroupResults[leadGroupKey] = {}
          }

          props.forEach((element) => {
            let leadGroupBenchmarkResult = leadGroupResult[element[0][0]]
            if (!leadGroupBenchmarkResult) {
              leadGroupBenchmarkResult = leadGroupResult[element[0][0]] = {}
            }

            let benchmarkItem = null
            leadGroupBenchmarkResult.benchmark = null
            if (benchmarkData[yearToUse]) {
              if (benchmarkData[yearToUse][leadGroupKey]) {
                if (benchmarkData[yearToUse][leadGroupKey][element[0][0]]) {
                  benchmarkItem = benchmarkData[yearToUse][leadGroupKey][element[0][0]]
                  if (benchmarkItem && benchmarkItem?.active) {
                    leadGroupBenchmarkResult.benchmark = benchmarkItem.value / 100
                  }
                }
              }
            }
            if (leadGroupBenchmarkResult.benchmark) {
              leadGroupBenchmarkResult.expected =
                leadGroupData[element[2][2]] * leadGroupBenchmarkResult.benchmark || 0
              leadGroupBenchmarkResult.metric1 = leadGroupData[element[1][2]] || 0
              leadGroupBenchmarkResult.metric2 = leadGroupData[element[2][2]] || 0
            }
          })
        }

        let benchmarkCombined = {}

        for (let leadGroupKey in leadGroupResults) {
          let leadGroupItem = leadGroupResults[leadGroupKey]
          for (let benchmarkKey in leadGroupItem) {
            let benchMarkItem = leadGroupItem[benchmarkKey]
            let benchmarkCombinedItem = benchmarkCombined[benchmarkKey]
            if (!benchmarkCombinedItem) {
              benchmarkCombinedItem = benchmarkCombined[benchmarkKey] = {}
              benchmarkCombinedItem.expected = 0
              benchmarkCombinedItem.metric1 = 0
              benchmarkCombinedItem.metric2 = 0
            }
            benchmarkCombinedItem.expected += benchMarkItem.expected || 0
            benchmarkCombinedItem.metric1 += benchMarkItem.metric1 || 0
            benchmarkCombinedItem.metric2 += benchMarkItem.metric2 || 0
          }
        }

        for (let benchmarkKey in benchmarkCombined) {
          let benchMarkItem = benchmarkCombined[benchmarkKey]
          if (benchMarkItem.metric1 === 0 || benchMarkItem.metric2 === 0) {
            benchMarkItem.benchmark = 0
            benchMarkItem.score = 0
            benchMarkItem.scoreWeighted = 0
          } else {
            benchMarkItem.value = (benchMarkItem.metric1 / benchMarkItem.metric2) * 100
            benchMarkItem.benchmark = (benchMarkItem.expected / benchMarkItem.metric2) * 100
            benchMarkItem.score = benchMarkItem.metric1 / benchMarkItem.expected || 0

            let timeLineDataOb = {}
            timeLineDataOb.value = benchMarkItem.score * 100 || 0
            timeLineDataOb.formattedValue = this.reportCore.formatPercentWithoutSymbol(benchMarkItem.score)


            this.timelineMetrics[benchmarkKey][0][parseInt(monthProp)] = timeLineDataOb
          }
        }
      }

      let scoreWeightedYearStorage = []

      //at this point all benchmarks are in the current year timeline , now loop and created the weight score

     
      for (let benchmarkKey in this.timelineMetrics) {
        for (let i = 0; i < 12; i++) {

          let timeLineDataOb = this.timelineMetrics[benchmarkKey][0][i]

          if (!timeLineDataOb) {
            continue
          }

          let scoreWeightedYearStorageItem = scoreWeightedYearStorage[i]
          if (!scoreWeightedYearStorageItem) {
            scoreWeightedYearStorageItem = scoreWeightedYearStorage[i] = {}
          }

          scoreWeightedYearStorageItem.value = scoreWeightedYearStorageItem.value || 0;

          scoreWeightedYearStorageItem.value += (timeLineDataOb.value *
            this.dataBenchmarkLeadGroupStore.benchmarkData[yearToUse].benchmarks[benchmarkKey]
              .weight)

          scoreWeightedYearStorageItem.formattedValue = Math.round(scoreWeightedYearStorageItem.value)

        }
      }

      let weightedScoreKey = "Score Weighted"
      let weightedScoreTimelines = this.timelineMetrics[weightedScoreKey] = []
      weightedScoreTimelines[0] = []
      weightedScoreTimelines[1] = []

      for (let i = 0; i < 12; i++) {
        if (scoreWeightedYearStorage[i]) {
          weightedScoreTimelines[0][i] = scoreWeightedYearStorage[i]
        }

      }

      //------------------------------------

      //reset this array
      scoreWeightedYearStorage = []

      let yearToUsePrevious = yearToUse - 1;

      for (let monthProp in this.timeLineData.timelineYearPrevious) {
        let month = this.timeLineData.timelineYearPrevious[monthProp]
        let leadGroupResults = {}
        for (let leadGroupKey in month) {
          let leadGroupData = month[leadGroupKey]
          let leadGroupResult = leadGroupResults[leadGroupKey]
          if (!leadGroupResult) {
            leadGroupResult = leadGroupResults[leadGroupKey] = {}
          }

          props.forEach((element) => {
            let leadGroupBenchmarkResult = leadGroupResult[element[0][0]]
            if (!leadGroupBenchmarkResult) {
              leadGroupBenchmarkResult = leadGroupResult[element[0][0]] = {}
            }

            let benchmarkItem = null
            leadGroupBenchmarkResult.benchmark = null
            if (benchmarkData[yearToUsePrevious]) {
              if (benchmarkData[yearToUsePrevious][leadGroupKey]) {
                if (benchmarkData[yearToUsePrevious][leadGroupKey][element[0][0]]) {
                  benchmarkItem = benchmarkData[yearToUsePrevious][leadGroupKey][element[0][0]]
                  if (benchmarkItem && benchmarkItem?.active) {
                    leadGroupBenchmarkResult.benchmark = benchmarkItem.value / 100
                  }
                }
              }
            }
            if (leadGroupBenchmarkResult.benchmark) {
              leadGroupBenchmarkResult.expected =
                leadGroupData[element[2][2]] * leadGroupBenchmarkResult.benchmark || 0
              leadGroupBenchmarkResult.metric1 = leadGroupData[element[1][2]] || 0
              leadGroupBenchmarkResult.metric2 = leadGroupData[element[2][2]] || 0
            }
          })
        }

        let benchmarkCombined = {}

        for (let leadGroupKey in leadGroupResults) {
          let leadGroupItem = leadGroupResults[leadGroupKey]
          for (let benchmarkKey in leadGroupItem) {
            let benchMarkItem = leadGroupItem[benchmarkKey]
            let benchmarkCombinedItem = benchmarkCombined[benchmarkKey]
            if (!benchmarkCombinedItem) {
              benchmarkCombinedItem = benchmarkCombined[benchmarkKey] = {}
              benchmarkCombinedItem.expected = 0
              benchmarkCombinedItem.metric1 = 0
              benchmarkCombinedItem.metric2 = 0
            }
            benchmarkCombinedItem.expected += benchMarkItem.expected || 0
            benchmarkCombinedItem.metric1 += benchMarkItem.metric1 || 0
            benchmarkCombinedItem.metric2 += benchMarkItem.metric2 || 0
          }
        }

        for (let benchmarkKey in benchmarkCombined) {
          let benchMarkItem = benchmarkCombined[benchmarkKey]
          if (benchMarkItem.metric1 === 0 || benchMarkItem.metric2 === 0) {
            benchMarkItem.benchmark = 0
            benchMarkItem.score = 0
            benchMarkItem.scoreWeighted = 0
          } else {
            benchMarkItem.value = (benchMarkItem.metric1 / benchMarkItem.metric2) * 100
            benchMarkItem.benchmark = (benchMarkItem.expected / benchMarkItem.metric2) * 100
            benchMarkItem.score = benchMarkItem.metric1 / benchMarkItem.expected || 0

            let timeLineDataOb = {}
            timeLineDataOb.value = benchMarkItem.score * 100 || 0
            timeLineDataOb.formattedValue = this.reportCore.formatPercentWithoutSymbol(benchMarkItem.score)

            this.timelineMetrics[benchmarkKey][1][parseInt(monthProp)] = timeLineDataOb
          }
        }
      }



      //at this point all benchmarks are in the current year timeline , now loop and created the weight score


      for (let benchmarkKey in this.timelineMetrics) {
        for (let i = 0; i < 12; i++) {

          let timeLineDataOb = this.timelineMetrics[benchmarkKey][1][i]

          if (!timeLineDataOb) {
            continue
          }

          let scoreWeightedYearStorageItem = scoreWeightedYearStorage[i]
          if (!scoreWeightedYearStorageItem) {
            scoreWeightedYearStorageItem = scoreWeightedYearStorage[i] = {}
          }

          scoreWeightedYearStorageItem.value = scoreWeightedYearStorageItem.value || 0;

          scoreWeightedYearStorageItem.value += (timeLineDataOb.value *
            this.dataBenchmarkLeadGroupStore.benchmarkData[yearToUse].benchmarks[benchmarkKey]
              .weight)

          scoreWeightedYearStorageItem.formattedValue = Math.round(scoreWeightedYearStorageItem.value);

        }
      }

     
      for (let i = 0; i < 12; i++) {
        if (scoreWeightedYearStorage[i]) {
          weightedScoreTimelines[1][i] = scoreWeightedYearStorage[i]
        }

      }

    },

    buildMetricsTableOwners(metrics, targetData) {


      let yearToUse = this.reportCore.dateRangeEnd.year

      let benchmarkData = this.dataBenchmarkLeadGroupStore.benchmarkDataByLeadGroup

      let props = [
        [
          ['Appt Set Rate', '%'],
          ['Appointments', 'INT', 'APPOINTMENTS_COUNT'],
          ['Leads', 'INT', 'LEADS_COUNT'],
          ['Appt Set Rate', '%', ['APPOINTMENTS_COUNT', 'LEADS_COUNT']],
          'Appointments Expected'
        ],
        [
          ['Issued Rate', '%'],
          ['Appointments Issued', 'INT', 'ISSUED_APPOINTMENTS_COUNT'],
          ['Appointments', 'INT', 'APPOINTMENTS_COUNT'],
          ['Issued Rate', '%', ['ISSUED_APPOINTMENTS_COUNT', 'APPOINTMENTS_COUNT']],
          'Appointments Issued Expected'
        ],
        [
          ['Closing Rate', '%'],
          ['Sales', 'INT', 'SALES_COUNT'],
          ['Quotes', 'INT', 'QUOTES_COUNT'],
          ['Closing Rate', '%', ['SALES_COUNT', 'QUOTES_COUNT']],
          'Sales Expected'
        ],
        [
          ['Demo Rate', '%'],
          ['Quotes', 'INT', 'QUOTES_COUNT'],
          ['Appointments Issued', 'INT', 'ISSUED_APPOINTMENTS_COUNT'],
          ['Demo Rate', '%', ['QUOTES_COUNT', 'ISSUED_APPOINTMENTS_COUNT']],
          'Quotes Expected'
        ]
      ]

      let ownerLeadGroups = metrics.leadGroups
      let ownerLeadGroupResults = {}
      for (let leadGroupKey in ownerLeadGroups) {
        let ownerLeadGroupData = ownerLeadGroups[leadGroupKey].data
        let leadGroupResult = ownerLeadGroupResults[leadGroupKey]
        if (!leadGroupResult) {
          leadGroupResult = ownerLeadGroupResults[leadGroupKey] = {}
        }

        props.forEach((element) => {
          let leadGroupBenchmarkResult = leadGroupResult[element[0][0]]
          if (!leadGroupBenchmarkResult) {
            leadGroupBenchmarkResult = leadGroupResult[element[0][0]] = {}
          }

          let benchmarkItem = null
          leadGroupBenchmarkResult.benchmark = null
          if (benchmarkData[yearToUse]) {
            if (benchmarkData[yearToUse][leadGroupKey]) {
              if (benchmarkData[yearToUse][leadGroupKey][element[0][0]]) {
                benchmarkItem = benchmarkData[yearToUse][leadGroupKey][element[0][0]]
                if (benchmarkItem && benchmarkItem?.active) {
                  leadGroupBenchmarkResult.benchmark = benchmarkItem.value / 100
                }
              }
            }
          }
          if (leadGroupBenchmarkResult.benchmark) {
            leadGroupBenchmarkResult.expected =
              ownerLeadGroupData[element[2][2]] * leadGroupBenchmarkResult.benchmark || 0
            leadGroupBenchmarkResult.metric1 = ownerLeadGroupData[element[1][2]] || 0
            leadGroupBenchmarkResult.metric2 = ownerLeadGroupData[element[2][2]] || 0
          }
        })
      }

      let benchmarkCombined = {}

      for (let leadGroupKey in ownerLeadGroupResults) {
        let leadGroupItem = ownerLeadGroupResults[leadGroupKey]
        for (let benchmarkKey in leadGroupItem) {
          let benchMarkItem = leadGroupItem[benchmarkKey]
          let benchmarkCombinedItem = benchmarkCombined[benchmarkKey]
          if (!benchmarkCombinedItem) {
            benchmarkCombinedItem = benchmarkCombined[benchmarkKey] = {}
            benchmarkCombinedItem.expected = 0
            benchmarkCombinedItem.metric1 = 0
            benchmarkCombinedItem.metric2 = 0
          }

          benchmarkCombinedItem.expected += benchMarkItem.expected || 0
          benchmarkCombinedItem.metric1 += benchMarkItem.metric1 || 0
          benchmarkCombinedItem.metric2 += benchMarkItem.metric2 || 0
        }
      }



      for (let benchmarkKey in benchmarkCombined) {
        let benchMarkItem = benchmarkCombined[benchmarkKey]
        if (benchMarkItem.metric1 === 0 || benchMarkItem.metric2 === 0) {
          benchMarkItem.benchmark = 0
          benchMarkItem.score = 0
          benchMarkItem.scoreWeighted = 0
        } else {
          // benchMarkItem.value = (benchMarkItem.metric1 / benchMarkItem.metric2) * 100
          // benchMarkItem.benchmark = (benchMarkItem.expected / benchMarkItem.metric2) * 100
          benchMarkItem.score = benchMarkItem.metric1 / benchMarkItem.expected || 0
          benchMarkItem.scoreWeighted = 0
          if (this.dataBenchmarkLeadGroupStore.benchmarkData[yearToUse]) {
            benchMarkItem.scoreWeighted =
              benchMarkItem.score *
              100 *
              this.dataBenchmarkLeadGroupStore.benchmarkData[yearToUse].benchmarks[benchmarkKey]
                .weight
          }
        }
      }

      let scoreWeighted = 0

      for (let benchmarkKey in benchmarkCombined) {
        let benchMarkItem = benchmarkCombined[benchmarkKey]
        scoreWeighted += benchMarkItem.scoreWeighted
      }

      let ob = {}
      useBuildMetricDataTables2(ob, 'Owner', 'STRING', metrics.data, 'OWNER')
      ob['Score Weighted'] = scoreWeighted

      for (let benchmarkKey in benchmarkCombined) {
        let benchMarkItem = benchmarkCombined[benchmarkKey]        
        ob[`${benchmarkKey} Score`] = benchMarkItem.score

      }



      targetData.push(ob)
    },

    buildMetricsTableLeadGroups(metrics, targetData) {
      let yearToUse = this.reportCore.dateRangeEnd.year

      let benchmarkData = this.dataBenchmarkLeadGroupStore.benchmarkDataByLeadGroup
     
      let props = [
        [
          ['Appt Set Rate', '%'],
          ['Appointments', 'INT', 'APPOINTMENTS_COUNT'],
          ['Leads', 'INT', 'LEADS_COUNT'],
          ['Appt Set Rate', '%', ['APPOINTMENTS_COUNT', 'LEADS_COUNT']],
          'Appointments Expected'
        ],
        [
          ['Issued Rate', '%'],
          ['Appointments Issued', 'INT', 'ISSUED_APPOINTMENTS_COUNT'],
          ['Appointments', 'INT', 'APPOINTMENTS_COUNT'],
          ['Issued Rate', '%', ['ISSUED_APPOINTMENTS_COUNT', 'APPOINTMENTS_COUNT']],
          'Appointments Issued Expected'
        ],
        [
          ['Closing Rate', '%'],
          ['Sales', 'INT', 'SALES_COUNT'],
          ['Quotes', 'INT', 'QUOTES_COUNT'],
          ['Closing Rate', '%', ['SALES_COUNT', 'QUOTES_COUNT']],
          'Sales Expected'
        ],
        [
          ['Demo Rate', '%'],
          ['Quotes', 'INT', 'QUOTES_COUNT'],
          ['Appointments Issued', 'INT', 'ISSUED_APPOINTMENTS_COUNT'],
          ['Demo Rate', '%', ['QUOTES_COUNT', 'ISSUED_APPOINTMENTS_COUNT']],
          'Quotes Expected'
        ]
      ]

      props.forEach((element) => {
        let ob = {}
        let benchmarkTemp = element[0][0]
        useBuildMetricDataTables2(ob, 'Lead Group', 'STRING', metrics, 'LEAD_GROUP')
        useBuildMetricDataTables2(ob, element[1][0], element[1][1], metrics, element[1][2])
        useBuildMetricDataTables2(ob, element[2][0], element[2][1], metrics, element[2][2])
        useBuildMetricDataTables2(ob, element[3][0], element[3][1], metrics, element[3][2])
        ob.metric1 = metrics[element[1][2]] || 0
        ob.metric2 = metrics[element[2][2]] || 0

        let benchmarkItem = null
        if (benchmarkData[yearToUse]) {
          if (benchmarkData[yearToUse][metrics.LEAD_GROUP]) {
            if (benchmarkData[yearToUse][metrics.LEAD_GROUP][element[0][0]]) {
              benchmarkItem = benchmarkData[yearToUse][metrics.LEAD_GROUP][element[0][0]]
              ob.Benchmark = benchmarkItem.value / 100
            }
          }
        }

        if (benchmarkItem && benchmarkItem?.active) {
          if (benchmarkItem.value) {
            ob[element[4]] = ob.metric2 * ob.Benchmark || 0
            ob.expected = ob[element[4]] || 0
            ob['Score'] = ob.metric1 / ob[element[4]] || 0
            ob.Grade = ob['Score'] * 100 || 0

            targetData[benchmarkTemp].push(ob)
          }
        }
      })
    },

    buildGauge() {

      let scoreWeighted = 0
      this.gaugeConfig.needleValue = 0
      this.gaugeConfig.maxValue = 0;
      this.gaugeConfig.renderKey++
      let yearToUse = this.reportCore.dateRangeEnd.year

      if (!this.dataBenchmarkLeadGroupStore.benchmarkData[yearToUse]) {
        return
      }

      let weightTotal = 0;


      for (let prop in this.scoreCards) {
        let benchMarkItem = this.scoreCards[prop]

        let weight = this.dataBenchmarkLeadGroupStore.benchmarkData[yearToUse].benchmarks[benchMarkItem.benchmarkName].weight

        scoreWeighted += benchMarkItem.value * 100 * weight;
        weightTotal += weight
      }

      this.gaugeConfig.needleValue = Math.round(scoreWeighted)
      this.gaugeConfig.maxValue = Math.round(weightTotal * 100)

      this.gaugeConfig.segments.forEach(element => {
        element.value = this.gaugeConfig.maxValue * element.percentage
      })

    },

    //---------------------------------------------------------------------------------------------

    buildScoreCards(targetData) {

      //loop targetData to get combined totals and scores
      let benchmarks = {}
      for (let benchmarkKey in targetData) {
        let benchmark = benchmarks[benchmarkKey]
        if (!benchmark) {
          benchmark = benchmarks[benchmarkKey] = {}
          benchmark.metric1 = 0
          benchmark.metric2 = 0
          benchmark.expected = 0
        }
        let benchmarkData = targetData[benchmarkKey]
        benchmarkData.forEach((element) => {
          benchmark.metric1 += element.metric1 || 0
          benchmark.metric2 += element.metric2 || 0
          benchmark.expected += element.expected || 0
        })
      }

      for (let benchmarkKey in benchmarks) {
        let benchmark = benchmarks[benchmarkKey]
        benchmark.score = benchmark.metric1 / benchmark.expected
        benchmark.grade = benchmark.score * 100

        let scoreCardObject = {}
        scoreCardObject.title = `${benchmarkKey} Score`
        scoreCardObject.benchmarkName = benchmarkKey

        scoreCardObject.value = benchmark.score || 0
        scoreCardObject.valueFormat = 'INTX100'

        scoreCardObject.metricSub1 = benchmark.grade || 0
        scoreCardObject.valueMetric1Format = 'GRADE'
        scoreCardObject.metricSub1Valid = true
        scoreCardObject.metricSub2Valid = false

        scoreCardObject.valueSub1 = benchmark.metric1 || 0
        scoreCardObject.valueSub2 = benchmark.expected || 0
        scoreCardObject.valueSub1Format = 'INT'
        scoreCardObject.valueSub2Format = 'INT'
        scoreCardObject.valueSub1Valid = true
        scoreCardObject.valueSub2Valid = true

        scoreCardObject.scorecardLabels = {
          subValue1: 'metric 1',
          subValue2: 'expected',
          subMetric1: 'GRADE',
          subMetric2: ''
        }
        let key = benchmarkKey.split(' ').join('')
        this.scoreCards[key] = scoreCardObject
      }
    },

    //---------------------------------------------------------------------------------------------

    doubleRaf(callback, ...args) {
      requestAnimationFrame(() => {
        requestAnimationFrame(() => {
          try {
            callback(...args)
          } catch (error) {
            useErrorHandler().processScriptError(error)
          }
        })
      })
    },

    async buildReport() {
      //check if kpi data and benchmark data is loaded
      let validateBuildDataKPIIsLoaded = this.dataKPI2Store.validateDataIsLoaded()

      let validateBuildDataBenchmarkLeadGroupIsLoaded =
        this.dataBenchmarkLeadGroupStore.validateDataIsLoaded()

      if (validateBuildDataKPIIsLoaded && validateBuildDataBenchmarkLeadGroupIsLoaded) {
        this.reportCore.setLoadHeavy(true, 'KPI Benchmark Report', 'process')
        this.doubleRaf(() => this.buildReportProcess1())
      } else {
        if (!validateBuildDataKPIIsLoaded) {
          await this.dataKPI2Store.loadDataAll()
        }
        if (!validateBuildDataBenchmarkLeadGroupIsLoaded) {
          await this.dataBenchmarkLeadGroupStore.loadData()
        }
        this.reportCore.setLoadHeavy(true, 'KPI Benchmark Report', 'process')
        this.doubleRaf(() => this.buildReportProcess1())
      }
    }
  }
})
