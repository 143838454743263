<template>
  <div class="w-100 mx-auto printable" style="max-width: 99%" :class="applicationStore.getPadding">
    <div class="content-bg-11" :class="{
      'pa-4': !applicationStore.grid.tabletAndUnder,
      'elevation-2': !applicationStore.grid.tabletAndUnder,
      'rounded-2': !applicationStore.grid.tabletAndUnder
    }">
      <div class="pa-4 d-flex flex-column" style="background-color: var(--content-bg-13)">
        <div class="d-flex align-items-center" style="cursor: pointer"
          @click="reportRoyaltyStore.section1Expanded = !reportRoyaltyStore.section1Expanded">
          <div class="flex-ui-1 text-type-10">
          
            <div class="icon-color-1" style="font-weight: 700; font-size: 20px">Royalty Report</div>
          </div>

          <span class="ms-auto mdi icon icon-color-1" :class="{
            ' mdi-plus-circle': !reportRoyaltyStore.section1Expanded,
            ' mdi-minus-circle': reportRoyaltyStore.section1Expanded
          }">
          </span>
        </div>

        <div v-if="reportRoyaltyStore.section1Expanded">
          <div class="d-flex align-items-center flex-wrap text-type-10 p-0">
            <div class="icon-color-1">Current Filters Applied:</div>

            <div class="flex-ui-1 ml-2">
              <div class="fs-7 mr-2 text-type-10 icon-color-1">owner:</div>
              <div style="color: #ffca00">
                {{ reportRoyaltyStore.ownerFilter || 'All Owners' }} ,
              </div>
            </div>

          


            <div class="d-flex align-items-center flex-wrap text-type-10 p-0">
              <div class="flex-ui-1 ml-2">
                <div class="fs-7 mr-2 text-type-10 icon-color-1">range  {{ rangeLabel}} :</div>
                <div style="color: #ffca00">
                  {{ reportCore.dateRangeStart.getDateString() }}
                </div>
              </div>

              <div class="flex-ui-1">
                <div class="fs-7 mx-2 text-type-10 icon-color-1">to</div>
                <div style="color: #ffca00">
                  {{ reportCore.dateRangeEnd.getDateString() }}
                </div>
              </div>

            </div>



          </div>
        </div>
      </div>

      <div class="mt-min pa-1 py-3 px-3 d-flex flex-column justify-items-start"
        style="background-color: var(--content-bg-13)">

<MonthSelectionComponent  ref="monthSelectionComponent" @change="onMonthSelectionChange" />

       




        <div class="fs-7 mb-1 text-align-left icon-color-1">Royalty Dates</div>
        <Dropdown style="width: 250px; max-width: 250px" @update:modelValue="onRoyaltyDateSelected"
          v-model="reportRoyaltyStore.selectedDate" :options="reportRoyaltyStore.getWednesDayDatesOptions"
          optionLabel="label" placeholder="Select a Preset" class="" />
      </div>
      <div class="d-flex mt-4 mb-1 flex-wrap justify-content-around gap-2">
        <div class="d-flex flex-column justify-content-left" style="flex: 1 1 0; width: 100%">
          <div>
            <ReportTableComponent configKey="reportRoyaltyTableOwner" />
          </div>
        </div>
      </div>
      <ReportKPIVerboseComponent  />




           
           
      <div class="pa-4 mt-2 mb-2 d-flex flex-column content-bg-10">
        <div class="d-flex flex-wrap fs-8 fw-bold p-0" :class="{
    'align-items-center': !applicationStore.grid.tabletAndUnder,
    'align-items-start': applicationStore.grid.tabletAndUnder,
    'flex-column': applicationStore.grid.tabletAndUnder
  }">
          <div class="text-color-1 fs-6">Royalty Range:</div>

          <div class="flex-ui-1 ml-2">
            <div class="fs-7 mr-2 text-type-10 text-color-1">{{ rangeLabel }}:</div>
            <div style="color: #ff6600">
              {{ reportCore.dateRangeStart.getDateString()  }}
            </div>
            <div class="fs-7 mx-2 text-type-10 text-color-1">to</div>
            <div style="color: #ff6600">
              {{ reportCore.dateRangeEnd.getDateString()  }}
            </div>
          </div>

         
        </div>
       
      </div>




      <div class="mb-2 w-100" style="
            display: grid;
            grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
            grid-gap: 0.5rem 0.5rem;
          ">
        <ScoreCardComponent style="flex: 0 0 0" :componentData="reportRoyaltyStore?.scoreCards.Sales"
        ></ScoreCardComponent>
        <ScoreCardComponent style="flex: 0 0 0" :componentData="reportRoyaltyStore?.scoreCards.Amount"
        ></ScoreCardComponent>
        <ScoreCardComponent style="flex: 0 0 0" :componentData="reportRoyaltyStore?.scoreCards.Change"
        ></ScoreCardComponent>
        <ScoreCardComponent style="flex: 0 0 0" :componentData="reportRoyaltyStore?.scoreCards.Service"
        ></ScoreCardComponent>
        <ScoreCardComponent style="flex: 0 0 0" :componentData="reportRoyaltyStore?.scoreCards.Royalties"
        ></ScoreCardComponent>
        <ScoreCardComponent style="flex: 0 0 0" :componentData="reportRoyaltyStore?.scoreCards.EffectiveRoyalty"
        ></ScoreCardComponent>
       
        


      </div>




      <div v-if="auth.hasPermission('view_report_royalty_debug')" class="mt-min pa-4 d-flex flex-column"
        style="background-color: var(--content-bg-13)">
        <div class="d-flex align-items-center" style="cursor: pointer"
          @click="reportRoyaltyStore.showDebugData = !reportRoyaltyStore.showDebugData">
          <div class="flex-ui-1 text-type-10">
            <div class="icon-color-1" style="font-weight: 700; font-size: 20px">Debug</div>
          </div>

          <span class="ms-auto mdi icon icon-color-1" :class="{
            ' mdi-plus-circle': !reportRoyaltyStore.showDebugData,
            ' mdi-minus-circle': reportRoyaltyStore.showDebugData
          }">
          </span>
        </div>

      </div>






      <div v-if="reportRoyaltyStore.showDebugData && auth.hasPermission('view_report_royalty_debug')" class="mt-4">


        <div v-if="dataKPI2Store.unmappedKPIOwnersArrayCORE?.length">
          <div class="fs-6 text-color-2 fw-bold">unmapped owners CORE</div>
          <ListOfGrid :listData="dataKPI2Store.unmappedKPIOwnersArrayCORE" classDynamic="data-table-4"></ListOfGrid>

        </div>

        <div v-if="dataKPI2Store.unmappedKPIOwnersArraySM?.length">
          <div class="fs-6 text-color-2 fw-bold">unmapped owners SM</div>
          <ListOfGrid :listData="dataKPI2Store.unmappedKPIOwnersArraySM" classDynamic="data-table-4"></ListOfGrid>

        </div>

        <div v-if="dataKPI2Store.unmappedKPILeadGroupsArrayCORE?.length">
          <div class="fs-6 text-color-2 fw-bold">unmapped lead groups CORE</div>
          <ListOfGrid :listData="dataKPI2Store.unmappedKPILeadGroupsArrayCORE" classDynamic="data-table-4"></ListOfGrid>

        </div>

        <div v-if="dataKPI2Store.unmappedKPILeadGroupsArraySM?.length">
          <div class="fs-6 text-color-2 fw-bold">unmapped lead groups SM</div>
          <ListOfGrid :listData="dataKPI2Store.unmappedKPILeadGroupsArraySM" classDynamic="data-table-4"></ListOfGrid>

        </div>









      </div>


    </div>
  </div>
</template>

<script setup>
import ReportTableComponent from '@/components/ui/ReportTableComponent.vue'
import ReportKPIVerboseComponent from '@/components/ui/ReportKPIVerboseComponent.vue'
import { useReportRoyaltyStore } from '@/stores/ReportRoyaltyStore.js'
import { useReportCore } from '@/stores/ReportCore.js'
import { useApplicationStore } from '@/stores/ApplicationStore.js'
import ScoreCardComponent from '@/components/charts/ScoreCardComponent.vue'
import { useAuth } from '@/stores/Auth.js'
import { useDataKPI2Store } from '@/stores/DataKPI2Store.js'
import ListOfGrid from '@/components/ui/ListOfGrid.vue'
import MonthSelectionComponent from '@/components/ui/MonthSelectionComponent.vue'
import { onMounted,ref } from 'vue'

const reportCore = useReportCore()
const reportRoyaltyStore = useReportRoyaltyStore()
const applicationStore = useApplicationStore()

const dataKPI2Store = useDataKPI2Store();
const auth = useAuth()

const monthSelectionComponent = ref(null)

const rangeLabel = ref("week")

function onMonthSelectionChange(dateSelectionMonth){

  rangeLabel.value = "month"
reportRoyaltyStore.onMonthDateSelected(dateSelectionMonth)
}

function onRoyaltyDateSelected(date){
  rangeLabel.value = "week"
  monthSelectionComponent.value.clearSelection();
  reportRoyaltyStore.onDateUpdated(date)
}



onMounted(() => {
  reportRoyaltyStore.onViewMounted()
  
 
})
</script>
