import { useReportCore } from '@/stores/ReportCore.js'
import { markRaw } from 'vue'
export default class KPIVerboseParser3Class {
  constructor() {}

  processData(model) {
    let reportCore = markRaw(useReportCore())

    let contactObjects = []

    for (let ownerKey in model.processedData) {
      let ownerFinalItem = (model.ownersFinal[ownerKey] = {})
      ownerFinalItem.declinedCount = 0
      ownerFinalItem.uniqueDeclined = {}
      let ownerItem = model.processedData[ownerKey]
      //  console.log(ownerItem)

      for (let contactID in ownerItem.contacts) {
        let contact = ownerItem.contacts[contactID]
       // console.log(contact.hasValidProposalDeclined)
       // console.log(contact)
        let isPushed = false

        if (contact.hasValidProposalDeclined) {
          contact.proposalDeclinedKPIItemIndexs.forEach((element) => {
            let kpiDeclinedItem = contact.kpiDataVerbose[element]
            let dateToCheck = kpiDeclinedItem.date

            if (
              dateToCheck >= reportCore.dateRangeStart &&
              dateToCheck <= reportCore.dateRangeEnd
            ) {
              if (!isPushed) {
                isPushed = true
                contactObjects.push(contact)
              }
              let declinedType = kpiDeclinedItem.metric_type.replace('proposal declined ', '')
            
             

              ownerFinalItem.uniqueDeclined[declinedType] =
                ownerFinalItem.uniqueDeclined[declinedType] || 0
              ownerFinalItem.uniqueDeclined[declinedType]++

              ownerFinalItem.declinedCount++
            }
          })
        }
      }
    }

    //console.log(contactObjects)

    contactObjects.sort((a, b) => b.sortDateValueOf - a.sortDateValueOf)

    model.contactObjects.data = contactObjects
  }
}
