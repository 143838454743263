<template>
  <div class="w-100 mx-auto printable" style="max-width: 99%" :class="applicationStore.getPadding">
    <div class="content-bg-11" :class="{
    'pa-4': !applicationStore.grid.tabletAndUnder,
    'elevation-2': !applicationStore.grid.tabletAndUnder,
    'rounded-2': !applicationStore.grid.tabletAndUnder
  }">
      <div class="pa-4 d-flex flex-column" style="background-color: var(--content-bg-13)">


        <div class="d-flex align-items-center" style="cursor: pointer"
          @click="reportMarketing.section1Expanded = !reportMarketing.section1Expanded">

          <div class="flex-ui-1 text-type-10">
           
            <div class="icon-color-1" style="font-weight: 700; font-size: 20px">Marketing Report</div>
          </div>

          <span class="ms-auto mdi icon icon-color-1" :class="{
    ' mdi-plus-circle': !reportMarketing.section1Expanded,
    ' mdi-minus-circle': reportMarketing.section1Expanded
  }">
          </span>
        </div>

        <div v-if="reportMarketing.section1Expanded">

          <div class="d-flex align-items-center flex-wrap text-type-10 p-0">
            <div class="icon-color-1">Current Filters Applied:</div>

            <div class="flex-ui-1 ml-2">
              <div class="fs-7 mr-2 text-type-10 icon-color-1">owner:</div>
              <div style="color: #ffca00">{{ reportMarketing.ownerFilter || 'All Owners' }} ,</div>
            </div>

            <div class="flex-ui-1 ml-2">
              <div class="fs-7 mr-2 text-type-10 icon-color-1">location:</div>
              <div style="color: #ffca00">{{ reportMarketing.locationFilter || 'All Locations' }} ,</div>
            </div>

            <div class="flex-ui-1 ml-2">
              <div class="fs-7 mr-2 text-type-10 icon-color-1">group:</div>
              <div style="color: #ffca00">{{ reportMarketing.leadGroupFilter || 'All Groups' }} ,</div>
            </div>

            <div class="flex-ui-1 ml-2">
              <div class="fs-7 mr-2 text-type-10 icon-color-1">date:</div>
              <div style="color: #ffca00">
                {{ reportCore.dateRangeStart.getDateString() }}
              </div>
            </div>

            <div class="flex-ui-1 ml-2">
              <div class="fs-7 mx-2 text-type-10 icon-color-1">to</div>
              <div style="color: #ffca00">
                {{ reportCore.dateRangeEnd.getDateString() }}
              </div>
            </div>
          </div>

          <div class="mt-2 flex-ui-1" style="align-items: center">
            <Checkbox @change="onUseDataSourceOverrideChanged" v-model="reportCore.useDataSourceOverride"
              inputId="expandScorecards" :binary="true" />
            <label for="expandScorecards" class="fs-8 text-color-5 text-type-10 ml-2">
              override per user data sources
            </label>
          </div>


          <div v-if="reportCore.useDataSourceOverride">

            <div class="mt-3 text-type-11 icon-color-1 p-0">select data source:</div>
            <div class="mt-1 mb-2 flex-ui-1 gap-3" style="align-items: center">
              <div class="d-flex align-items-center">
                <RadioButton @update:modelValue="onKPIRouteKeyChange" v-model="reportCore.dataSourceCurrent"
                  inputId="datasource1" name="kpi_route_key" :value="reportCore.DATA_SOURCE_CORE" />
                <label for="datasource1" class="fs-8 text-color-5 text-type-10 ml-2">CORE</label>
              </div>
              <div class="d-flex align-items-center">
                <RadioButton @update:modelValue="onKPIRouteKeyChange" v-model="reportCore.dataSourceCurrent"
                  inputId="datasource2" name="kpi_route_key" :value="reportCore.DATA_SOURCE_SM" />
                <label for="datasource2" class="fs-8 text-color-5 text-type-10 ml-2">SM</label>
              </div>
              <div class="d-flex align-items-center">
                <RadioButton @update:modelValue="onKPIRouteKeyChange" v-model="reportCore.dataSourceCurrent"
                  inputId="datasource3" name="kpi_route_key" :value="reportCore.DATA_SOURCE_BLEND" />
                <label for="datasource3" class="fs-8 text-color-5 text-type-10 ml-2">BLEND</label>
              </div>
            </div>

            <div v-if="reportCore.dataSourceCurrent === reportCore.DATA_SOURCE_BLEND"
              class="mt-3 mb-3 d-flex justify-content-start"
              :class="{ 'flex-column': applicationStore.grid.tabletAndUnder, 'align-items-start': applicationStore.grid.tabletAndUnder, 'align-items-center': !applicationStore.grid.tabletAndUnder }">
              <p class="text-type-9 text-color-5 p-0 mb-0 mr-2 fs-8">Blend Date</p>
              <Calendar panelClass="min-w-min" v-model="reportCore.dateSelectionBlend"
                @date-select="onDateSelectionBlend" selectionMode="single" :manualInput="false" inputId="range" />
            </div>


          </div>

          <div class="mt-3 flex-ui-1" style="align-items: center">
            <div class="text-type-11 icon-color-1 p-0 mr-2">KPI CORE cached:</div>
            <div class="text-type-11 p-0" style="color: #ffca00">
              {{ dataKPI2Store.queryCacheDateCORE ?? '' }}
            </div>
            <button v-if="auth.hasPermission('invalidate_kpi_1_report_cache')" class="button-minimal"
              @click="toggleQueryCacheExtendedDisplay = !toggleQueryCacheExtendedDisplay">
              <span class="mdi icon icon-color-1 mdi-help-circle"></span>
            </button>
          </div>
          <div class="flex-ui-1" style="align-items: center">
            <div class="text-type-11 icon-color-1 p-0 mr-2">KPI SM cached:</div>
            <div class="text-type-11 p-0" style="color: #ffca00">
              {{ dataKPI2Store.queryCacheDateSM ?? '' }}
            </div>
            <button v-if="auth.hasPermission('invalidate_kpi_1_report_cache')" class="button-minimal"
              @click="toggleQueryCacheExtendedDisplay = !toggleQueryCacheExtendedDisplay">
              <span class="mdi icon icon-color-1 mdi-help-circle"></span>
            </button>
          </div>


          <div class="flex-ui-1 align-items-center">
            <div class="text-type-11 icon-color-1 p-0 mr-2">marketing sales cached:</div>
            <div class="text-type-11 p-0" style="color: #ffca00">
              {{ reportMarketing.queryCacheDateBudgetSpend ?? '' }}
            </div>
            <button v-if="auth.hasPermission('invalidate_marketing_spend_cache')" class="button-minimal" @click="
    toggleBudgetSpendQueryCacheExtendedDisplay =
    !toggleBudgetSpendQueryCacheExtendedDisplay
    ">
              <span class="mdi icon icon-color-1 mdi-help-circle"></span>
            </button>
          </div>


        </div>



      </div>

      <div v-if="toggleQueryCacheExtendedDisplay && reportMarketing.section1Expanded" class="mt-min pa-4"
        style="background-color: var(--content-bg-13)">




        <div class="text-type-11 icon-color-1 p-0">
          Queries are cached once a day at midnight
        </div>
        <div class="mt-1 d-flex flex-wrap align-items-center gap-2">
          <b-button class="p-1 fs-6 fw-bolder px-3" variant="primary"
            @click="reportMarketing.refreshQueryCacheKPI($event, 'kpi2')">refresh KPI CORE</b-button>

          <b-button class="p-1 fs-6 fw-bolder px-3" variant="primary"
            @click="reportMarketing.refreshQueryCacheKPI($event, 'kpi2SM')">refresh KPI SM</b-button>
        </div>
      </div>





      <div v-if="toggleBudgetSpendQueryCacheExtendedDisplay && reportMarketing.section1Expanded" class="mt-min pa-4"
        style="background-color: var(--content-bg-13)">
        <div class="text-type-11 icon-color-1 p-0">
          Queries are cached once a day at midnight
        </div>
        <div class="mt-1 d-flex flex-wrap align-items-center gap-2">
          <b-button class="p-1 fs-6 fw-bolder px-3" variant="primary" @click="reportMarketing.refreshQueryCacheBudgetSpend()">refresh
            marketing sales</b-button>
        </div>
      </div>

      <div class="mt-min pa-1 py-3 px-3" style="background-color: var(--content-bg-13)">






        <div class="d-flex justify-content-left mb-4">
          <SelectButton @update:modelValue="onYearChange" v-model="reportMarketing.yearSelectionCurrent"
            :options="optionsBudgetYearsComputed" optionLabel="year" :allowEmpty="false" />
        </div>




        <div>
          <div style="cursor: pointer" @click="() => {
    showMonthSelectSingle = !showMonthSelectSingle
    showMonthSelectMulti = false
  }
    ">
            <div class="mt-2 d-flex align-items-center">
              <span class="me-2 mdi icon icon-color-1" :class="{
    ' mdi-plus-circle': !showMonthSelectSingle,
    ' mdi-minus-circle': showMonthSelectSingle
  }">
              </span>
              <div class="text-type-11 icon-color-1 p-0">Select Month</div>
            </div>
          </div>
          <div v-if="showMonthSelectSingle">
            <div class="d-grid" :style="getGridStyle">
              <div v-for="(month, index) in monthSelectSingleOptions" :key="'monthsingle_' + month"
                class="text-type-12 icon-color-1 p-2 text-center" :class="{
    'nav-item-1-bg': !month.selected,
    'nav-item-1-selected-bg': month.selected
  }" style="cursor: pointer" @click="selectMonthSingle(month, index)">
                {{ month.name }}
              </div>
            </div>
          </div>

          <div style="cursor: pointer" @click="() => {
    showMonthSelectMulti = !showMonthSelectMulti
    showMonthSelectSingle = false
  }
    ">
            <div class="mt-2 d-flex align-items-center">
              <span class="me-2 mdi icon icon-color-1" :class="{
    ' mdi-plus-circle': !showMonthSelectMulti,
    ' mdi-minus-circle': showMonthSelectMulti
  }">
              </span>
              <div class="text-type-11 icon-color-1 p-0" style="min-width: 150px">
                Select Month Range
              </div>
            </div>
          </div>
          <div v-if="showMonthSelectMulti">
            <div class="d-grid" :style="getGridStyle">
              <div v-for="(month, index) in monthSelectMultiOptions" :key="'monthsingle_' + month"
                class="text-type-12 icon-color-1 p-2 text-center" :class="{
    'nav-item-1-bg': !month.selected,
    'nav-item-1-selected-bg': month.selected
  }" style="cursor: pointer" @click="selectMonthMulti(month, index)">
                {{ month.name }}
              </div>
            </div>
          </div>
        </div>

       



      </div>

      <ReportFiltersComponent @update="reportMarketing.buildReport()"/>



      <div v-if="reportMarketing.validateEditSpendOption" @click="reportMarketing.editSpendExpandHandler" style="cursor:pointer"
        class="mt-min pa-2 elevation-1 ui-bg-5 d-flex align-items-center">
        <div class="m-0 h6 fw-bold text-color-12">edit spend data</div>

        <span class="ms-auto mdi icon text-color-12"
          :class="{ ' mdi-plus-circle': !reportMarketing.editSpendExpanded, ' mdi-minus-circle': reportMarketing.editSpendExpanded }"> </span>
      </div>

      <div v-if="reportMarketing.validateEditSpendOption && reportMarketing.editSpendExpanded" class="mt-min d-flex flex-column">



        <div v-for="record in reportMarketing.leadGroupSpendData" :key="'budgetallocation_' + record.group_id"
          class="pa-2 content-border-b-1 fs-6 fw-bold text-color-1 px-2 ui-bg-4 d-flex flex-wrap align-items-center gap-3">

          <div class="text-align-left icon-color-7" style="min-width: 200px">
            {{ record.name }}
          </div>

          <div class="d-flex align-items-center">
            <label class="fs-8" :for="'group_spend' + record.group_id">{{ record.monthName }}</label>

            <button class="button-minimal" @click="reportMarketing.onLeadGroupOverrideChanged(record)">
              <span class="mdi icon nav-color-1" :class="{
    'mdi-cloud-lock-open': !record.override,
    'mdi-cloud-lock': record.override,
  }"></span>
            </button>


          </div>
          <div style="width:100px;">

            <b-form-input :id="'group_spend' + record.group_id" v-model="record.value"
              @focus="reportMarketing.cacheInputValueSpendEdit(record)" @blur="reportMarketing.validateInputValueSpendEdit(record)"
              @keydown.enter="reportMarketing.validateInputValueSpendEdit(record)" trim :class="{
    'input-bg-color': (record.api_controlled && !record.override),
    'input-bg-color-2': (record.override),
    'fw-regular': record.api_controlled,
    'fw-bold': record.api_controlled
  }" :disabled="!manage_marketing_data || (record.api_controlled && !record.override)"></b-form-input>
          </div>



        </div>
        <b-button style="max-width:250px" class="mt-4 p-1 fs-6 fw-bolder px-3" variant="primary"
          @click="reportMarketing.loadAllData()">refresh report</b-button>


      </div>

      <div class="d-flex mt-4 mb-1 flex-wrap justify-content-around gap-2">
        <div class="d-flex flex-column justify-content-left" :style="getTableContainerStyle">
          <div class="d-flex justify-content-left mb-2">
            <SelectButton v-model="currentTableSelection" :options="options" optionLabel="name" :allowEmpty="false" />
          </div>

          <div >
            <ReportTableComponent configKey="reportMarketingTableOwner" :vShowKey="currentTableSelection?.name"/>
            <ReportTableComponent configKey="reportMarketingTableLeadGroup" :vShowKey="currentTableSelection?.name"/>

          </div>
        </div>
        <div v-if="!applicationStore.grid.smaller" class="d-flex flex-column justify-content-left"
          :style="getTableContainerStyle">
          <div class="d-flex justify-content-left mb-2">
            <SelectButton v-model="currentTableSelection2" :options="options" optionLabel="name" :allowEmpty="false" />
          </div>

          <div >
            <ReportTableComponent configKey="reportMarketingTableOwner" :vShowKey="currentTableSelection2?.name"/>
            <ReportTableComponent configKey="reportMarketingTableLeadGroup" :vShowKey="currentTableSelection2?.name"/>

          </div>




        </div>
      </div>

      <ReportKPIVerboseComponent />

      <div class="pa-4 mb-2 d-flex flex-column content-bg-10">

        <div class="d-flex flex-wrap fs-8 fw-bold p-0"
        :class="{ 'align-items-center': !applicationStore.grid.tabletAndUnder, 'align-items-start': applicationStore.grid.tabletAndUnder, 'flex-column': applicationStore.grid.tabletAndUnder }"
        >
          <div class="text-color-1 fs-6">Growth Date Ranges:</div>

          <div class="flex-ui-1 ml-2">
            <div class="fs-7 mr-2 text-type-10 text-color-1">YOY:</div>
            <div style="color: #ff6600">
              {{ reportCore.oneYearBeforeStart.getDateString() }}
            </div>
            <div class="fs-7 mx-2 text-type-10 text-color-1">to</div>
            <div style="color: #ff6600">
              {{ reportCore.oneYearBeforeEnd.getDateString() }}
            </div>
          </div>

          <div v-if="reportCore.popRangeValid || reportCore.momRangeValid" class="flex-ui-1 ml-2">
            <div class="fs-7 mr-2 text-type-10 text-color-1">
              {{ reportCore.momRangeValid ? 'MOM' : 'POP' }}:
            </div>
            <div style="color: #ff6600">
              {{ reportCore.periodBeforeStart.getDateString() }}
            </div>
            <div class="fs-7 mx-2 text-type-10 text-color-1">to</div>
            <div style="color: #ff6600">
              {{ reportCore.periodBeforeEnd.getDateString() }}
            </div>
          </div>
        </div>

        <div class="mt-2 flex-ui-1" style="align-items: center">
          <Checkbox v-model="reportCore.expandScorecards" inputId="expandScorecards" :binary="true" />
          <label for="expandScorecards" class="fs-8 text-color-1 text-type-10 ml-2">
            Expand Scorecards
          </label>
        </div>
      </div>

      <div class="w-100" style="
            display: grid;
            grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
            grid-gap: 0.5rem 0.5rem;
          ">
        <ScoreCardComponent style="flex: 0 0 0" :componentData="reportMarketing?.scoreCards.spend_percentage"
          :labels="reportMarketing.scorecardLabels"></ScoreCardComponent>
        <ScoreCardComponent style="flex: 0 0 0" :componentData="reportMarketing?.scoreCards.spend_amount"
          :labels="reportMarketing.scorecardLabels"></ScoreCardComponent>
        <ScoreCardComponent style="flex: 0 0 0" :componentData="reportMarketing?.scoreCards.sales_amount"
          :labels="reportMarketing.scorecardLabels"></ScoreCardComponent>
        <ScoreCardComponent style="flex: 0 0 0" :componentData="reportMarketing?.scoreCards.cost_per_lead"
          :labels="reportMarketing.scorecardLabels">
        </ScoreCardComponent>
        <ScoreCardComponent style="flex: 0 0 0" :componentData="reportMarketing?.scoreCards.cost_per_appointment"
          :labels="reportMarketing.scorecardLabels"></ScoreCardComponent>
        <ScoreCardComponent style="flex: 0 0 0" :componentData="reportMarketing?.scoreCards.cost_per_issued_appointment"
          :labels="reportMarketing.scorecardLabels"></ScoreCardComponent>
        <ScoreCardComponent style="flex: 0 0 0" :componentData="reportMarketing?.scoreCards.cost_per_quote"
          :labels="reportMarketing.scorecardLabels">
        </ScoreCardComponent>
        <ScoreCardComponent style="flex: 0 0 0" :componentData="reportMarketing?.scoreCards.cost_per_sale"
          :labels="reportMarketing.scorecardLabels">
        </ScoreCardComponent>
        <ScoreCardComponent style="flex: 0 0 0" :componentData="reportMarketing?.scoreCards.ROI"
          :labels="reportMarketing.scorecardLabels">
        </ScoreCardComponent>
        <ScoreCardComponent style="flex: 0 0 0" :componentData="reportMarketing?.scoreCards.nsl"
          :labels="reportMarketing.scorecardLabels">
        </ScoreCardComponent>
        <ScoreCardComponent style="flex: 0 0 0" :componentData="reportMarketing?.scoreCards.nsa"
          :labels="reportMarketing.scorecardLabels">
        </ScoreCardComponent>
        <ScoreCardComponent style="flex: 0 0 0" :componentData="reportMarketing?.scoreCards.nsia"
          :labels="reportMarketing.scorecardLabels">
        </ScoreCardComponent>

        <ScoreCardComponent style="flex: 0 0 0" :componentData="reportMarketing?.scoreCards.issued_appointment_by_leads"
          :labels="reportMarketing.scorecardLabels">
        </ScoreCardComponent>

        <ScoreCardComponent style="flex: 0 0 0" :componentData="reportMarketing?.scoreCards.sales_by_leads"
          :labels="reportMarketing.scorecardLabels">
        </ScoreCardComponent>




      </div>


      <div class="elevation-4 pa-4 content-bg-4 rounded-2 w-100 mt-4">
        <div class="mb-2">
          <ItemContainerLeftRightSlotsComponent>
            <template #left>
              <p class="text-type-10 nav-color-1 p-0 m-0 fs-6">
                {{ reportMarketing.currentLineChartMetric }}
              </p>
              <div class="ml-2 d-flex align-content-center">
                <div class="d-inline-block" :style="reportMarketing.timelineData.datasets[0].lengendBlockStyle"></div>
                <p class="d-inline-block text-type-10 nav-color-1 p-0 m-0 fs-8 ms-1">
                  {{ reportCore.currentDate.year }}
                </p>
              </div>

              <div class="ml-2 d-flex align-content-center">
                <div class="d-inline-block" :style="reportMarketing.timelineData.datasets[1].lengendBlockStyle"></div>
                <p class="d-inline-block text-type-10 nav-color-1 p-0 m-0 fs-8 ms-1">
                  {{ reportCore.currentDate.year - 1 }}
                </p>
              </div>
            </template>
            <template #right>
              <button class="button-minimal" @click="toggleTimelineMetrics = !toggleTimelineMetrics">
                <span class="mdi icon nav-color-1 mdi-menu"></span>
              </button>

              <Dropdown v-if="toggleTimelineMetrics" v-model="selectedTimelineMetric"
                :options="reportMarketing.tableColumnsMetrics" placeholder="Select Metric" class="m-2"
                style="width: 250px; max-width: 250px" @update:modelValue="onUpdateTimelineMetric" />
            </template>
          </ItemContainerLeftRightSlotsComponent>
        </div>

        <Chart type="line" :data="reportMarketing.timelineData" :options="chartOptions" :height="260" />
      </div>

      <div v-if="auth.hasPermission('view_marketing_spend_report_debug')" class="pa-4 mt-2"
        style="background-color: var(--content-bg-13)">
        <div class="flex-ui-1" style="align-items: center">
          <Checkbox v-model="reportMarketing.showDebugData" inputId="showdebugdata" :binary="true" />
          <label for="showdebugdata" class="fs-8 icon-color-1 text-type-10 ml-2">
            Show Debug Data
          </label>
        </div>


      </div>

      <div v-if="reportMarketing.showDebugData && auth.hasPermission('view_marketing_spend_report_debug')"
        class="mt-4">


        <div v-if="dataKPI2Store.unmappedKPIOwnersArrayCORE?.length">
          <div class="fs-6 text-color-2 fw-bold">unmapped owners CORE</div>
          <ListOfGrid :listData="dataKPI2Store.unmappedKPIOwnersArrayCORE" classDynamic="data-table-4"></ListOfGrid>

        </div>

        <div v-if="dataKPI2Store.unmappedKPIOwnersArraySM?.length">
          <div class="fs-6 text-color-2 fw-bold">unmapped owners SM</div>
          <ListOfGrid :listData="dataKPI2Store.unmappedKPIOwnersArraySM" classDynamic="data-table-4"></ListOfGrid>

        </div>

        <div v-if="dataKPI2Store.unmappedKPILeadGroupsArrayCORE?.length">
          <div class="fs-6 text-color-2 fw-bold">unmapped lead groups CORE</div>
          <ListOfGrid :listData="dataKPI2Store.unmappedKPILeadGroupsArrayCORE" classDynamic="data-table-4"></ListOfGrid>

        </div>

        <div v-if="dataKPI2Store.unmappedKPILeadGroupsArraySM?.length">
          <div class="fs-6 text-color-2 fw-bold">unmapped lead groups SM</div>
          <ListOfGrid :listData="dataKPI2Store.unmappedKPILeadGroupsArraySM" classDynamic="data-table-4"></ListOfGrid>

        </div>

        <div v-if="reportMarketing.ownersExcludedByStartDateArray?.length">
          <div class="mt-2 fs-6 text-color-2 fw-bold">owners excluded by start bate</div>
          <ListOfGrid :listData="reportMarketing.ownersExcludedByStartDateArray" classDynamic="data-table-4">
          </ListOfGrid>
        </div>

        <div v-if="reportMarketing.ownersExcludedByStartDateButValidArray?.length">

          <div class="mt-2 fs-6 text-color-2 fw-bold">owners excluded by start bate but valid</div>
          <ListOfGrid :listData="reportMarketing.ownersExcludedByStartDateButValidArray" classDynamic="data-table-4">
          </ListOfGrid>

        </div>

        <div class="fs-6 text-color-2 fw-bold">snowflake spend write debug</div>
        <div class="text-align-left pa-4" style="background-color: #165489">

          <div class="mb-4 pa-1" style="background-color: #ffffff16;"
            v-for="(debugitemcontainer, key, index) in reportMarketing.dataSpendWriteDebugInfo"
            :key="'debugitemcontainer_' + index">
            <div class="fs-5 fw-bold" style="color:#ffffff;">{{ key }}</div>
            <div class="mt-1" v-for="(debugitem, index2) in debugitemcontainer"
              :key="'debugitem_' + index + '_' + index2">
              <div class="fs-8" style="color:#eeeeee;">{{ debugitem }}</div>
            </div>
          </div>
        </div>


      </div>


    </div>
  </div>
</template>

<script setup>
import { useReportCore } from '@/stores/ReportCore.js'
import { useReportKPI1 } from '@/stores/ReportKPI1.js'
import { useDataKPI2Store } from '@/stores/DataKPI2Store.js'
import { useReportMarketing } from '@/stores/ReportMarketing.js'
import { onMounted, ref, computed, reactive } from 'vue'
import { useAuth } from '@/stores/Auth.js'
import { useApplicationStore } from '@/stores/ApplicationStore.js'
import ItemContainerLeftRightSlotsComponent from '@/components/layout/ItemContainerLeftRightSlotsComponent.vue'
import ScoreCardComponent from '@/components/charts/ScoreCardComponent.vue'
import ListOfGrid from '@/components/ui/ListOfGrid.vue'
import ReportTableComponent from '@/components/ui/ReportTableComponent.vue'
import ReportFiltersComponent from '@/components/ui/ReportFiltersComponent.vue'
import ReportKPIVerboseComponent from '@/components/ui/ReportKPIVerboseComponent.vue'

const reportMarketing = useReportMarketing()
const reportCore = useReportCore()


const dataKPI2Store  = useDataKPI2Store()
const auth = useAuth()

const dateSelectionMonth = ref()
const invalidateRangeCalenderKey = ref(0)
const toggleQueryCacheExtendedDisplay = ref(false)

const showMonthSelectSingle = ref(true)
const showMonthSelectMulti = ref(false)
const toggleBudgetSpendQueryCacheExtendedDisplay = ref(false)
const selectedTimelineMetric = ref(reportMarketing.currentLineChartMetric)


var monthSelectedSingleItem = null
var monthSelectedMultiStartItem = null
var monthSelectedMultiEndItem = null

var selectMonthSinglePending = false
var selectMonthSingleFirstIndex = null




//---------------------------------------------------------------------------------------







function onUpdateTimelineMetric(event) {
  reportMarketing.changeTimelineMetric(event)
}

const toggleTimelineMetrics = ref(false)



const monthSelectSingleOptions = reactive([
  { name: 'jan', selected: false },
  { name: 'feb', selected: false },
  { name: 'mar', selected: false },
  { name: 'apr', selected: false },
  { name: 'may', selected: false },
  { name: 'jun', selected: false },
  { name: 'jul', selected: false },
  { name: 'aug', selected: false },
  { name: 'sep', selected: false },
  { name: 'oct', selected: false },
  { name: 'nov', selected: false },
  { name: 'dec', selected: false }
])

const monthSelectMultiOptions = reactive([
  { name: 'jan', selected: false },
  { name: 'feb', selected: false },
  { name: 'mar', selected: false },
  { name: 'apr', selected: false },
  { name: 'may', selected: false },
  { name: 'jun', selected: false },
  { name: 'jul', selected: false },
  { name: 'aug', selected: false },
  { name: 'sep', selected: false },
  { name: 'oct', selected: false },
  { name: 'nov', selected: false },
  { name: 'dec', selected: false }
])



const optionsAllOwners = ref([{ name: 'Owners' }, { name: 'Lead Groups' }])
const optionsOwner = ref([{ name: 'Owners' }, { name: 'Lead Groups' }])
const currentTableSelection = ref(null)
const currentTableSelection2 = ref(null)
const chartOptions = ref()
const applicationStore = useApplicationStore()





const manage_marketing_data = computed(() => {
  return (
    auth.hasPermission('edit_marketing_spend_management_data')
  )
})



const getGridStyle = computed(() => {
  let val = 'grid-template-columns: repeat(auto-fit, minmax(60px, 1fr)); gap: 0.2rem'
  if (applicationStore.grid.tabletAndUnder) {
    val = 'grid-template-columns: repeat(6, minmax(60px, 1fr)); gap: 0.2rem'
  }

  if (applicationStore.grid.smaller) {
    val = 'grid-template-columns: repeat(4, minmax(60px, 1fr)); gap: 0.2rem'
  }

  return val
})

const optionsBudgetYearsComputed = computed(() => {
  return reportMarketing.dataRawBudgetYears
})


function onYearChange(event) {
  reportCore.currentDate.year = event.year
  reportCore.currentDate.invalidate()
  //check if currently a range is in use
  if (!monthSelectedMultiStartItem && monthSelectedMultiEndItem) {

    reportCore.dateRangeStart.getValuesFromDate(new Date(reportCore.currentDate.year, reportCore.dateRangeStart.month, reportCore.dateRangeStart.day))
    reportCore.dateRangeEnd.getValuesFromDate(new Date(reportCore.currentDate.year, reportCore.dateRangeEnd.month + 1, 0))

  } else {
    reportCore.dateForMonth(new Date(reportCore.currentDate.year, reportCore.dateRangeStart.month, 1))
  }

  reportMarketing.buildReport()
  reportMarketing.loadEditSpendData()

}

function updateuseDimensionFilters() {
  reportMarketing.buildReport()
}

function selectMonthSingle(month, index) {
  //reset multi selections
  if (monthSelectedMultiStartItem) {
    monthSelectedMultiStartItem.selected = false
    monthSelectedMultiStartItem = null
  }
  if (monthSelectedMultiEndItem) {
    monthSelectedMultiEndItem.selected = false
    monthSelectedMultiEndItem = null
  }

  //handle single selects
  month.selected = !month.selected
  if (monthSelectedSingleItem) {
    monthSelectedSingleItem.selected = false
  }
  monthSelectedSingleItem = month

  //load data
  reportCore.dateForMonth(new Date(reportCore.currentDate.year, index, 1))
  reportMarketing.buildReport()
  reportMarketing.loadEditSpendData()
}

function selectMonthMulti(month, index) {
  if (!selectMonthSinglePending) {
    if (monthSelectedSingleItem) {
      monthSelectedSingleItem.selected = false
      monthSelectedSingleItem = null
    }

    //deselect all ui for months in range
    for (let i = 0; i < monthSelectMultiOptions.length; i++) {
      monthSelectMultiOptions[i].selected = false
    }

    monthSelectedMultiStartItem = null
    monthSelectedMultiEndItem = null

    month.selected = !month.selected

    monthSelectedMultiStartItem = month
    selectMonthSinglePending = true
    selectMonthSingleFirstIndex = index

    return
  }

  //check which index is higher as months can be selected in any order
  let startIndex = selectMonthSingleFirstIndex
  let endIndex = index
  if (endIndex < startIndex) {
    startIndex = index
    endIndex = selectMonthSingleFirstIndex
  }

  month.selected = !month.selected
  //in case someone selects the same month for start and end
  if (endIndex === startIndex) {
    month.selected = true
  }

  //select all ui for months in range
  for (let i = startIndex; i < endIndex + 1; i++) {
    monthSelectMultiOptions[i].selected = true
  }

  monthSelectedMultiEndItem = month
  selectMonthSinglePending = false

  reportCore.dateRangeStart.getValuesFromDate(new Date(reportCore.currentDate.year, startIndex, 1))

  reportCore.dateRangeEnd.getValuesFromDate(new Date(reportCore.currentDate.year, endIndex + 1, 0))

  //load data

  reportMarketing.buildReport()

  reportMarketing.loadEditSpendData()
}



function onUseDataSourceOverrideChanged() {
  reportMarketing.loadAllData()
}

function onDateSelectionBlend() {
  reportCore.dataBlendDate.getValuesFromDate(reportCore.dateSelectionBlend)
  if (reportCore.useDataSourceOverride) {
    reportMarketing.loadAllData()
  }
}

function onKPIRouteKeyChange(val) {
  reportCore.dataSourceCurrent = val
  if (reportCore.useDataSourceOverride) {
    reportMarketing.loadAllData()
  }

}





onMounted(() => {
  console.log(`on mounted in ReportMarketingView`)
  init()

})

async function init() {

  reportCore.reset()
  reportMarketing.ownerFilter = null
  reportMarketing.locationFilter = null
  reportMarketing.leadGroupFilter = null


  reportMarketing.ownerTableFilterCurrent = reportMarketing.ownerTableFilterValueLEGACY
  monthSelectedSingleItem = monthSelectSingleOptions[reportCore.currentDate.month]
  monthSelectedSingleItem.selected = true
  dateSelectionMonth.value = new Date(reportCore.currentDate.year, reportCore.currentDate.month, 1)
  reportCore.dateForMonth(dateSelectionMonth.value)

  chartOptions.value = setChartOptions()



  if (auth.hasPermission('read_marketing_spend_report_all_owners')) {
    currentTableSelection.value = { name: 'Owners' }
    currentTableSelection2.value = { name: 'Lead Groups' }
   
  } else {
    currentTableSelection.value = { name: 'Owners' }
    currentTableSelection2.value = { name: 'Lead Groups' }
  }

  invalidateRangeCalenderKey.value++
  applicationStore.scollToTop()
  await reportMarketing.loadAllData()
  await reportMarketing.loadSpendCacheInfo()

}



//----------------------------------------------------------------------

const options = computed(() => {
  let optionsToUse = optionsOwner.value
  if (auth.hasPermission('read_marketing_spend_report_all_owners')) {
    optionsToUse = optionsAllOwners.value
  }
  return optionsToUse
})

//----------------------------------------------------------------------

const getTableContainerStyle = computed(() => {
  return applicationStore.grid.smaller ? 'flex:1 1 0;width:100%' : 'flex:1 1 0;max-width:49%'
})

//----------------------------------------------------------------------

const getDateFiltersClass = computed(() => {
  return {
    'justify-content-left': !applicationStore.grid.tabletAndUnder,
    'justify-content-center': applicationStore.grid.tabletAndUnder,
    'flex-column': applicationStore.grid.smaller
  }
})

//----------------------------------------------------------------------

const getDebugTableStyle = computed(() => {
  return applicationStore.grid.tabletAndUnder
    ? ' flex:1 1 0;width:100%'
    : 'flex:1 1 0;max-width: 49%;'
})


//----------------------------------------------------------------------

const setChartOptions = () => {
  const documentStyle = getComputedStyle(document.documentElement)
  const textColor = documentStyle.getPropertyValue('--text-color')
  const textColorSecondary = documentStyle.getPropertyValue('--text-color-secondary')
  const surfaceBorder = documentStyle.getPropertyValue('--surface-600')

  return {
    stacked: false,
    maintainAspectRatio: false,
    aspectRatio: 0.6,

    interaction: {
      intersect: false,
      axis: 'xy',
      mode: 'index'
    },
    plugins: {
      legend: {
        display: false
      },
      tooltip: {
        animation: false,
        callbacks: {
          label: (tooltipItem, data) => {
            // Assuming 'value' is the property you want to format
            let dataset = null
            if (tooltipItem.datasetIndex === 0) {
              dataset = reportMarketing.timelineSingleMetricSet1
            }
            if (tooltipItem.datasetIndex === 1) {
              dataset = reportMarketing.timelineSingleMetricSet2
            }
            const dataPoint = dataset[tooltipItem.dataIndex]

            return dataPoint.formattedValue
          }
        }
      }
    },

    scales: {
      x: {
        ticks: {
          color: textColorSecondary
        },
        grid: {
          color: surfaceBorder
        }
      },
      y: {
        type: 'linear',
        display: true,
        position: 'left',
        ticks: {
          color: textColorSecondary
        },
        grid: {
          color: surfaceBorder
        }
      }
    }
  }
}

</script>