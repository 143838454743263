<template>
  <div v-show="vShowKey === config?.vShowKey" :key="renderKey">
    <div>
      <ItemContainerLeftRightSlotsComponent>
        <template #left>
          <p class="text-type-10 nav-color-1 p-0 m-0">{{ config?.tableName }}</p>
        </template>
        <template #right>

          <button class="button-minimal" @click="reportCore.toggleTableScrollHeight">
            <span class="ms-auto mdi icon nav-color-1"
              :class="{ ' mdi-plus-circle': reportCore.tableScrollHeightActive, ' mdi-minus-circle': !reportCore.tableScrollHeightActive }">
            </span>
          </button>

          <button class="button-minimal" @click="toggleTableMetrics = !toggleTableMetrics">
            <span class="mdi icon nav-color-1 mdi-menu"></span>
          </button>



          <MultiSelect v-if="toggleTableMetrics" v-model="selectedTableMetrics" :options="config?.tableColumnsMetrics"
            placeholder="Select Metrics" :maxSelectedLabels="0" class="m-2" style="width: 250px; max-width: 250px"
            @update:modelValue="onUpdateColumns" />
        </template>
      </ItemContainerLeftRightSlotsComponent>

      <div v-if="toggleTableMetrics">
        <div class="mt-2 text-align-left text-color-2 fw-bold fs-7">Select Preset:</div>

        <div class="content-border-b-1 content-bg-2 mb-1" style="position: relative"
          v-for="(preset, index) in dataPresetsTablesStore.getPresets(config?.presetKeys)?.system"
          :key="`preset_system_${uniqueKey}_${index}`">
          <div style="width: 100%; height: 100%; position: absolute; cursor: pointer" @click="setPresetTable(preset)">
          </div>
          <div class="pa-2 d-flex flex-wrap align-items-center gap-2">
            <div class="text-align-left icon-color-1 fs-7 me-auto"
              style="word-break: break-all;flex:1 1 0;min-width: 100px">
              {{ preset.name }}
            </div>

            <div class="d-flex flex-wrap align-items-center">
              <div class="flex-ui-1 mr-3 " style="align-items: center">
                <Checkbox :disabled="preset.is_default" v-model="preset.is_default"
                  :inputId="`preset_system_checkbox_is_default_${uniqueKey}_${index}`" :binary="true"
                  @update:modelValue="dataPresetsTablesStore.setPresetAsDefault(preset, config?.presetKeys)" />
                <label :for="`preset_system_checkbox_is_default_${uniqueKey}_${index}`"
                  class="fs-10 icon-color-1 text-type-10 ml-1">
                  default
                </label>
              </div>
              <button class="p-0 button-minimal" style="opacity: 0.6" :disabled="true">
                <span class="mdi icon icon-color-1 mdi-delete"></span>
              </button>

            </div>




          </div>
        </div>

        <div class="content-border-b-1 content-bg-2 mb-1" style="position: relative"
          v-for="(preset, index) in dataPresetsTablesStore.getPresets(config?.presetKeys)?.user"
          :key="`preset_user_${uniqueKey}_${index}`">
          <div style="width: 100%; height: 100%; position: absolute; cursor: pointer" @click="setPresetTable(preset)">
          </div>
          <div class="pa-2 d-flex flex-wrap align-items-center gap-2">
            <div class="text-align-left icon-color-1 fs-7 me-auto"
              style="word-break: break-all;flex:1 1 0;min-width: 100px; pointer-events: none">
              {{ preset.name }}
            </div>

            <div class="d-flex flex-wrap align-items-center">

              <div class="flex-ui-1 mr-3" style="align-items: center">
                <Checkbox v-if="auth.hasPermission('edit_preset_global')"
                  :disabled="!auth.hasPermission('edit_preset_global')" v-model="preset.is_global"
                  :inputId="`preset_system_checkbox_is_global_${uniqueKey}_${index}`" :binary="true" @update:modelValue="
                    dataPresetsTablesStore.updateisGlobal(preset, config?.presetKeys)
                    " />
                <label :for="`preset_system_checkbox_is_global_${uniqueKey}_${index}`"
                  v-if="auth.hasPermission('edit_preset_global') || preset.is_global"
                  class="fs-10 icon-color-1 text-type-10 ml-1">
                  global
                </label>
              </div>

              <div class="flex-ui-1 mr-3" style="align-items: center">
                <Checkbox :disabled="preset.is_default" v-model="preset.is_default"
                  :inputId="`preset_user_checkbox_is_default_${uniqueKey}_${index}`" :binary="true" @update:modelValue="
                    dataPresetsTablesStore.setPresetAsDefault(preset, config?.presetKeys)
                    " />
                <label :for="`preset_user_checkbox_is_default_${uniqueKey}_${index}`"
                  class="fs-10 icon-color-1 text-type-10 ml-1 ">
                  default
                </label>
              </div>
              <button :disabled="preset.is_global && !auth.hasPermission('edit_preset_global')"
                :style="getPresetDeleteStyle(preset)" class=" p-0 button-minimal" style="z-index: 1"
                @click="dataPresetsTablesStore.delete(preset, config?.presetKeys)">
                <span class="mdi icon icon-color-1 mdi-delete"></span>
              </button>


            </div>



          </div>
        </div>

        <div class="mt-2 text-align-left text-color-2 fw-bold fs-7">Add Preset:</div>

        <div class="mb-4 gap-2 d-flex align-items-center">
          <b-form-input class="text-color-1" :id="`new_preset_input_${uniqueKey}`" v-model="newPresetModel"
            @update:modelValue="updateNewModelValue" trim></b-form-input>

          <b-button style="width: 100%; max-width: 280px" class="p-1 fs-6 fw-bolder px-3" variant="primary"
            @click="dataPresetsTablesStore.add(config.presetKeys)">Add Preset</b-button>
        </div>
      </div>

      <RCTable :componentData="config?.dataTable" :sortField="config?.sortField" :sortOrder="config?.sortOrder||-1" @select="onTableItemSelect"
        @unselect="onTableItemUnselect" @refresh="onTableItemUnselect" :classDynamic="config?.classDynamic"></RCTable>
    </div>
  </div>
</template>

<script setup>
import { onMounted, ref, computed } from 'vue'
import ItemContainerLeftRightSlotsComponent from '@/components/layout/ItemContainerLeftRightSlotsComponent.vue'
import { useDataPresetsTablesStore } from '@/stores/DataPresetsTablesStore.js'
import { useAuth } from '@/stores/Auth.js'
import { useReportCore } from '@/stores/ReportCore.js'
import { useDynamicConfigStore } from '@/stores/DynamicConfigStore.js'
import { useUniqueKey } from '@/scripts/utils/UniqueKey.js'
import RCTable from '@/components/charts/RCTable.vue'
const props = defineProps({
  configKey: {
    type: String,
    required: true
  },
  vShowKey: {
    type: String,
    default: 'Owners'
  }
})
var config = {}
const auth = useAuth();
const reportCore = useReportCore();
//this is a local model , on update it will set the vaue in the config , which is referenced when updating DB etc..
const selectedTableMetrics = ref(null)
const newPresetModel = ref(null)
const renderKey = ref(0)

function updateNewModelValue(value) {
  config.presetStore.presetNamesNew[config.presetKeys.report_key][config.presetKeys.table_key] =
    value
}

function getPresetDeleteStyle(preset) {
  if (preset.is_global && !auth.hasPermission('edit_preset_global')) {
    return "opacity:0.6;"
  }
  return ""
}

const dynamicConfigStore = useDynamicConfigStore()
const { uniqueKey } = useUniqueKey()

const toggleTableMetrics = ref(false)
const dataPresetsTablesStore = useDataPresetsTablesStore()

function onUpdateColumns(value) {

  config.presetStore.selectedMetrics[config.presetKeys.report_key][config.presetKeys.table_key] =
    value

  let columnsList = dataPresetsTablesStore.getSelections(config.presetKeys)

  if (!columnsList) return

  let headers = []
  let header = {}
  header.field = config.tableHeaderKey
  header.header = config.tableHeaderKey
  headers.push(header)
  columnsList.forEach((element) => {
    let header = {}
    header.field = element
    header.header = element
    headers.push(header)
  })
  config.dataTable.tableHeaders = headers
}

function setPresetTable(preset) {
  dataPresetsTablesStore.setSelectionToPreset(preset, config.presetKeys)
  selectedTableMetrics.value =
    config.presetStore.selectedMetrics[config.presetKeys.report_key][config.presetKeys.table_key]
  onUpdateColumns(selectedTableMetrics.value)
}

const onTableItemSelect = (event) => {
  //console.log(event.data)

  config.selectionFilters.forEach(element => {


    config.relatedStore[element.selectionFilter] = event.data[element.dimensionStaticAlt || element.dimensionStatic]
    if (element.selectionFilter === 'ownerFilter') {
      config.relatedStore.leadGroupFilter = null

    }

    if (element.selectionFilter === 'salesPersonFilter') {
      config.relatedStore.leadGroupFilter = null
      config.relatedStore.salesPersonUnfilteredFilter = null

    }

    if (element.selectionFilter === 'salesPersonUnfilteredFilter') {
      config.relatedStore.leadGroupFilter = null
      config.relatedStore.salesPersonFilter = null

    }

  })

  config.relatedStore.buildReport()
}

//----------------------------------------------------------------------

const onTableItemUnselect = (event) => {

  config.selectionFilters.forEach(element => {

    config.relatedStore[element.selectionFilter] = null

    if (element.selectionFilter === 'ownerFilter') {
      config.relatedStore.leadGroupFilter = null
      config.relatedStore.leadGroupFilterAlt = null
      config.relatedStore.salesPersonFilter = null
      config.relatedStore.salesPersonUnfilteredFilter = null

    }
    if (element.selectionFilter === 'salesPersonFilter') {

      config.relatedStore.leadGroupFilter = null

    }

    if (element.selectionFilter === 'salesPersonUnfilteredFilter') {
      config.relatedStore.leadGroupFilter = null


    }

  })



  config.relatedStore.buildReport()
}

onMounted(() => {
  config = dynamicConfigStore.getConfig(props.configKey)

  dataPresetsTablesStore.getDefaultPreset(config.presetKeys)
  selectedTableMetrics.value =
    config.presetStore.selectedMetrics[config.presetKeys.report_key][config.presetKeys.table_key]

  onUpdateColumns(selectedTableMetrics.value)
  renderKey.value++
})
</script>
