<template>
  <div></div>
</template>
<script setup>
import { useAuth } from '@/stores/Auth.js'
import { useRouter } from 'vue-router'
const auth = useAuth()
const router = useRouter()
if (auth.hasRole('salesperson')) {
    router.push("/application/kpi2_report"); 
}else{
   // router.push("/application/report_kpi_declined"); 
    router.push("/application/kpi1_report"); 
}
</script>
<style scoped></style>
