import { useReportCore } from '@/stores/ReportCore.js'
import { markRaw } from 'vue';
export default  class KPIVerboseParser2Class {
    constructor() {
    
    }

    processData(model) {
        let reportCore = markRaw(useReportCore());

        for (let ownerKey in model.processedData) {
            if (ownerKey.toLowerCase().indexOf('sandbox') !== -1) {
              continue
            }
            if (ownerKey === 'null') {
              continue
            }
            let ownerItem = model.processedData[ownerKey]
    
    
    
    
    
    
    
    
    
            for (let contactID in ownerItem.contacts) {
              let contact = ownerItem.contacts[contactID]
              if (ownerKey === 'null') {
                // console.log(contact)
              }
    
              // valid sale
              if (contact.hasValidSale) {
                let ownerItemValid = model.ownersFinal[ownerKey]
                if (ownerItemValid) {
    
                  contact.saleKPIItemIndexs.forEach((element) => {
                    let kpiSalesItem = contact.kpiDataVerbose[element]
    
                    let dateToCheck = kpiSalesItem.date
    
                    if (
                      dateToCheck >= reportCore.dateRangeStart &&
                      dateToCheck <= reportCore.dateRangeEnd
                    ) {
    
    
                      let validContact = ownerItemValid.contacts[contact.id]
                      if (!validContact) {
                        validContact = ownerItemValid.contacts[contact.id] = contact
                      }
                    }
                  })
    
                }
              }
    
              // valid service change
    
              if (contact.hasValidServiceChange) {
    
                let ownerItemValid = model.ownersFinal[ownerKey]
                if (ownerItemValid) {
    
                  contact.serviceChangeKPIItemIndexs.forEach((element) => {
                    let kpiServiceChangeItem = contact.kpiDataVerbose[element]
    
                    let dateToCheck = kpiServiceChangeItem.date
    
                    if (
                      dateToCheck >= reportCore.dateRangeStart &&
                      dateToCheck <= reportCore.dateRangeEnd
                    ) {
                      ownerItemValid.serviceChangeValue += kpiServiceChangeItem.metric
                      ownerItemValid.serviceChangeCount++
    
                      let validContact = ownerItemValid.contacts[contact.id]
                      if (!validContact) {
                        validContact = ownerItemValid.contacts[contact.id] = contact
                      }
                    }
                  })
    
                }
              }
    
              // valid service service
    
              if (contact.hasValidServiceService) {
                let ownerItemValid = model.ownersFinal[ownerKey]
                if (ownerItemValid) {
    
    
                  contact.serviceServiceKPIItemIndexs.forEach((element) => {
                    let kpiServiceServiceItem = contact.kpiDataVerbose[element]
    
                    let dateToCheck = kpiServiceServiceItem.date
    
                    if (
                      dateToCheck >= reportCore.dateRangeStart &&
                      dateToCheck <= reportCore.dateRangeEnd
                    ) {
                      ownerItemValid.serviceServiceValue += kpiServiceServiceItem.metric
                      ownerItemValid.serviceServiceCount++
    
                      let validContact = ownerItemValid.contacts[contact.id]
                      if (!validContact) {
                        validContact = ownerItemValid.contacts[contact.id] = contact
                      }
                    }
                  })
    
                }
              }
            }
          }

     
    }
  }