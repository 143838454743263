import { defineStore } from 'pinia'
import { useReportCore } from '@/stores/ReportCore.js'
import { markRaw, reactive, ref } from 'vue'
import { useURLS } from '@/stores/URLS.js'
import { useKPIVerboseQuery1 } from '@/scripts/kpi/KPIVerboseQuery1.js'
import { useKPIVerboseQuery2 } from '@/scripts/kpi/KPIVerboseQuery2.js'
import { useKPIVerboseQuery3 } from '@/scripts/kpi/KPIVerboseQuery3.js'
import { useKPIVerboseParser1 } from '@/scripts/kpi/KPIVerboseParser1.js'
import { useKPIVerboseParser2 } from '@/scripts/kpi/KPIVerboseParser2.js'
import { useKPIVerboseParser3 } from '@/scripts/kpi/KPIVerboseParser3.js'
import KPIVerboseModel from '@/scripts/kpi/KPIVerboseModel.js'



export const useDataReportKPIVerbose = defineStore('DataReportKPIVerbose', {
  state: () => {
    return {
      reportCore: markRaw(useReportCore()),
      requestModels: {},
      requestModelQueries: {},
      requestModelParsers: {},
    }
  },

  actions: {

    getModel(options) {    
      let combinedKey = options.queryKey + options.parserKey
      let modelToUse = this.requestModels[combinedKey]
      if (modelToUse) {
        return modelToUse
      }
      modelToUse = this.createModel(options)
      this.requestModels[combinedKey] = modelToUse
      return modelToUse


    },

    //----------------------------------------------------------------

    createModel(options) {    

      let model = new KPIVerboseModel(options)
      this.getQuery(model)
      this.getParser(model)

      return model


    },

    //----------------------------------------------------------------

    getQuery(model) {
      let query = this.requestModelQueries[model.queryKey]
      if (query) {
        model.query = query
        return
      }
      switch (model.queryKey) {
        case "KPIVerboseQuery1":
          query = useKPIVerboseQuery1(model)
          break;
        case "KPIVerboseQuery2":
          query = useKPIVerboseQuery2(model)
          break;
        case "KPIVerboseQuery3":
          query = useKPIVerboseQuery3(model)
          break;
      }

      this.requestModelQueries[model.queryKey] = query
      model.query = query
     // console.log('getQuery')
     // console.log(model)
    },

    //----------------------------------------------------------------


    getParser(model) {
      let parser = this.requestModelParsers[model.parserKey]
      if (parser) {
        model.parser = parser
        return
      }
      switch (model.parserKey) {
        case "KPIVerboseParser1":
          parser = useKPIVerboseParser1(model)
          break;
        case "KPIVerboseParser2":
          parser = useKPIVerboseParser2(model)
          break;
        case "KPIVerboseParser3":
          parser = useKPIVerboseParser3(model)
          break;
      }

      this.requestModelParsers[model.parserKey] = parser
      model.parser = parser
     // console.log('getParser')
      //console.log(model)
    },

    //----------------------------------------------------------------


    async invalidate(requestKeys,options) {
      //if not options is passed then invalidate all
      for (let modelKey in this.requestModels) {
        let model = this.requestModels[modelKey]
        if (requestKeys) {
          if (model.queryKey === requestKeys.queryKey) {
            //for future versions perhaps: models can share query classes , 
            //should not call invalidate on all , 
            //also currently i dont think it will work as expected
            model.invalidate(options);
          }

        } else {
          model.invalidate(options);
        }

      }



    },

    //----------------------------------------------------------------

    async kpiVerboseRebuild(data) {
    //  console.log('kpiVerboseRebuild')
    //  console.log(data)
      let dataLocal = { ...data }
      if (dataLocal.key === 'kpi2') {
        dataLocal.key = 'kpiVerbose'
      }
      if (dataLocal.key === 'kpi2SM') {
        dataLocal.key = 'kpiVerboseSM'
      }
      this.loading = true
      try {
        let urls = useURLS()
        var urlToUse = urls.getURL('kpi_verbose', 'kpiVerboseRebuild')
        let result = await this.reportCore.loadServerData(urlToUse, dataLocal)
        if (result) {
         // console.log(result)
          if (result.complete) {
            this.invalidate()
          }
          this.loading = false
        }
      } catch (error) {
        console.log(error)
        this.loading = false
      }
    },

  }


})
