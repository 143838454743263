import { defineStore } from 'pinia'
import { markRaw } from 'vue'
import { useReportCore } from '@/stores/ReportCore.js'
import { useDataReportKPIVerbose } from '@/stores/DataReportKPIVerbose.js'
import { useAuth } from '@/stores/Auth.js'
import { useErrorHandler } from '@/stores/ErrorHandler.js'


export const useReportKPIDeclinedStore = defineStore('ReportKPIDeclinedStore', {
  state() {
    return {
      section1Expanded: true,
      ownerFilter: null,
      selectedDate: null,     
      dataTableOwners: {},    
      tableDataOwnersObj: null,
      tableDataLocationsObj: null,
      tableDataSalesPersonsObj: null,
      dateSelectionMonth: null,
      tableDataLeadGroupsObj: null,
      metrics: null,
      scoreCardsGrouped: [],
      scoreCardsUnGrouped: [],
      reportCore: markRaw(useReportCore()),
      dataReportKPIVerbose: markRaw(useDataReportKPIVerbose()),
      KPIVerboseModel: useDataReportKPIVerbose().getModel({queryKey:'KPIVerboseQuery3',parserKey:'KPIVerboseParser3'}),
     
      
      


      reportElementProps: markRaw([
        'SALES_SUM',
        'SALES_COUNT'
      ]),

      tableColumnsMetrics: markRaw(['declined count','P&M','Customer Canceled','Finance Turn Down']),

      showDebugData: false,

    }
  },

  

  actions: {
    async onViewMounted() {
      this.reportCore.reset()
      this.reset()
      this.buildReport()

    },

    reset() {
      this.ownerFilter = null
    },  

    onMonthDateSelected(dateSelectionMonth) {
      this.useRoyaltyRange = false
      this.selectedDate = null;
      this.dateSelectionMonth = dateSelectionMonth
      this.buildReport()
    },   

    //-------------------------------------------------------------------------------------------------

    async buildReportProcess1(){

      let ownersFinal = this.KPIVerboseModel.ownersFinal =  markRaw({})
      this.scoreCardsGrouped = []
      this.scoreCardsUnGrouped = []

      

      await this.KPIVerboseModel.invalidate({ownerFilter:this.ownerFilter})

     

      let tableDataOwners = []
     // console.log(this.KPIVerboseModel.processedData)

      this.metrics = markRaw({})
      this.metrics.grouped = {}
      this.metrics.ungrouped = {}
      this.metrics.grouped['declined count'] = 0

      let keyGroups = {};
      
      keyGroups["P&M - Rep Followup"] = "P&M"
      keyGroups["Other"] = "P&M"
      keyGroups["P&M"] = "P&M"
      keyGroups["P&M - One Leg"] = "P&M"
      keyGroups["Work out of Scope"] = "P&M"
      keyGroups["Duplicate"] = "P&M"
      keyGroups["no reason"] = "P&M"
      keyGroups["Price"] = "P&M"
      keyGroups["Found Another Company"] = "P&M"
      keyGroups["Not Ready"] = "P&M"

      keyGroups["Customer Canceled"] = "Customer Canceled"
      keyGroups["Canceled after sale"] = "Customer Canceled"

      keyGroups["Finance Turn Down"] = "Finance Turn Down"
      keyGroups["Finance Turndown"] = "Finance Turn Down"

      


      //declined P&M - Rep Followup
      //declined Other
      //declined P&M
      //declined Customer Canceled
      //declined P&M - One Leg
      //declined Work out of Scope
      //declined Duplicate
      //declined no reason
      //declined Price
      //declined Found Another Company
      //declined Not Ready
      //declined Finance Turn Down
      //declined Canceled after sale
      //declined Finance Turndown

      for (let ownerKey in this.KPIVerboseModel.processedData) {
       

        let ob = {}
        ob.Owner = ownerKey
        ob['P&M'] = 0;
        ob['Customer Canceled'] = 0;
        ob['Finance Turn Down'] = 0;
        ob['declined count'] = ownersFinal[ownerKey].declinedCount
        tableDataOwners.push(ob)

        for(let uniqueDeclinedItemKey in ownersFinal[ownerKey].uniqueDeclined){

          let mappedKey = keyGroups[uniqueDeclinedItemKey]
          if(!mappedKey){
            mappedKey = "P&M"
          }

          this.metrics.grouped[mappedKey] = this.metrics.grouped[mappedKey]||0
          this.metrics.grouped[mappedKey] += ownersFinal[ownerKey].uniqueDeclined[uniqueDeclinedItemKey]||0
          this.metrics.grouped['declined count'] += ownersFinal[ownerKey].uniqueDeclined[uniqueDeclinedItemKey]||0

          let ungroupedKey = `${uniqueDeclinedItemKey} (${mappedKey})`

          this.metrics.ungrouped[ungroupedKey] = this.metrics.ungrouped[ungroupedKey]||0
          this.metrics.ungrouped[ungroupedKey] += ownersFinal[ownerKey].uniqueDeclined[uniqueDeclinedItemKey]||0

          ob[mappedKey] = ob[mappedKey]||0
          ob[mappedKey] += ownersFinal[ownerKey].uniqueDeclined[uniqueDeclinedItemKey]||0
        }      

      }

      this.dataTableOwners.tableData = tableDataOwners

      for(let key in this.metrics.grouped){

        let scoreCardObject = {}
        scoreCardObject.title = key
        scoreCardObject.value = this.metrics.grouped[key] || 0
        scoreCardObject.valueFormat = 'INT'
        scoreCardObject.metricSub1Valid = false
        scoreCardObject.metricSub2Valid = false
        scoreCardObject.valueSub1Valid = false
        scoreCardObject.valueSub2Valid = false
        this.scoreCardsGrouped.push(scoreCardObject)

      }

      for(let key in this.metrics.ungrouped){

        let scoreCardObject = {}
        scoreCardObject.title = key
        scoreCardObject.value = this.metrics.ungrouped[key] || 0
        scoreCardObject.valueFormat = 'INT'
        scoreCardObject.metricSub1Valid = false
        scoreCardObject.metricSub2Valid = false
        scoreCardObject.valueSub1Valid = false
        scoreCardObject.valueSub2Valid = false
        this.scoreCardsUnGrouped.push(scoreCardObject)

      }

     

     //not required because invalidate was called on model
      //this.dataReportKPIVerbose.invalidate({queryKey:"KPIVerboseQuery3"},{ownerFilter:this.ownerFilter});

      this.reportCore.setLoadHeavy(false)

    },

   

   /* buildScoreCards() {
      let scoreCardObject = {}
      scoreCardObject.title = 'Sales'
      scoreCardObject.value = this.metrics.Sales || 0
      scoreCardObject.valueFormat = 'INT'
      scoreCardObject.metricSub1Valid = false
      scoreCardObject.metricSub2Valid = false
      scoreCardObject.valueSub1Valid = false
      scoreCardObject.valueSub2Valid = false
      this.scoreCards.Sales = scoreCardObject

      //--------------------------------------------------------

      scoreCardObject = {}
      scoreCardObject.title = 'Amount'
      scoreCardObject.value = this.metrics.Amount || 0
      scoreCardObject.valueFormat = 'USD'
      scoreCardObject.metricSub1Valid = false
      scoreCardObject.metricSub2Valid = false
      scoreCardObject.valueSub1Valid = false
      scoreCardObject.valueSub2Valid = false
      this.scoreCards.Amount = scoreCardObject

      //--------------------------------------------------------

      scoreCardObject = {}
      scoreCardObject.title = 'Change'
      scoreCardObject.value = this.metrics.Change || 0
      scoreCardObject.valueFormat = 'USD'
      scoreCardObject.metricSub1Valid = false
      scoreCardObject.metricSub2Valid = false
      scoreCardObject.valueSub1Valid = false
      scoreCardObject.valueSub2Valid = false
      this.scoreCards.Change = scoreCardObject

      //--------------------------------------------------------

      scoreCardObject = {}
      scoreCardObject.title = 'Service'
      scoreCardObject.value = this.metrics.Service || 0
      scoreCardObject.valueFormat = 'USD'
      scoreCardObject.metricSub1Valid = false
      scoreCardObject.metricSub2Valid = false
      scoreCardObject.valueSub1Valid = false
      scoreCardObject.valueSub2Valid = false
      this.scoreCards.Service = scoreCardObject

      //--------------------------------------------------------

      scoreCardObject = {}
      scoreCardObject.title = 'Royalties'
      scoreCardObject.value = this.metrics.Royalties || 0
      scoreCardObject.valueFormat = 'USD'
      scoreCardObject.metricSub1Valid = false
      scoreCardObject.metricSub2Valid = false
      scoreCardObject.valueSub1Valid = false
      scoreCardObject.valueSub2Valid = false
      this.scoreCards.Royalties = scoreCardObject
    },*/

    doubleRaf(callback, ...args) {
      requestAnimationFrame(() => {
        requestAnimationFrame(() => {
          try {
            callback(...args)
          } catch (error) {
            useErrorHandler().processScriptError(error)
          }
        })
      })
    },

    async buildReport() {
      this.reportCore.setLoadHeavy(true, 'KPI Declined Report', 'process')
      this.doubleRaf(() => this.buildReportProcess1())
    }
  }
})
