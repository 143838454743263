<template>

    <div class="mt-min pa-1 py-3 px-3 d-flex flex-column justify-items-start"
        style="background-color: var(--content-bg-13)">

        <div class="d-grid" :style="getGridStyle">
            <div v-for="(month, index) in monthSelectSingleOptions" :key="'monthsingle_' + month"
                class="text-type-12 icon-color-1 p-2 text-center" :class="{
                    'nav-item-1-bg': !month.selected,
                    'nav-item-1-selected-bg': month.selected
                }" style="cursor: pointer" @click="selectMonthSingle(month, index)">
                {{ month.name }}
            </div>
        </div>
    </div>


</template>

<script setup>
import { useReportCore } from '@/stores/ReportCore.js'
import { onMounted, computed, reactive,ref } from 'vue'
import { useApplicationStore } from '@/stores/ApplicationStore.js'

const emit = defineEmits(['change'])

function clearSelection(){
    if(monthSelectedSingleItem){
    monthSelectedSingleItem.selected = false
    }
    monthSelectedSingleItem = null;
    dateSelectionMonth.value = null;
}

defineExpose({
    clearSelection,reset
  })

const reportCore = useReportCore()
var monthSelectedSingleItem = null
const dateSelectionMonth = ref()
const applicationStore = useApplicationStore();

const monthSelectSingleOptions = reactive([
    { name: 'jan', selected: false },
    { name: 'feb', selected: false },
    { name: 'mar', selected: false },
    { name: 'apr', selected: false },
    { name: 'may', selected: false },
    { name: 'jun', selected: false },
    { name: 'jul', selected: false },
    { name: 'aug', selected: false },
    { name: 'sep', selected: false },
    { name: 'oct', selected: false },
    { name: 'nov', selected: false },
    { name: 'dec', selected: false }
])






function reset(){

   
        monthSelectedSingleItem = monthSelectSingleOptions[reportCore.currentDate.month]
        monthSelectedSingleItem.selected = true
        dateSelectionMonth.value = new Date(reportCore.currentDate.year, reportCore.currentDate.month, 1)
        reportCore.dateForMonth(dateSelectionMonth.value)
   

}

function selectMonthSingle(month, index) {
  //reset multi selections
  

  //handle single selects
  month.selected = !month.selected
  if (monthSelectedSingleItem) {
    monthSelectedSingleItem.selected = false
  }
  monthSelectedSingleItem = month

  dateSelectionMonth.value = new Date(reportCore.currentDate.year, index, 1)
  reportCore.dateForMonth(dateSelectionMonth.value)
 emit('change',dateSelectionMonth.value)
}


const getGridStyle = computed(() => {
    let val = 'grid-template-columns: repeat(auto-fit, minmax(60px, 1fr)); gap: 0.2rem'
    if (applicationStore.grid.tabletAndUnder) {
        val = 'grid-template-columns: repeat(6, minmax(60px, 1fr)); gap: 0.2rem'
    }

    if (applicationStore.grid.smaller) {
        val = 'grid-template-columns: repeat(4, minmax(60px, 1fr)); gap: 0.2rem'
    }

    return val
})

</script>