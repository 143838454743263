import { defineStore } from 'pinia'
import { markRaw } from 'vue'
import { useReportCore } from '@/stores/ReportCore.js'
import SimpleDate from '../scripts/utils/SimpleDate'
import { useDataReportKPIVerbose } from '@/stores/DataReportKPIVerbose.js'
import { useAuth } from '@/stores/Auth.js'
import { useErrorHandler } from '@/stores/ErrorHandler.js'

import { useDataKPI2Store } from '@/stores/DataKPI2Store.js'
export const useReportRoyaltyStore = defineStore('ReportRoyaltyStore', {
  state() {
    return {
      section1Expanded: true,
      ownerFilter: null,
      selectedDate: null,
      wednesDayDates: [],
      wednesDayDatesOptions: [],
      royaltyDateRange: null,
      dataTableOwners: {},
      useRoyaltyRange: null,
      tableDataOwnersObj: null,
      tableDataLocationsObj: null,
      tableDataSalesPersonsObj: null,
      dateSelectionMonth: null,
      tableDataLeadGroupsObj: null,
      metrics: null,

      scoreCards: {},
      reportCore: markRaw(useReportCore()),
      
     
      dataKPI2Store: markRaw(useDataKPI2Store()),
      dataReportKPIVerbose: markRaw(useDataReportKPIVerbose()),
      KPIVerboseModel: useDataReportKPIVerbose().getModel({queryKey:'KPIVerboseQuery2',parserKey:'KPIVerboseParser2'}),
      

      reportElementProps: markRaw([
        'SALES_SUM',
        'SALES_COUNT'
      ]),

      tableColumnsMetrics: markRaw(['Sales', 'Amount', 'Change', 'Service', 'Royalties']),

      showDebugData: false,

    }
  },

  getters: {
    getWednesDayDatesOptions(state) {
      return state.wednesDayDatesOptions
    }
  },

  actions: {
    async onViewMounted() {

      this.reportCore.reset()
      await this.loadAllData()


    },
    async loadAllData() {

      let asyncProcessSuccessful = false
      asyncProcessSuccessful = await this.dataKPI2Store.loadDataAll()

      this.reset()
      this.buildReport()


    },

    reset() {
      this.ownerFilter = null
      this.useRoyaltyRange = true
      this.wednesDayDates = this.reportCore.getWednesdaysOfYear(
        this.reportCore.currentDate.year,
        true
      )

      console.log( this.wednesDayDates)

      let a = []
      this.wednesDayDates.forEach((element) => {
        let ob = {}
        ob.label = element.toString()
        ob.data = element
        a.push(ob)
      })

      this.wednesDayDatesOptions = a

      this.wednesDayDatesOptions.reverse()

      let todayDate = new SimpleDate(new Date())

      let indexLast = 0
      let indexSecondLast = 1

      this.selectedDate = this.wednesDayDatesOptions[indexSecondLast]

      let useLastIndex = false
      if (this.selectedDate) {
        if (this.selectedDate.data != todayDate) {
          useLastIndex = true
        }
      } else {
        useLastIndex = true
      }

      if (useLastIndex) {
        this.selectedDate = this.wednesDayDatesOptions[indexLast]
      }


    },



    onDateUpdated(date) {
      this.useRoyaltyRange = true
      this.selectedDate = date
      this.buildReport()
    },

    onMonthDateSelected(dateSelectionMonth) {
      this.useRoyaltyRange = false
      this.selectedDate = null;
      this.dateSelectionMonth = dateSelectionMonth
      this.buildReport()

    },

    getRoyaltyDateRange() {
      this.royaltyDateRange = this.reportCore.getRoyaltyPeriodForWednesday(
        this.selectedDate.data.getNativeDate()
      )
      // console.log(this.royaltyDateRange)
      this.reportCore.dateRangeStart = this.royaltyDateRange.start
      this.reportCore.dateRangeEnd = this.royaltyDateRange.end
    },


    getOwnerStorage(ownerName) {
      let ownerStorage = this.tableDataOwnersObj[ownerName]
      if (!ownerStorage) {
        ownerStorage = this.tableDataOwnersObj[ownerName] = {}
        let systemOwner = this.reportCore.dataOwners[ownerName]

        let isMapped = true
        let ownerType = null
        if (!systemOwner) {
          isMapped = false
        } else {
          if (systemOwner.started_at.year !== this.reportCore.currentDate.year) {
            ownerType = 'legacy'
          }

          if (systemOwner.started_at.year === this.reportCore.currentDate.year) {
            ownerType = 'new'
          }
        }

        ownerStorage.isMapped = isMapped
        ownerStorage.ownerType = ownerType
        ownerStorage.name = ownerName
        ownerStorage.data = {}
      }

      return ownerStorage
    },


    buildReportProcess7() {
      //let tableDataOwners = []     
      // let tableDataLeadGroups = []

      // console.log(this.tableDataOwnersObj)

      for (let ownerName in this.tableDataOwnersObj) {
        let ownerItem = this.tableDataOwnersObj[ownerName]
        ownerItem.data.OWNER = ownerName
        /*if (this.ownerFilter) {
          if (ownerName === this.ownerFilter) {
            this.buildMetricsTables(ownerItem.data, tableDataOwners)
          }
        } else {
          this.buildMetricsTables(ownerItem.data, tableDataOwners)
        }*/

        /*if (!ownerItem.isMapped) {
          let unmappedItem = this.dataKPI2Store.unmappedKPIOwnersCORE[ownerName]
          let unmappedItem2 = this.dataKPI2Store.unmappedKPIOwnersSM[ownerName]
          if (unmappedItem) {
            unmappedItem.props.sales.value = this.reportCore.formatCurrency(
              ownerItem.data.SALES_SUM
            )
          }
          if (unmappedItem2) {
            unmappedItem2.props.sales.value = this.reportCore.formatCurrency(
              ownerItem.data.SALES_SUM
            )
          }
        }*/
      }

      /* for (let locationName in this.tableDataLocationsObj) {
         let locationItem = this.tableDataLocationsObj[locationName]
         locationItem.data.LOCATION = locationName
         this.buildMetricsTables(locationItem.data, tableDataLocations)
       }*/

      /* for (let leadGroupName in this.tableDataLeadGroupsObj) {
         let leadGroupItem = this.tableDataLeadGroupsObj[leadGroupName]
         leadGroupItem.data.LEAD_GROUP = leadGroupName
         this.buildMetricsTables(leadGroupItem.data, tableDataLeadGroups)
 
         if (!leadGroupItem.isMapped) {
           let unmappedItem = this.dataKPI2Store.unmappedKPILeadGroupsCORE[leadGroupName]
           let unmappedItem2 = this.dataKPI2Store.unmappedKPILeadGroupsSM[leadGroupName]
           if (unmappedItem) {
             unmappedItem.props.sales.value = this.reportCore.formatCurrency(
               leadGroupItem.data.SALES_SUM
             )
           }
           if (unmappedItem2) {
             unmappedItem2.props.sales.value = this.reportCore.formatCurrency(
               leadGroupItem.data.SALES_SUM
             )
           }
         }
       }*/

      // this.dataTableOwners.tableData = tableDataOwners
      //this.dataTableLocations.tableData = tableDataLocations
      // this.dataTableGroups.tableData = tableDataLeadGroups

      this.dataKPI2Store.buildDebugInfo()

      // this.buildMetricsTimeline(this.timeLineData)
      // this.changeTimelineMetric(this.currentLineChartMetric)
      // this.buildScoreCards(this.metrics)
    },



    //-------------------------------------------------------------------------------------------------

    buildReportProcess6(
      ownerKPIObj,
      locationName,
      salesPersonName,
      leadGroupName,
      dateBeingProcessed,
      dateRangesValidities
    ) {

      let locationKPIObj =
        ownerKPIObj.years[dateBeingProcessed.year].months[dateBeingProcessed.month].days[
          dateBeingProcessed.day
        ].locations[locationName]

      let salesPersonKPIObj = locationKPIObj.salesPersons[salesPersonName]

      let leadGroupKPIObj = salesPersonKPIObj.leadGroups[leadGroupName]
      if (!leadGroupKPIObj) {
        return
      }
      let ownerStorage = null
      //let locationStorage = null
      // let leadGroupStorage = null

      if (dateRangesValidities.isDateRangeValidCurrent) {
        ownerStorage = this.getOwnerStorage(ownerKPIObj.name)

        /* locationStorage = this.tableDataLocationsObj[locationName]
 
         if (!locationStorage) {
           locationStorage = this.tableDataLocationsObj[locationName] = {}
           locationStorage.data = {}
         }*/

        // leadGroupStorage = this.getLeadGroupStorage(leadGroupName, ownerKPIObj.name)
      }

      for (let i = 0; i < this.reportElementProps.length; i++) {
        let prop = this.reportElementProps[i]
        if (dateRangesValidities.isDateRangeValidCurrent) {
          ownerStorage.data[prop] = ownerStorage.data[prop] || 0
          ownerStorage.data[prop] += leadGroupKPIObj.kpiData[prop] || 0

          /*  locationStorage.data[prop] = locationStorage.data[prop] || 0
            locationStorage.data[prop] += leadGroupKPIObj.kpiData[prop]|| 0
  
            leadGroupStorage.data[prop] = leadGroupStorage.data[prop] || 0
            leadGroupStorage.data[prop] += leadGroupKPIObj.kpiData[prop]|| 0*/

          //  this.metrics.rangeCurrent[prop] = this.metrics.rangeCurrent[prop] || 0
          // this.metrics.rangeCurrent[prop] += leadGroupKPIObj.kpiData[prop] || 0
        }

        /* if (dateRangesValidities.isDateRangeValidPOP) {
           this.metrics.rangeSub2[prop] = this.metrics.rangeSub2[prop] || 0
           this.metrics.rangeSub2[prop] += leadGroupKPIObj.kpiData[prop] || 0
         }
 
         if (dateRangesValidities.isDateRangeValidYOY) {
           this.metrics.rangeSub1[prop] = this.metrics.rangeSub1[prop] || 0
           this.metrics.rangeSub1[prop] += leadGroupKPIObj.kpiData[prop] || 0
         }
 
         if (dateRangesValidities.isDateRangeValidTimelineCurrent) {
           this.timeLineData.timelineYearCurrent[dateBeingProcessed.month][prop] =
             this.timeLineData.timelineYearCurrent[dateBeingProcessed.month][prop] || 0
           this.timeLineData.timelineYearCurrent[dateBeingProcessed.month][prop] +=
             leadGroupKPIObj.kpiData[prop] || 0
         }
 
         if (dateRangesValidities.isDateRangeValidTimelinePrevious) {
           this.timeLineData.timelineYearPrevious[dateBeingProcessed.month][prop] =
             this.timeLineData.timelineYearPrevious[dateBeingProcessed.month][prop] || 0
           this.timeLineData.timelineYearPrevious[dateBeingProcessed.month][prop] +=
             leadGroupKPIObj.kpiData[prop] || 0
         }*/
      }
    },

    //-----------------------------------------------------------------

    buildReportProcess5b(
      ownerKPIObj,
      locationName,
      salesPersonName,
      dateBeingProcessed,
      dateRangesValidities
    ) {
      let locationKPIObj = ownerKPIObj.years[dateBeingProcessed.year]
      if (locationKPIObj) {
        locationKPIObj = locationKPIObj.months[dateBeingProcessed.month]
      } else {
        return
      }
      if (locationKPIObj) {
        locationKPIObj = locationKPIObj.days[dateBeingProcessed.day]
      } else {
        return
      }
      if (locationKPIObj) {
        locationKPIObj = locationKPIObj.locations[locationName]
      } else {
        return
      }

      if (!locationKPIObj) {
        return
      }

      let salesPersonKPIObj = locationKPIObj.salesPersons[salesPersonName]
      if (!salesPersonKPIObj) {
        return
      }

      if (this.leadGroupFilter) {
        //console.log(this.leadGroupFilter)
        this.buildReportProcess6(
          ownerKPIObj,
          locationName,
          salesPersonName,
          this.leadGroupFilter,
          dateBeingProcessed,
          dateRangesValidities
        )
      } else {
        for (let leadGroupName in salesPersonKPIObj.leadGroups) {
          this.buildReportProcess6(
            ownerKPIObj,
            locationName,
            salesPersonName,
            leadGroupName,
            dateBeingProcessed,
            dateRangesValidities
          )
        }
      }
    },

    //-------------------------------------------------------------------------------------------------

    buildReportProcess5(ownerKPIObj, locationName, dateBeingProcessed, dateRangesValidities) {
      let locationKPIObj = ownerKPIObj.years[dateBeingProcessed.year]
      if (locationKPIObj) {
        locationKPIObj = locationKPIObj.months[dateBeingProcessed.month]
      } else {
        return
      }
      if (locationKPIObj) {
        locationKPIObj = locationKPIObj.days[dateBeingProcessed.day]
      } else {
        return
      }
      if (locationKPIObj) {
        locationKPIObj = locationKPIObj.locations[locationName]
      } else {
        return
      }

      if (!locationKPIObj) {
        return
      }


      for (let salesPersonName in locationKPIObj.salesPersons) {
        this.buildReportProcess5b(
          ownerKPIObj,
          locationName,
          salesPersonName,
          dateBeingProcessed,
          dateRangesValidities
        )
      }

    },


    buildReportProcess4(ownerName, dataSource, dateBeingProcessed, dateRangesValidities) {
      let ownerKPIObj = dataSource[ownerName]
      //console.log(`buildReportProcess4 ownerName ${ownerName}`)
      if (this.locationFilter) {
        // console.log(this.locationFilter)
        if (ownerKPIObj) {
          this.buildReportProcess5(
            ownerKPIObj,
            this.locationFilter,
            dateBeingProcessed,
            dateRangesValidities
          )
        }
      } else {
        if (ownerKPIObj) {
          let locationRef = ownerKPIObj.years[dateBeingProcessed.year]
          if (locationRef) {
            locationRef = locationRef.months[dateBeingProcessed.month]
          } else {
            return
          }
          if (locationRef) {
            locationRef = locationRef.days[dateBeingProcessed.day]
          } else {
            return
          }
          if (locationRef) {
            locationRef = locationRef.locations
          } else {
            return
          }

          for (let locationName in locationRef) {
            this.buildReportProcess5(
              ownerKPIObj,
              locationName,
              dateBeingProcessed,
              dateRangesValidities
            )
          }
        }
      }
    },

    buildReportProcess3(dateBeingProcessed) {
      let dateRangesValidities = {}
      dateRangesValidities.isDateRangeValidCurrent = true
      dateRangesValidities.isDateRangeValidPOP = true
      dateRangesValidities.isDateRangeValidYOY = true
      dateRangesValidities.isDateRangeValidTimelineCurrent = true
      dateRangesValidities.isDateRangeValidTimelinePrevious = true

      if (dateBeingProcessed > this.reportCore.dateRangeEnd) {
        dateRangesValidities.isDateRangeValidCurrent = false
      }

      if (dateBeingProcessed < this.reportCore.dateRangeStart) {
        dateRangesValidities.isDateRangeValidCurrent = false
      }

      if (dateBeingProcessed > this.reportCore.periodBeforeEnd) {
        dateRangesValidities.isDateRangeValidPOP = false
      }

      if (dateBeingProcessed < this.reportCore.periodBeforeStart) {
        dateRangesValidities.isDateRangeValidPOP = false
      }

      if (dateBeingProcessed > this.reportCore.oneYearBeforeEnd) {
        dateRangesValidities.isDateRangeValidYOY = false
      }

      if (dateBeingProcessed < this.reportCore.oneYearBeforeStart) {
        dateRangesValidities.isDateRangeValidYOY = false
      }
      if (dateBeingProcessed.year === this.reportCore.currentDate.year) {
        dateRangesValidities.isDateRangeValidTimelinePrevious = false
      }
      if (dateBeingProcessed.year === this.reportCore.currentDate.year - 1) {
        dateRangesValidities.isDateRangeValidTimelineCurrent = false
      }

      //------------------------------------------------------------------

      let dataSource = null

      let blendDate = null
      let dataSourceKey = null

      if (this.ownerFilter) {
        if (this.reportCore.useDataSourceOverride) {
          blendDate = this.reportCore.dataBlendDate
          dataSourceKey = this.reportCore.dataSourceCurrent
        } else {
          //get user , get preferred source info , if it exists , otherwise default SM
          let systemOwner = this.reportCore.dataOwners[this.ownerFilter]
          if (systemOwner) {
            blendDate = systemOwner.UserDefaultDataSource.blend_date_sd
            dataSourceKey =
              systemOwner.UserDefaultDataSource.value || this.reportCore.DATA_SOURCE_BLEND
          } else {
            //unmapped owners get system defaults , which are same as overrides
            blendDate = this.reportCore.dataBlendDate
            dataSourceKey = this.reportCore.dataSourceCurrent
          }
        }

        if (dataSourceKey === this.reportCore.DATA_SOURCE_CORE) {
          dataSource = this.dataKPI2Store.processedDataCORE
        }
        if (dataSourceKey === this.reportCore.DATA_SOURCE_SM) {
          dataSource = this.dataKPI2Store.processedDataSM
        }

        if (dataSourceKey === this.reportCore.DATA_SOURCE_BLEND) {
          dataSource = this.dataKPI2Store.processedDataCORE

          if (dateBeingProcessed >= blendDate) {
            dataSource = this.dataKPI2Store.processedDataSM
          }
        }

        this.buildReportProcess4(
          this.ownerFilter,
          dataSource,
          dateBeingProcessed,
          dateRangesValidities
        )

      } else {
        for (let ownerName in this.reportCore.dataOwnersNoAliases) {
          let systemOwner = this.reportCore.dataOwnersNoAliases[ownerName]
          if (this.reportCore.useDataSourceOverride) {
            blendDate = this.reportCore.dataBlendDate
            dataSourceKey = this.reportCore.dataSourceCurrent
          } else {
            blendDate = systemOwner.UserDefaultDataSource.blend_date_sd
            dataSourceKey =
              systemOwner.UserDefaultDataSource.value || this.reportCore.DATA_SOURCE_BLEND
          }
          if (dataSourceKey === this.reportCore.DATA_SOURCE_CORE) {
            dataSource = this.dataKPI2Store.processedDataCORE
          }
          if (dataSourceKey === this.reportCore.DATA_SOURCE_SM) {
            dataSource = this.dataKPI2Store.processedDataSM
          }

          if (dataSourceKey === this.reportCore.DATA_SOURCE_BLEND) {
            dataSource = this.dataKPI2Store.processedDataCORE

            if (dateBeingProcessed >= blendDate) {
              dataSource = this.dataKPI2Store.processedDataSM
            }
          }

          if (dataSource[ownerName]) {
            this.buildReportProcess4(
              ownerName,
              dataSource,
              dateBeingProcessed,
              dateRangesValidities
            )



          } else {
            // console.log(`ownerName ${ownerName} not found in kpi data source ${dataSourceKey}`)
          }
        }

        //once system owners are looped , need to loop kpi sets and process unmapped owners
        //as the above loop will miss them


        if (this.reportCore.useDataSourceOverride) {
          blendDate = this.reportCore.dataBlendDate
          dataSourceKey = this.reportCore.dataSourceCurrent
        } else {
          blendDate = this.reportCore.dataBlendDateUnmapped
          dataSourceKey = this.reportCore.dataSourceUnmapped
        }

        if (dataSourceKey === this.reportCore.DATA_SOURCE_CORE) {
          dataSource = this.dataKPI2Store.processedDataCORE
        }
        if (dataSourceKey === this.reportCore.DATA_SOURCE_SM) {
          dataSource = this.dataKPI2Store.processedDataSM
        }

        if (dataSourceKey === this.reportCore.DATA_SOURCE_BLEND) {
          dataSource = this.dataKPI2Store.processedDataCORE

          if (dateBeingProcessed >= blendDate) {
            dataSource = this.dataKPI2Store.processedDataSM
          }
        }
        for (let ownerName in dataSource) {
          let kpiOwner = dataSource[ownerName]
          if (!kpiOwner.isMapped) {
            this.buildReportProcess4(
              ownerName,
              dataSource,
              dateBeingProcessed,
              dateRangesValidities
            )
          }
          //unmapped owners wont have spend data ..   
          /*
          if (dateBeingProcessed.day === 1) {
            this.buildSpendProcess(ownerName, dateBeingProcessed, dateRangesValidities)
          }*/

        }
      }
    },

    buildReportProcess2() {




      //call getYearMonthDayValuesForKPIRangeProperRange, not getYearMonthDayValuesForKPIRange
      // this gets the range for start to end .. and doesnt include the backdated range used for YOY in other reports 
      let yearMonthDayValues = this.reportCore.getYearMonthDayValuesForKPIRangeProperRange()

      for (let i = 0; i < yearMonthDayValues.length; i++) {
        this.buildReportProcess3(yearMonthDayValues[i])
      }
    },

    async buildReportProcess1() {

      const auth = useAuth()
      //not sure if this is even needed here because data is filtered on the backend based on this permission.. check..?
      if (!auth.hasPermission('read_owner_royalty_percentages_report_all_owners')) {
        this.ownerFilter = auth.user.name
        if (auth.hasRole('staff')) {
          console.log(this.reportCore.dataStaffs)
          this.ownerFilter = this.reportCore.dataStaffs[auth.user.name]?.parentName
        }
      }


      //this sets the start and end for "year to date" 
      this.reportCore.dateYTD()


      this.reportCore.calculateGrowthDateRanges()

      this.tableDataOwnersObj = markRaw({})
      this.tableDataLocationsObj = markRaw({})
      this.tableDataSalesPersonsObj = markRaw({})
      this.tableDataLeadGroupsObj = markRaw({})

      this.metrics = markRaw({})

      this.dataKPI2Store.clearDebugInfo()

      this.buildReportProcess2()
      this.buildReportProcess7()

      let ownersFinal = this.KPIVerboseModel.ownersFinal =  markRaw({})
      for (let ownerKey in this.tableDataOwnersObj) {
        let owner = ownersFinal[ownerKey] = {}
        owner.contacts = {}
        owner.salesSumValue = 0
        owner.salesSumYearlyValue = this.tableDataOwnersObj[ownerKey].data.SALES_SUM
        owner.salesCount = 0

        owner.serviceChangeValue = 0
        owner.serviceChangeCount = 0

        owner.serviceServiceValue = 0
        owner.serviceServiceCount = 0


      }


      //upcoming change .. need to do month selections or royalty range.. depends on the selection from the report..
      if (this.useRoyaltyRange) {
        this.getRoyaltyDateRange()
      } else {
        this.reportCore.dateForMonth(this.dateSelectionMonth)
      }


      this.reportCore.calculateGrowthDateRanges()

      this.tableDataOwnersObj = markRaw({})
      this.tableDataLocationsObj = markRaw({})
      this.tableDataSalesPersonsObj = markRaw({})
      this.tableDataLeadGroupsObj = markRaw({})

      this.metrics = markRaw({})


      this.buildReportProcess2()
      this.buildReportProcess7()


      for (let ownerKey in this.tableDataOwnersObj) {
        let owner = ownersFinal[ownerKey]
        if (owner) {

          owner.salesSumValue = this.tableDataOwnersObj[ownerKey].data.SALES_SUM

          owner.salesCount = this.tableDataOwnersObj[ownerKey].data.SALES_COUNT

        }




      }


      await this.KPIVerboseModel.invalidate({ownerFilter:this.ownerFilter})

    


      

      let tableDataOwners = []

      for (let ownerKey in ownersFinal) {
        let ownerItem = ownersFinal[ownerKey]

        let ownerYearlySalesAmount = ownerItem.salesSumYearlyValue

        let systemOwner = this.reportCore.dataOwners[ownerKey]
        // console.log(systemOwner)
        let ob = {}
        ob.Owner = ownerKey
        ob.Amount = ownerItem.salesSumValue
        ob.Sales = ownerItem.salesCount
        ob.Royalties = 0
        ob.Service = ownerItem.serviceServiceValue
        ob.Change = ownerItem.serviceChangeValue

        if (systemOwner?.OwnerRoyaltyPercentages) {
          let royaltyBracket = systemOwner.OwnerRoyaltyPercentages.find((element) => {
            return element.bracket_start <= ownerYearlySalesAmount && element.bracket_end > ownerYearlySalesAmount
          })
          if (royaltyBracket) {
            ob.Royalties = (ob.Amount + ob.Service + ob.Change) * (royaltyBracket.percent / 100)
          }
        }

        this.metrics.Sales = this.metrics.Sales || 0
        this.metrics.Sales += ob.Sales

        this.metrics.Amount = this.metrics.Amount || 0
        this.metrics.Amount += ob.Amount

        this.metrics.Change = this.metrics.Change || 0
        this.metrics.Change += ob.Change

        this.metrics.Service = this.metrics.Service || 0
        this.metrics.Service += ob.Service

        this.metrics.Royalties = this.metrics.Royalties || 0
        this.metrics.Royalties += ob.Royalties

      

        tableDataOwners.push(ob)
      }

      this.metrics.EffectiveRoyalty =  this.metrics.Royalties/this.metrics.Amount

      this.dataTableOwners.tableData = tableDataOwners

      this.buildScoreCards()


      this.dataReportKPIVerbose.invalidate({queryKey:"KPIVerboseQuery1"},{ownerFilter:this.ownerFilter});

     

      this.reportCore.setLoadHeavy(false)
    },

    buildScoreCards() {
      let scoreCardObject = {}
      scoreCardObject.title = 'Sales'
      scoreCardObject.value = this.metrics.Sales || 0
      scoreCardObject.valueFormat = 'INT'
      scoreCardObject.metricSub1Valid = false
      scoreCardObject.metricSub2Valid = false
      scoreCardObject.valueSub1Valid = false
      scoreCardObject.valueSub2Valid = false
      this.scoreCards.Sales = scoreCardObject

      //--------------------------------------------------------

      scoreCardObject = {}
      scoreCardObject.title = 'Amount'
      scoreCardObject.value = this.metrics.Amount || 0
      scoreCardObject.valueFormat = 'USD'
      scoreCardObject.metricSub1Valid = false
      scoreCardObject.metricSub2Valid = false
      scoreCardObject.valueSub1Valid = false
      scoreCardObject.valueSub2Valid = false
      this.scoreCards.Amount = scoreCardObject

      //--------------------------------------------------------

      scoreCardObject = {}
      scoreCardObject.title = 'Change'
      scoreCardObject.value = this.metrics.Change || 0
      scoreCardObject.valueFormat = 'USD'
      scoreCardObject.metricSub1Valid = false
      scoreCardObject.metricSub2Valid = false
      scoreCardObject.valueSub1Valid = false
      scoreCardObject.valueSub2Valid = false
      this.scoreCards.Change = scoreCardObject

      //--------------------------------------------------------

      scoreCardObject = {}
      scoreCardObject.title = 'Service'
      scoreCardObject.value = this.metrics.Service || 0
      scoreCardObject.valueFormat = 'USD'
      scoreCardObject.metricSub1Valid = false
      scoreCardObject.metricSub2Valid = false
      scoreCardObject.valueSub1Valid = false
      scoreCardObject.valueSub2Valid = false
      this.scoreCards.Service = scoreCardObject

      //--------------------------------------------------------

      scoreCardObject = {}
      scoreCardObject.title = 'Royalties'
      scoreCardObject.value = this.metrics.Royalties || 0
      scoreCardObject.valueFormat = 'USD'
      scoreCardObject.metricSub1Valid = false
      scoreCardObject.metricSub2Valid = false
      scoreCardObject.valueSub1Valid = false
      scoreCardObject.valueSub2Valid = false
      this.scoreCards.Royalties = scoreCardObject

      //--------------------------------------------------------

      scoreCardObject = {}
      scoreCardObject.title = 'Effective Royalty'
      scoreCardObject.value = this.metrics.EffectiveRoyalty || 0
      scoreCardObject.valueFormat = '%'
      scoreCardObject.metricSub1Valid = false
      scoreCardObject.metricSub2Valid = false
      scoreCardObject.valueSub1Valid = false
      scoreCardObject.valueSub2Valid = false
      this.scoreCards.EffectiveRoyalty = scoreCardObject
    },

    doubleRaf(callback, ...args) {
      requestAnimationFrame(() => {
        requestAnimationFrame(() => {
          try {
            callback(...args)
          } catch (error) {
            useErrorHandler().processScriptError(error)
          }
        })
      })
    },

    async buildReport() {
      this.reportCore.setLoadHeavy(true, 'Royalty Report', 'process')
      this.doubleRaf(() => this.buildReportProcess1())
    }
  }
})
