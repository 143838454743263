import { defineStore } from 'pinia'
import { useURLS } from '@/stores/URLS.js'
import { useReportCore } from '@/stores/ReportCore.js'
import { useDataKPI2Store } from '@/stores/DataKPI2Store.js'
import { useDataReportKPIVerbose } from '@/stores/DataReportKPIVerbose.js'
import { useBuildMetricDataTables2 } from '@/scripts/utils/BuildMetricDataTables2.js'
import { useConvertObjectToSortedArray } from '@/scripts/utils/ConvertObjectToSortedArray.js'
import { shallowRef, markRaw, toRaw } from 'vue'

import { useAuth } from '@/stores/Auth.js'

import { useScoreCardBudgetSales } from '@/scripts/utils/ScoreCardBudgetSales.js'
import { useScoreCardBudgetVariance } from '@/scripts/utils/ScoreCardBudgetVariance.js'
import { useScoreCardBudgetOwners } from '@/scripts/utils/ScoreCardBudgetOwners.js'
import { useScoreCardBudgetPredictiveIndex } from '@/scripts/utils/ScoreCardBudgetPredictiveIndex.js'
import { useScoreCardBudgetPredictiveIndexSummed } from '@/scripts/utils/ScoreCardBudgetPredictiveIndexSummed.js'
import { useDataPresetsReportFiltersStore } from '@/stores/DataPresetsReportFiltersStore.js'
import { useErrorHandler } from '@/stores/ErrorHandler.js'
import SimpleDate from '../scripts/utils/SimpleDate'
export const useReportBudget = defineStore('ReportBudget', {
  state: () => {
    return {
      reportElementProps: markRaw(['SALES_SUM']),
      reportElementProps2: markRaw(['SALES_SUM', 'SALES_COUNT', 'APPOINTMENTS_COUNT']),
      dataRawBudgetYears: markRaw([]),
      yearSelectionCurrent: null,
      dataRawBudgetAllocations: markRaw({}),
      reportCore: markRaw(useReportCore()),
      dataPresetsReportFiltersStore:markRaw(useDataPresetsReportFiltersStore()),
      dataKPI2Store: markRaw(useDataKPI2Store()),
      dataBudgetYears: null,
      dataBudgetAllocations: null,
      dataReportKPIVerbose:markRaw(useDataReportKPIVerbose()),

      dataTableOwners: {},
      dataTableGroups: {},
      dataTableLocations: {},

      predictiveIndexSummed30: null,
      predictiveIndexSummed60: null,

      tableDataOwnersObj: null,
      tableDataOwnersBudgetsObj: null,
      tableDataLocationsObj: null,
      tableDataSalesPersonsObj: null,
      tableDataLeadGroupsObj: null,

      metrics: markRaw({}),

      section1Expanded: false,

      ownerTableFilterCurrent: 'all',

      ownerTableFilterValueNEW: 'new',
      ownerTableFilterValueLEGACY: 'legacy',
      ownerTableFilterValueALL: 'all',

      ownersExcludedByStartDate: null,
      ownersExcludedByStartDateButValid: null,

      ownersExcludedByStartDateArray: null,
      ownersExcludedByStartDateButValidArray: null,

      showDebugData: false,

      ownerFilter: null,

      locationFilter: null,
      leadGroupFilter: null,

      scorecardLabelsSales: null,
      scorecardLabelsVariance: null,
      scorecardLabelsOwners: null,
      scorecardLabelsPredictiveIndex: null,

      scoreCards: shallowRef({}),

      ownerSelectiveFilters: markRaw({}),

      tableColumnsMetrics: markRaw([
        'Sales Last Year',
        'Sales Budget',
        'Sales This Year',
        'Budget % Delta',
        'YOY',
        'Predictive Index 30',
        'Predictive Index 60'
      ]),

      timeLineDataObj: null,

      showFocusedChartData: false,

      chartKey: 0,


      chartOptions: null,

      timelineData: {

        labels: [],
        datasets: [
          {
            label: 'Sales Amount',
            fill: false,
            lengendBlockStyle: 'width:20px;height:20px;background-color:#3099ef;',
            borderColor: '#3099ef',
            yAxisID: 'y',
            tension: 0,
            pointRadius: 6,

            data: []
          },
          {
            label: 'Predictive Index 30',
            fill: false,
            lengendBlockStyle: 'width:20px;height:20px;background-color:#ad84aa;',
            borderColor: '#ad84aa',
            backgroundColor: '#ad84aa',
            yAxisID: 'y',
            tension: 0,
            pointRadius: 6,
            type: 'bar',
            data: []
          },
          {
            label: 'Predictive Index 60',
            fill: false,
            lengendBlockStyle: 'width:20px;height:20px;background-color:#6b7887;',
            borderColor: '#6b7887',
            backgroundColor: '#6b7887',
            yAxisID: 'y',
            tension: 0,
            pointRadius: 6,
            type: 'bar',
            data: []
          }

        ]
      }
    }
  },
  actions: {

    setChartOptions() {
      this.chartOptions = {



        stacked: false,
        maintainAspectRatio: false,
        aspectRatio: 0.6,



        interaction: {
          intersect: false,
          axis: 'xy',
          mode: 'index'
        },
        plugins: {
          legend: {
            display: false
          },

          tooltip: {
            animation: false,
            callbacks: {
              label: this.formatLabel
            }
          }
        },

        scales: {
          x: {
            ticks: {
              color: '#ffff00'
            },
            grid: {
              color: '#ff0000'
            }
          },
          y: {
            type: 'linear',
            display: true,
            position: 'left',

            ticks: {
              color: '#ffff00'
            },
            grid: {
              color: '#ff0000'
            }
          }
        }



      }
    },

    formatLabel(tooltipItem, data) {

      return this.reportCore.formatCurrency(tooltipItem.raw);
    },
   

    async refreshQueryCacheKPI($event, key) {
      let asyncProcessSuccessful = false
      asyncProcessSuccessful = await this.dataKPI2Store.refreshQueryCache($event, key)
      if (!asyncProcessSuccessful) return
      asyncProcessSuccessful = await this.loadDataBudgetyears()
      if (!asyncProcessSuccessful) return
      asyncProcessSuccessful = await this.loadDataBudgetAllocation()
      if (!asyncProcessSuccessful) return

      this.processLoadedData()

      this.buildReport()
    },

    async loadAllData() {




      //gets all kpi data
      let asyncProcessSuccessful = false
      asyncProcessSuccessful = await this.dataKPI2Store.loadDataAll()
      if (!asyncProcessSuccessful) return
      asyncProcessSuccessful = await this.loadDataBudgetyears()
      if (!asyncProcessSuccessful) return
      asyncProcessSuccessful = await this.loadDataBudgetAllocation()
      if (!asyncProcessSuccessful) return

      this.processLoadedData()

      this.buildReport()
    },

    processLoadedData() {
      this.ownerFilter = null
      this.locationFilter = null
      this.leadGroupFilter = null

      this.buildOptimizedData()
    },

    buildOptimizedData() {
      let dataRef = (this.dataBudgetYears = markRaw({}))

      let i = this.dataRawBudgetYears.length
      while (i--) {
        let element = this.dataRawBudgetYears[i]
        dataRef[element.year] = element
      }

      //this.dataRawBudgetYears = null

      dataRef = this.dataBudgetAllocations = markRaw({})

      i = this.dataRawBudgetAllocations.length
      let monthStr = [
        'jan',
        'feb',
        'mar',
        'apr',
        'may',
        'jun',
        'jul',
        'aug',
        'sep',
        'oct',
        'nov',
        'dec'
      ]
      let monthStrOb = {}
      for (let j = 0; j < 12; j++) {
        monthStrOb[monthStr[j]] = j
      }
      while (i--) {
        let element = this.dataRawBudgetAllocations[i]

        let yearOb = dataRef[element.year]
        if (!yearOb) {
          yearOb = dataRef[element.year] = {}
        }
        for (let j = 0; j < 12; j++) {
          let monthKey = monthStr[j]
          let monthIndex = monthStrOb[monthKey]

          let yearMonthOb = yearOb[monthIndex]
          if (!yearMonthOb) {
            yearMonthOb = yearOb[monthIndex] = {}
          }
          let monthValue = element[monthKey]
          if (element.fixed_months) {
            //need to calculate
            let percent = this.dataBudgetYears[element.year][monthKey] / 100
            monthValue = element.value * percent
          }
          let userInternal = this.reportCore.dataOwners[element.Owner.User.name.trim()]
          yearMonthOb[userInternal.name] = monthValue
        }
      }
    },

    async loadDataBudgetyears() {
      //get budget data
      this.reportCore.setLoadHeavy(true, 'Budget Years')

      const urls = useURLS()
      var data = {}
      var urlToUse = urls.getURL('budget_sale_report', 'budgetSaleYearsFind')
      try {
        let result = await this.reportCore.loadServerData(urlToUse, data)
        if (result) {
          // console.log(result)
          this.dataRawBudgetYears = markRaw(result)
          this.yearSelectionCurrent = this.dataRawBudgetYears[0]
          this.reportCore.setLoadHeavy(false)
          return true
        }
      } catch (error) {
        console.log(error)
        this.reportCore.setLoadHeavy(false)
        return false
      }
    },

    async loadDataBudgetAllocation() {
      //get budget data
      this.reportCore.setLoadHeavy(true, 'Budget Allocations')
      const urls = useURLS()
      var data = {}
      var urlToUse = urls.getURL('budget_sale_report', 'budgetSaleFind')
      try {
        let result = await this.reportCore.loadServerData(urlToUse, data)
        if (result) {
          // console.log(result)
          this.dataRawBudgetAllocations = markRaw(result)
          this.reportCore.setLoadHeavy(false)
          return true
        }
      } catch (error) {
        console.log(error)
        this.reportCore.setLoadHeavy(false)
        return false
      }
    },

    //-------------------------------------------------------------------------------------------------

    buildReportProcessOwnersExcludedByStartDate(ownerObj) {
      let ownerName = ownerObj.name
      if (!ownerName || ownerName === '' || ownerName === 'null') {
        ownerName = 'empty'
      }

      let debugOwner = (this.ownersExcludedByStartDate[ownerName] = {})
      debugOwner.name = ownerName
      debugOwner.props = {}

      debugOwner.props.dateValue = {
        label: 'started at',
        value: this.reportCore.dataOwners[ownerName].started_at.toString()
      }
    },

    //-------------------------------------------------------------------------------------------------

    buildReportProcessOwnersExcludedByStartDateButValid(ownerObj) {
      let ownerName = ownerObj.name
      if (!ownerName || ownerName === '' || ownerName === 'null') {
        ownerName = 'empty'
      }

      let debugOwner = (this.ownersExcludedByStartDateButValid[ownerName] = {})
      debugOwner.name = ownerName
      debugOwner.props = {}

      debugOwner.props.dateValue = {
        label: 'started at',
        value: this.reportCore.dataOwners[ownerName].started_at.toString()
      }

      debugOwner.props.salesSumValue = {
        label: 'sales sum',
        value: ownerObj.data['SALES_SUM'] || 0
      }
    },

    //-------------------------------------------------------------------------------------------------

    buildReportProcess7() {
      let tableDataOwners = []
      let tableDataLocations = []
      let tableDataLeadGroups = []
      this.predictiveIndexSummed30 = 0
      this.predictiveIndexSummed60 = 0

      let budgetTotal = 0
      for (let ownerName in this.tableDataOwnersObj) {
        let ownerItem = this.tableDataOwnersObj[ownerName]
        ownerItem.typeInvalid = false
        ownerItem.ignore = false
        if (!ownerItem.isMapped) {
          let unmappedItem = this.dataKPI2Store.unmappedKPIOwnersCORE[ownerName]
          let unmappedItem2 = this.dataKPI2Store.unmappedKPIOwnersSM[ownerName]
          if (unmappedItem) {
            unmappedItem.props.sales.value = this.reportCore.formatCurrency(
              ownerItem.data.SALES_SUM || 0
            )
          }
          if (unmappedItem2) {
            unmappedItem2.props.sales.value = this.reportCore.formatCurrency(
              ownerItem.data.SALES_SUM || 0
            )
          }
        } else {
          let systemOwner = this.reportCore.dataOwners[ownerName]

          if (this.ownerTableFilterCurrent !== this.ownerTableFilterValueALL) {
            if (!this.ownerFilter) {
              if (ownerItem.ownerType !== this.ownerTableFilterCurrent) {
                ownerItem.typeInvalid = true
                continue
              }
            }
          }

          let ignore = false
          if (systemOwner.started_at > this.reportCore.dateRangeEnd) {
            ignore = true
          }
          if (ignore) {
            if (ownerItem.data.SALES_SUM > 0) {
              ignore = false
              this.buildReportProcessOwnersExcludedByStartDateButValid(ownerItem)
            }
          }
          if (!ignore) {
            ownerItem.data.OWNER = ownerName

            ownerItem.data.BUDGET = 0
            let ownerBudgetItem = this.tableDataOwnersBudgetsObj[ownerName]
            if (ownerBudgetItem) {

              for (let budgetYearKey in ownerBudgetItem.budgetStore) {
                let budgetYear = ownerBudgetItem.budgetStore[budgetYearKey]
                for (let budgetMonthKey in budgetYear) {
                  let budgetMonth = budgetYear[budgetMonthKey]
                  ownerItem.data.BUDGET += budgetMonth.value
                }
              }

            }



            budgetTotal += ownerItem.data.BUDGET || 0

            ownerItem.data.YOY = this.reportCore.getGrowth(
              ownerItem.data.SALES_SUM_YOY,
              ownerItem.data.SALES_SUM
            )

            let appointmentCount22Temp = ownerItem.dataRolling22.APPOINTMENTS_COUNT || 0
            let appointmentCount30Temp = ownerItem.dataRolling30.APPOINTMENTS_COUNT || 0
            let appointmentCount60Temp = ownerItem.dataRolling60.APPOINTMENTS_COUNT || 0

            let salesSum30Temp = ownerItem.dataRolling30.SALES_SUM || 0
            let salesCount30Temp = ownerItem.dataRolling30.SALES_COUNT || 0

            let salesSum60Temp = ownerItem.dataRolling60.SALES_SUM || 0
            let salesCount60Temp = ownerItem.dataRolling60.SALES_COUNT || 0

            //---------------------------------------------------------

            let estimatedAppointments = (appointmentCount22Temp / 22) * 30

            //----------------------------------------------------------

            let estimatedSoldJobs30 =
              estimatedAppointments * (salesCount30Temp / appointmentCount30Temp)

            let predictiveIndex30 = estimatedSoldJobs30 * (salesSum30Temp / salesCount30Temp)

            this.predictiveIndexSummed30 += predictiveIndex30 || 0

            ownerItem.data.PREDICTIVE_INDEX_30 = predictiveIndex30

            //----------------------------------------------------------

            let estimatedSoldJobs60 =
              estimatedAppointments * (salesCount60Temp / appointmentCount60Temp)

            let predictiveIndex60 = estimatedSoldJobs60 * (salesSum60Temp / salesCount60Temp)

            this.predictiveIndexSummed60 += predictiveIndex60 || 0

            ownerItem.data.PREDICTIVE_INDEX_60 = predictiveIndex60

           
              this.buildMetricsTables(ownerItem.data, tableDataOwners)
          



            //add this data to location data and lead group data
            for (let locationName in ownerItem.locations) {
              let locationRef = ownerItem.locations[locationName]
              let location = this.tableDataLocationsObj[locationName]
              if (!location) {
                location = this.tableDataLocationsObj[locationName] = {}
                location.data = {}
              }
              location.data.BUDGET = ownerItem.data.BUDGET

              for (let leadGroupName in locationRef.leadgroups) {
                let leadGroupRef = locationRef.leadgroups[leadGroupName]
                let leadGroupItem = this.tableDataLeadGroupsObj[leadGroupName]
                if (!leadGroupItem) {
                  leadGroupItem = this.tableDataLeadGroupsObj[leadGroupName] = {}
                  leadGroupItem.data = {}
                  leadGroupItem.dataRolling22 = {}
                  leadGroupItem.dataRolling30 = {}
                  leadGroupItem.dataRolling60 = {}
                }

                if (!leadGroupRef.isMapped) {
                  let unmappedItem = this.dataKPI2Store.unmappedKPILeadGroupsCORE[leadGroupName]
                  let unmappedItem2 = this.dataKPI2Store.unmappedKPILeadGroupsSM[leadGroupName]
                  if (unmappedItem) {
                    unmappedItem.props.sales.value = this.reportCore.formatCurrency(
                      leadGroupItem.data.SALES_SUM || 0
                    )
                  }
                  if (unmappedItem2) {
                    unmappedItem2.props.sales.value = this.reportCore.formatCurrency(
                      leadGroupItem.data.SALES_SUM || 0
                    )
                  }
                } else {
                  for (let prop in leadGroupRef.data) {
                    location.data[prop] = location.data[prop] || 0
                    location.data[prop] += leadGroupRef.data[prop] || 0

                    leadGroupItem.data[prop] = leadGroupItem.data[prop] || 0
                    leadGroupItem.data[prop] += leadGroupRef.data[prop] || 0
                  }

                  for (let prop in leadGroupRef.dataRolling22) {
                    leadGroupItem.dataRolling22[prop] = leadGroupItem.dataRolling22[prop] || 0
                    leadGroupItem.dataRolling22[prop] += leadGroupRef.dataRolling22[prop] || 0
                  }

                  for (let prop in leadGroupRef.dataRolling30) {
                    leadGroupItem.dataRolling30[prop] = leadGroupItem.dataRolling30[prop] || 0
                    leadGroupItem.dataRolling30[prop] += leadGroupRef.dataRolling30[prop] || 0
                  }

                  for (let prop in leadGroupRef.dataRolling60) {
                    leadGroupItem.dataRolling60[prop] = leadGroupItem.dataRolling60[prop] || 0
                    leadGroupItem.dataRolling60[prop] += leadGroupRef.dataRolling60[prop] || 0
                  }
                }
              }
            }
          } else {
            this.buildReportProcessOwnersExcludedByStartDate(ownerItem)
          }
          ownerItem.ignore = ignore
        }
      }

      for (let locationName in this.tableDataLocationsObj) {
        let locationItem = this.tableDataLocationsObj[locationName]

        locationItem.data.LOCATION = locationName
        locationItem.data.YOY = this.reportCore.getGrowth(
          locationItem.data.SALES_SUM_YOY,
          locationItem.data.SALES_SUM
        )

        this.buildMetricsTables(locationItem.data, tableDataLocations)
      }

      for (let leadGroupName in this.tableDataLeadGroupsObj) {
        let leadGroupItem = this.tableDataLeadGroupsObj[leadGroupName]

        leadGroupItem.data.LEAD_GROUP = leadGroupName
        leadGroupItem.data.BUDGET = budgetTotal
        leadGroupItem.data.YOY = this.reportCore.getGrowth(
          leadGroupItem.data.SALES_SUM_YOY,
          leadGroupItem.data.SALES_SUM
        )

        let appointmentCount22Temp = leadGroupItem.dataRolling22.APPOINTMENTS_COUNT || 0

        let appointmentCount30Temp = leadGroupItem.dataRolling30.APPOINTMENTS_COUNT || 0
        let salesSum30Temp = leadGroupItem.dataRolling30.SALES_SUM || 0
        let salesCount30Temp = leadGroupItem.dataRolling30.SALES_COUNT || 0

        let appointmentCount60Temp = leadGroupItem.dataRolling60.APPOINTMENTS_COUNT || 0
        let salesSum60Temp = leadGroupItem.dataRolling60.SALES_SUM || 0
        let salesCount60Temp = leadGroupItem.dataRolling60.SALES_COUNT || 0

        //--------------------------------------------------------------

        let estimatedAppointments = (appointmentCount22Temp / 22) * 30

        //--------------------------------------------------------------

        let estimatedSoldJobs30 =
          estimatedAppointments * (salesCount30Temp / appointmentCount30Temp)

        let predictiveIndex30 = estimatedSoldJobs30 * (salesSum30Temp / salesCount30Temp)

        leadGroupItem.data.PREDICTIVE_INDEX_30 = predictiveIndex30

        //--------------------------------------------------------------

        let estimatedSoldJobs60 =
          estimatedAppointments * (salesCount60Temp / appointmentCount60Temp)

        let predictiveIndex60 = estimatedSoldJobs60 * (salesSum60Temp / salesCount60Temp)

        leadGroupItem.data.PREDICTIVE_INDEX_60 = predictiveIndex60

        //--------------------------------------------------------------

        this.buildMetricsTables(leadGroupItem.data, tableDataLeadGroups)
      }

      this.dataTableOwners.tableData = tableDataOwners
      this.dataTableLocations.tableData = tableDataLocations
      this.dataTableGroups.tableData = tableDataLeadGroups

      this.buildMetricsTimeline()

      this.buildScoreCards(this.metrics)

      //convert some objects to sorted arrays for better ordered display

      this.ownersExcludedByStartDateArray = useConvertObjectToSortedArray(
        this.ownersExcludedByStartDate
      )
      this.ownersExcludedByStartDateButValidArray = useConvertObjectToSortedArray(
        this.ownersExcludedByStartDateButValid
      )
    },

    buildMetricsTimeline() {
      // console.log(this.timelineData)
      this.timelineData.datasets[0].data = []
      this.timelineData.datasets[1].data = []
      this.timelineData.datasets[2].data = []
      this.timelineData.labels = []

      let SALES_SUM_REFERENCE = 0;

      let maxValue = -Infinity;
      let minValue = Infinity



      /*
      this.timelineSingleMetricSet1 = this.timeLineDataObj
      this.timelineSingleMetricSet2 = this.timeLineDataObj
      this.timelineSingleMetricSet3 = this.timeLineDataObj*/

      //no month is a year with more htan 31 days so hard coded loop is perfect here to cover all posible keys
      //also to ensure order , otherwise i would have to convert timelinedata to a sorted array etc.
      for (let i = 0; i < 31; i++) {
        let timeLineDay = this.timeLineDataObj[i + 1]
        if (timeLineDay) {
          this.timelineData.labels.push(i + 1 + '')

          let appointmentCount22Temp = timeLineDay.dataRolling22.APPOINTMENTS_COUNT || 0

          let appointmentCount30Temp = timeLineDay.dataRolling30.APPOINTMENTS_COUNT || 0
          let salesSum30Temp = timeLineDay.dataRolling30.SALES_SUM || 0
          let salesCount30Temp = timeLineDay.dataRolling30.SALES_COUNT || 0

          let appointmentCount60Temp = timeLineDay.dataRolling60.APPOINTMENTS_COUNT || 0
          let salesSum60Temp = timeLineDay.dataRolling60.SALES_SUM || 0
          let salesCount60Temp = timeLineDay.dataRolling60.SALES_COUNT || 0

          //--------------------------------------------------------------

          let estimatedAppointments = (appointmentCount22Temp / 22) * 30

          //--------------------------------------------------------------

          let estimatedSoldJobs30 =
            estimatedAppointments * (salesCount30Temp / appointmentCount30Temp)

          let predictiveIndex30 = estimatedSoldJobs30 * (salesSum30Temp / salesCount30Temp)

          this.timelineData.datasets[1].data.push(predictiveIndex30)

          maxValue = Math.max(maxValue, predictiveIndex30)
          minValue = Math.min(minValue, predictiveIndex30)

          //--------------------------------------------------------------

          let estimatedSoldJobs60 =
            estimatedAppointments * (salesCount60Temp / appointmentCount60Temp)

          let predictiveIndex60 = estimatedSoldJobs60 * (salesSum60Temp / salesCount60Temp)

          this.timelineData.datasets[2].data.push(predictiveIndex60)
          maxValue = Math.max(maxValue, predictiveIndex60)
          minValue = Math.min(minValue, predictiveIndex60)

          SALES_SUM_REFERENCE += timeLineDay.data.SALES_SUM || 0

        }

      }

      for (let i = 0; i < 31; i++) {
        let timeLineDay = this.timeLineDataObj[i + 1]
        if (timeLineDay) {
          this.timelineData.datasets[0].data.push(SALES_SUM_REFERENCE)

        }
      }

      maxValue = Math.max(maxValue, SALES_SUM_REFERENCE)
      minValue = Math.min(minValue, SALES_SUM_REFERENCE)

      let diff = Math.abs(maxValue - minValue)
      let diff10Percent = diff * 0.1
      maxValue += diff10Percent
      minValue -= diff10Percent
      this.chartSuggestedYMax = maxValue | 0
      this.chartYMin = minValue | 0
      if (this.showFocusedChartData) {
        this.chartOptions.scales.y.suggestedMax = this.chartSuggestedYMax
        this.chartOptions.scales.y.min = this.chartYMin
      }

      this.chartOptions.scales.x.ticks.color = this.reportCore.textColorSecondary
      this.chartOptions.scales.x.grid.color = this.reportCore.surfaceBorder

      this.chartOptions.scales.y.ticks.color = this.reportCore.textColorSecondary
      this.chartOptions.scales.y.grid.color = this.reportCore.surfaceBorder




    },

    updateChartFocusMode(val) {

      if (val) {
        this.chartOptions.scales.y.suggestedMax = this.chartSuggestedYMax
        this.chartOptions.scales.y.min = this.chartYMin
      } else {
        this.chartOptions.scales.y.suggestedMax = 0
        this.chartOptions.scales.y.min = 0
      }
      this.chartKey++;
    },

    //-------------------------------------------------------------------------------------------------

    getLeadGroupStorage(ownerName, locationName, leadGroupName) {
      let ownerStorage = this.getOwnerStorage(ownerName)
      let locationStorage = ownerStorage.locations[locationName]
      if (!locationStorage.leadgroups) {
        locationStorage.leadgroups = {}
      }

      let leadGroupStorage = locationStorage.leadgroups[leadGroupName]

      if (!leadGroupStorage) {
        leadGroupStorage = locationStorage.leadgroups[leadGroupName] = {}
        let systemLeadGroup = this.reportCore.dataLeadGroups[leadGroupName]
        let isMapped = true
        if (!systemLeadGroup) {
          isMapped = false
        }

        leadGroupStorage.isMapped = isMapped
        leadGroupStorage.name = leadGroupName
        leadGroupStorage.ownerName = ownerName
        leadGroupStorage.ownerType = ownerStorage.ownerType
        leadGroupStorage.data = {}
        leadGroupStorage.dataRolling22 = {}
        leadGroupStorage.dataRolling30 = {}
        leadGroupStorage.dataRolling60 = {}
      }

      return leadGroupStorage
    },

    getLocationStorage(ownerName, locationName) {
      let ownerStorage = this.getOwnerStorage(ownerName)
      if (!ownerStorage.locations) {
        ownerStorage.locations = {}
      }

      let locationStorage = ownerStorage.locations[locationName]

      if (!locationStorage) {
        locationStorage = ownerStorage.locations[locationName] = {}
        let systemLocation = this.reportCore.dataLocations[locationName]
        let isMapped = true
        if (!systemLocation) {
          isMapped = false
        }

        locationStorage.isMapped = isMapped
        locationStorage.name = locationName
        locationStorage.ownerName = ownerName
        locationStorage.ownerType = ownerStorage.ownerType
        locationStorage.data = {}
      }

      return locationStorage
    },

    getOwnerStorage(ownerName) {
      let ownerStorage = this.tableDataOwnersObj[ownerName]
      if (!ownerStorage) {
        ownerStorage = this.tableDataOwnersObj[ownerName] = {}
        let systemOwner = this.reportCore.dataOwners[ownerName]

        let isMapped = true
        let ownerType = null
        if (!systemOwner) {
          isMapped = false
        } else {
          if (systemOwner.started_at.year !== this.reportCore.currentDate.year) {
            ownerType = 'legacy'
          }

          if (systemOwner.started_at.year === this.reportCore.currentDate.year) {
            ownerType = 'new'
          }
        }
        
        ownerStorage.isMapped = isMapped
        ownerStorage.ownerType = ownerType
        ownerStorage.name = ownerName
        ownerStorage.data = {}
        ownerStorage.dataRolling22 = {}
        ownerStorage.dataRolling30 = {}
        ownerStorage.dataRolling60 = {}
      }


      return ownerStorage
    },

    validateDimensionFiltering(ownerName, locationName, leadGroupName) {
      let valid = true
      if (this.dataPresetsReportFiltersStore.useDimensionFilters) {
        if (this.dataPresetsReportFiltersStore.getPresetData.useDimensionFilterOwner) {
          let systemOwner = this.dataPresetsReportFiltersStore.getPresetData.dataOwnersNoAliases[ownerName]
          if (systemOwner) {
            let selected = systemOwner.selected
            if (this.dataPresetsReportFiltersStore.getPresetData.isInclusive) {
              if (!selected) {
                return
              }
            }
            if (this.dataPresetsReportFiltersStore.getPresetData.isExclusive) {
              if (selected) {
                return
              }
            }
          } else {
            //if useDimensionFilters is true and no system owner , i have to ignore the owner as filtering will possibly fail
            return
          }
        }

        

        //-------------------------------------

        if (this.dataPresetsReportFiltersStore.getPresetData.useDimensionFilterLeadGroup) {
          let systemLeadGroup = this.dataPresetsReportFiltersStore.getPresetData.dataLeadGroupsNoAliases[leadGroupName]
          if (systemLeadGroup) {
           
            let selected = systemLeadGroup.selected
            if (this.dataPresetsReportFiltersStore.getPresetData.isInclusive) {
              if (!selected) {
                return
              }
            }
            if (this.dataPresetsReportFiltersStore.getPresetData.isExclusive) {
              if (selected) {
                return
              }
            }
          } else {
          
            //if useDimensionFilters is true and no system leadGroup , i have to ignore the leadGroup as filtering will possibly fail
            return
          }
        }
      }

      return valid
    },

    buildBudgetProcess(ownerName, dateBeingProcessed, dateRangesValidities) {
      const budgetDataYear = this.dataBudgetAllocations[dateBeingProcessed.year]
      const budgetDataMonth = budgetDataYear?.[dateBeingProcessed.month]
      const budgetDataOwner = budgetDataMonth?.[ownerName]

      if (!this.validateDimensionFiltering(ownerName, null, null)) {
        return
      }

      if (budgetDataOwner !== null && budgetDataOwner !== undefined) {

        let ownerStorage = this.tableDataOwnersBudgetsObj[ownerName]
        if (!ownerStorage) {
          ownerStorage = this.tableDataOwnersBudgetsObj[ownerName] = {}
          let systemOwner = this.reportCore.dataOwners[ownerName]
          if (systemOwner) {

            if (systemOwner.started_at.year !== this.reportCore.currentDate.year) {
              ownerStorage.ownerType = 'legacy'
            }

            if (systemOwner.started_at.year === this.reportCore.currentDate.year) {
              ownerStorage.ownerType = 'new'
            }

            
            if (systemOwner.started_at > this.reportCore.dateRangeEnd) {
              ownerStorage.ignore = true
            }else{
              ownerStorage.ignore = false
            }

          }
          ownerStorage.budgetStore = {}

         
        }



        if (dateRangesValidities.isDateRangeValidCurrent && dateBeingProcessed.day === 1) {
          let budgetStore = ownerStorage.budgetStore;

          let budgetStoreYear = budgetStore[dateBeingProcessed.year];
          if (!budgetStoreYear) {
            budgetStoreYear = budgetStore[dateBeingProcessed.year] = {};
          }

          let budgetStoreMonth = budgetStoreYear[dateBeingProcessed.month];
          if (!budgetStoreMonth) {
            budgetStoreMonth = budgetStoreYear[dateBeingProcessed.month] = {};
          }

          budgetStoreMonth.value = budgetDataOwner || 0;

        }


      }
    },

    buildReportProcess6(
      ownerKPIObj,
      locationName,
      salesPersonName,
      leadGroupName,
      dateBeingProcessed,
      dateRangesValidities
    ) {
     
      if (!this.validateDimensionFiltering(ownerKPIObj.name, locationName, leadGroupName)) {
        return
      }
      let locationKPIObj =
        ownerKPIObj.years[dateBeingProcessed.year].months[dateBeingProcessed.month].days[
          dateBeingProcessed.day
        ].locations[locationName]

        let salesPersonKPIObj = locationKPIObj.salesPersons[salesPersonName]


        let leadGroupKPIObj = salesPersonKPIObj.leadGroups[leadGroupName]
      if (!leadGroupKPIObj) {
        return
      }
      let ownerStorage = null
      let locationStorage = null
      let leadGroupStorage = null

     

      if (
        dateRangesValidities.isDateRangeValidCurrent ||
        dateRangesValidities.isDateRangeValidYOY ||
        dateRangesValidities.isDateRangeValidRolling22 ||
        dateRangesValidities.isDateRangeValidRolling60
      ) {
        ownerStorage = this.getOwnerStorage(ownerKPIObj.name)

        locationStorage = this.getLocationStorage(ownerKPIObj.name, locationName)

        leadGroupStorage = this.getLeadGroupStorage(ownerKPIObj.name, locationName, leadGroupName)
      }

      for (let i = 0; i < this.reportElementProps.length; i++) {
        let prop = this.reportElementProps[i]
        if (dateRangesValidities.isDateRangeValidCurrent) {
          ownerStorage.data[prop] = ownerStorage.data[prop] || 0
          ownerStorage.data[prop] += leadGroupKPIObj.kpiData[prop] || 0

          locationStorage.data[prop] = locationStorage.data[prop] || 0
          locationStorage.data[prop] += leadGroupKPIObj.kpiData[prop] || 0

          leadGroupStorage.data[prop] = leadGroupStorage.data[prop] || 0
          leadGroupStorage.data[prop] += leadGroupKPIObj.kpiData[prop] || 0

          this.metrics.rangeCurrent[prop] = this.metrics.rangeCurrent[prop] || 0
          this.metrics.rangeCurrent[prop] += leadGroupKPIObj.kpiData[prop] || 0

          let timeLineDay = this.timeLineDataObj[dateBeingProcessed.day]
          if (timeLineDay) {
            timeLineDay.data[prop] = timeLineDay.data[prop] || 0
            timeLineDay.data[prop] += leadGroupKPIObj.kpiData[prop] || 0
          }
        }

        if (dateRangesValidities.isDateRangeValidPOP) {
          this.metrics.rangeSub2[prop] = this.metrics.rangeSub2[prop] || 0
          this.metrics.rangeSub2[prop] += leadGroupKPIObj.kpiData[prop] || 0
        }

        if (dateRangesValidities.isDateRangeValidYOY) {
          this.metrics.rangeSub1[prop] = this.metrics.rangeSub1[prop] || 0
          this.metrics.rangeSub1[prop] += leadGroupKPIObj.kpiData[prop] || 0
        }
      }

      if (
        dateRangesValidities.isDateRangeValidRolling22 ||
        dateRangesValidities.isDateRangeValidRolling30 ||
        dateRangesValidities.isDateRangeValidRolling60
      ) {
        for (let i = 0; i < this.reportElementProps2.length; i++) {
          let prop = this.reportElementProps2[i]
          if (dateRangesValidities.isDateRangeValidRolling22) {
            ownerStorage.dataRolling22[prop] = ownerStorage.dataRolling22[prop] || 0
            ownerStorage.dataRolling22[prop] += leadGroupKPIObj.kpiData[prop] || 0

            leadGroupStorage.dataRolling22[prop] = leadGroupStorage.dataRolling22[prop] || 0
            leadGroupStorage.dataRolling22[prop] += leadGroupKPIObj.kpiData[prop] || 0
          }
          if (dateRangesValidities.isDateRangeValidRolling30) {
            ownerStorage.dataRolling30[prop] = ownerStorage.dataRolling30[prop] || 0
            ownerStorage.dataRolling30[prop] += leadGroupKPIObj.kpiData[prop] || 0

            leadGroupStorage.dataRolling30[prop] = leadGroupStorage.dataRolling30[prop] || 0
            leadGroupStorage.dataRolling30[prop] += leadGroupKPIObj.kpiData[prop] || 0
          }

          if (dateRangesValidities.isDateRangeValidRolling60) {
            ownerStorage.dataRolling60[prop] = ownerStorage.dataRolling60[prop] || 0
            ownerStorage.dataRolling60[prop] += leadGroupKPIObj.kpiData[prop] || 0

            leadGroupStorage.dataRolling60[prop] = leadGroupStorage.dataRolling60[prop] || 0
            leadGroupStorage.dataRolling60[prop] += leadGroupKPIObj.kpiData[prop] || 0
          }
        }
      }

      for (let timeLineDayKey in this.timeLineDataObj) {
        let timeLineDay = this.timeLineDataObj[timeLineDayKey]
        //the above method of finding reference doesnt account for the month or year , its a loose match
        //now validate the dates for exact match
        let isDateRangeValidRolling22 = true
        let isDateRangeValidRolling30 = true
        let isDateRangeValidRolling60 = true
        if (dateBeingProcessed < timeLineDay.rolling22Start) {
          isDateRangeValidRolling22 = false
        }
        if (dateBeingProcessed > timeLineDay.rolling22End) {
          isDateRangeValidRolling22 = false
        }

        if (dateBeingProcessed < timeLineDay.rolling30Start) {
          isDateRangeValidRolling30 = false
        }
        if (dateBeingProcessed > timeLineDay.rolling30End) {
          isDateRangeValidRolling30 = false
        }

        if (dateBeingProcessed < timeLineDay.rolling60Start) {
          isDateRangeValidRolling60 = false
        }
        if (dateBeingProcessed > timeLineDay.rolling60End) {
          isDateRangeValidRolling60 = false
        }

        if (isDateRangeValidRolling22 || isDateRangeValidRolling30 || isDateRangeValidRolling60) {
          for (let i = 0; i < this.reportElementProps2.length; i++) {
            let prop = this.reportElementProps2[i]
            if (isDateRangeValidRolling22) {
              timeLineDay.dataRolling22[prop] = timeLineDay.dataRolling22[prop] || 0
              timeLineDay.dataRolling22[prop] += leadGroupKPIObj.kpiData[prop] || 0
            }
            if (isDateRangeValidRolling30) {
              timeLineDay.dataRolling30[prop] = timeLineDay.dataRolling30[prop] || 0
              timeLineDay.dataRolling30[prop] += leadGroupKPIObj.kpiData[prop] || 0
            }

            if (isDateRangeValidRolling60) {
              timeLineDay.dataRolling60[prop] = timeLineDay.dataRolling60[prop] || 0
              timeLineDay.dataRolling60[prop] += leadGroupKPIObj.kpiData[prop] || 0
            }
          }
        }

      }





      //custom props
      //SALES_SUM_YOY
      if (dateRangesValidities.isDateRangeValidYOY) {
        ownerStorage.data.SALES_SUM_YOY = ownerStorage.data.SALES_SUM_YOY || 0
        ownerStorage.data.SALES_SUM_YOY += leadGroupKPIObj.kpiData.SALES_SUM || 0

        locationStorage.data.SALES_SUM_YOY = locationStorage.data.SALES_SUM_YOY || 0
        locationStorage.data.SALES_SUM_YOY += leadGroupKPIObj.kpiData.SALES_SUM || 0

        leadGroupStorage.data.SALES_SUM_YOY = leadGroupStorage.data.SALES_SUM_YOY || 0
        leadGroupStorage.data.SALES_SUM_YOY += leadGroupKPIObj.kpiData.SALES_SUM || 0
      }





    },

    //----------------------------------------------------------------------------------

    buildReportProcess5b(
      ownerKPIObj,
      locationName,
      salesPersonName,
      dateBeingProcessed,
      dateRangesValidities
    ) {
      let locationKPIObj = ownerKPIObj.years[dateBeingProcessed.year]
      if (locationKPIObj) {
        locationKPIObj = locationKPIObj.months[dateBeingProcessed.month]
      } else {
        return
      }
      if (locationKPIObj) {
        locationKPIObj = locationKPIObj.days[dateBeingProcessed.day]
      } else {
        return
      }
      if (locationKPIObj) {
        locationKPIObj = locationKPIObj.locations[locationName]
      } else {
        return
      }

      if (!locationKPIObj) {
        return
      }

      let salesPersonKPIObj = locationKPIObj.salesPersons[salesPersonName]
      if (!salesPersonKPIObj) {
        return
      }

      if (this.leadGroupFilter) {
        //console.log(this.leadGroupFilter)
        this.buildReportProcess6(
          ownerKPIObj,
          locationName,
          salesPersonName,
          this.leadGroupFilter,
          dateBeingProcessed,
          dateRangesValidities
        )
      } else {
        for (let leadGroupName in salesPersonKPIObj.leadGroups) {
          this.buildReportProcess6(
            ownerKPIObj,
            locationName,
            salesPersonName,
            leadGroupName,
            dateBeingProcessed,
            dateRangesValidities
          )
        }
      }
    },


    //-------------------------------------------------------------------------------------------------

    buildReportProcess5(ownerKPIObj, locationName, dateBeingProcessed, dateRangesValidities) {
      let locationKPIObj = ownerKPIObj.years[dateBeingProcessed.year]
      if (locationKPIObj) {
        locationKPIObj = locationKPIObj.months[dateBeingProcessed.month]
      } else {
        return
      }
      if (locationKPIObj) {
        locationKPIObj = locationKPIObj.days[dateBeingProcessed.day]
      } else {
        return
      }
      if (locationKPIObj) {
        locationKPIObj = locationKPIObj.locations[locationName]
      } else {
        return
      }

      if (!locationKPIObj) {
        return
      }

      for (let salesPersonName in locationKPIObj.salesPersons) {
        this.buildReportProcess5b(
          ownerKPIObj,
          locationName,
          salesPersonName,
          dateBeingProcessed,
          dateRangesValidities
        )
      }
    },

    buildReportProcess4(ownerName, dataSource, dateBeingProcessed, dateRangesValidities) {
      let ownerKPIObj = dataSource[ownerName]
      //console.log(`buildReportProcess4 ownerName ${ownerName}`)
      if (this.locationFilter) {
        // console.log(this.locationFilter)
        if (ownerKPIObj) {
          this.buildReportProcess5(
            ownerKPIObj,
            this.locationFilter,
            dateBeingProcessed,
            dateRangesValidities
          )
        }
      } else {
        if (ownerKPIObj) {
          let locationRef = ownerKPIObj.years[dateBeingProcessed.year]
          if (locationRef) {
            locationRef = locationRef.months[dateBeingProcessed.month]
          } else {
            return
          }
          if (locationRef) {
            locationRef = locationRef.days[dateBeingProcessed.day]
          } else {
            return
          }
          if (locationRef) {
            locationRef = locationRef.locations
          } else {
            return
          }

          for (let locationName in locationRef) {
            this.buildReportProcess5(
              ownerKPIObj,
              locationName,
              dateBeingProcessed,
              dateRangesValidities
            )
          }
        }
      }
    },

    //-------------------------------------------------------------------------------------------------

    buildReportProcess3(dateBeingProcessed) {
      let dateRangesValidities = {}
      dateRangesValidities.isDateRangeValidCurrent = true
      dateRangesValidities.isDateRangeValidPOP = true
      dateRangesValidities.isDateRangeValidYOY = true
      dateRangesValidities.isDateRangeValidTimelineCurrent = true
      dateRangesValidities.isDateRangeValidTimelinePrevious = true

      dateRangesValidities.isDateRangeValidRolling22 = true
      dateRangesValidities.isDateRangeValidRolling30 = true
      dateRangesValidities.isDateRangeValidRolling60 = true

      if (dateBeingProcessed > this.reportCore.dateRangeEnd) {
        dateRangesValidities.isDateRangeValidCurrent = false
      }

      if (dateBeingProcessed < this.reportCore.dateRangeStart) {
        dateRangesValidities.isDateRangeValidCurrent = false
      }

      if (dateBeingProcessed > this.reportCore.periodBeforeEnd) {
        dateRangesValidities.isDateRangeValidPOP = false
      }

      if (dateBeingProcessed < this.reportCore.periodBeforeStart) {
        dateRangesValidities.isDateRangeValidPOP = false
      }

      if (dateBeingProcessed > this.reportCore.oneYearBeforeEnd) {
        dateRangesValidities.isDateRangeValidYOY = false
      }

      if (dateBeingProcessed < this.reportCore.oneYearBeforeStart) {
        dateRangesValidities.isDateRangeValidYOY = false
      }
      if (dateBeingProcessed.year === this.reportCore.currentDate.year) {
        dateRangesValidities.isDateRangeValidTimelinePrevious = false
      }
      if (dateBeingProcessed.year === this.reportCore.currentDate.year - 1) {
        dateRangesValidities.isDateRangeValidTimelineCurrent = false
      }

      if (dateBeingProcessed > this.reportCore.dateRangeEnd) {
        dateRangesValidities.isDateRangeValidCurrent = false
      }

      if (dateBeingProcessed < this.reportCore.rolling22Start) {
        dateRangesValidities.isDateRangeValidRolling22 = false
      }
      if (dateBeingProcessed > this.reportCore.rolling22End) {
        dateRangesValidities.isDateRangeValidRolling22 = false
      }

      if (dateBeingProcessed < this.reportCore.rolling30Start) {
        dateRangesValidities.isDateRangeValidRolling30 = false
      }
      if (dateBeingProcessed > this.reportCore.rolling30End) {
        dateRangesValidities.isDateRangeValidRolling30 = false
      }

      if (dateBeingProcessed < this.reportCore.rolling60Start) {
        dateRangesValidities.isDateRangeValidRolling60 = false
      }
      if (dateBeingProcessed > this.reportCore.rolling60End) {
        dateRangesValidities.isDateRangeValidRolling60 = false
      }

      //------------------------------------------------------------------

      let dataSource = null

      let blendDate = null
      let dataSourceKey = null

      if (this.ownerFilter) {
        if (this.reportCore.useDataSourceOverride) {
          blendDate = this.reportCore.dataBlendDate
          dataSourceKey = this.reportCore.dataSourceCurrent
        } else {
          //get user , get preferred source info , if it exists , otherwise default SM
          let systemOwner = this.reportCore.dataOwners[this.ownerFilter]
          if (systemOwner) {
            blendDate = systemOwner.UserDefaultDataSource.blend_date_sd
            dataSourceKey =
              systemOwner.UserDefaultDataSource.value || this.reportCore.DATA_SOURCE_BLEND
          } else {
            //unmapped owners get system defaults , which are same as overrides
            blendDate = this.reportCore.dataBlendDate
            dataSourceKey = this.reportCore.dataSourceCurrent
          }
        }

        if (dataSourceKey === this.reportCore.DATA_SOURCE_CORE) {
          dataSource = this.dataKPI2Store.processedDataCORE
        }
        if (dataSourceKey === this.reportCore.DATA_SOURCE_SM) {
          dataSource = this.dataKPI2Store.processedDataSM
        }

        if (dataSourceKey === this.reportCore.DATA_SOURCE_BLEND) {
          dataSource = this.dataKPI2Store.processedDataCORE

          if (dateBeingProcessed >= blendDate) {
            dataSource = this.dataKPI2Store.processedDataSM
          }
        }
        this.buildBudgetProcess(this.ownerFilter, dateBeingProcessed, dateRangesValidities)

        this.buildReportProcess4(
          this.ownerFilter,
          dataSource,
          dateBeingProcessed,
          dateRangesValidities
        )

      } else {
        for (let ownerName in this.reportCore.dataOwnersNoAliases) {
          let systemOwner = this.reportCore.dataOwnersNoAliases[ownerName]
          if (this.reportCore.useDataSourceOverride) {
            blendDate = this.reportCore.dataBlendDate
            dataSourceKey = this.reportCore.dataSourceCurrent
          } else {
            blendDate = systemOwner.UserDefaultDataSource.blend_date_sd
            dataSourceKey =
              systemOwner.UserDefaultDataSource.value || this.reportCore.DATA_SOURCE_BLEND
          }
          if (dataSourceKey === this.reportCore.DATA_SOURCE_CORE) {
            dataSource = this.dataKPI2Store.processedDataCORE
          }
          if (dataSourceKey === this.reportCore.DATA_SOURCE_SM) {
            dataSource = this.dataKPI2Store.processedDataSM
          }

          if (dataSourceKey === this.reportCore.DATA_SOURCE_BLEND) {
            dataSource = this.dataKPI2Store.processedDataCORE

            if (dateBeingProcessed >= blendDate) {
              dataSource = this.dataKPI2Store.processedDataSM
            }
          }
          
          this.buildBudgetProcess(ownerName, dateBeingProcessed, dateRangesValidities)

          if (dataSource[ownerName]) {
            this.buildReportProcess4(
              ownerName,
              dataSource,
              dateBeingProcessed,
              dateRangesValidities
            )

          }
        }

        //once system owners are looped , need to loop kpi sets and process unmapped owners
        //as the above loop will miss them

        if (this.reportCore.useDataSourceOverride) {
          blendDate = this.reportCore.dataBlendDate
          dataSourceKey = this.reportCore.dataSourceCurrent
        } else {
          blendDate = this.reportCore.dataBlendDateUnmapped
          dataSourceKey = this.reportCore.dataSourceUnmapped
        }

        if (dataSourceKey === this.reportCore.DATA_SOURCE_CORE) {
          dataSource = this.dataKPI2Store.processedDataCORE
        }
        if (dataSourceKey === this.reportCore.DATA_SOURCE_SM) {
          dataSource = this.dataKPI2Store.processedDataSM
        }

        if (dataSourceKey === this.reportCore.DATA_SOURCE_BLEND) {
          dataSource = this.dataKPI2Store.processedDataCORE

          if (dateBeingProcessed >= blendDate) {
            dataSource = this.dataKPI2Store.processedDataSM
          }
        }
        for (let ownerName in dataSource) {
          let kpiOwner = dataSource[ownerName]
          if (!kpiOwner.isMapped) {

            this.buildReportProcess4(
              ownerName,
              dataSource,
              dateBeingProcessed,
              dateRangesValidities
            )

          }
        }
      }
    },

    //-------------------------------------------------------------------------------------------------

    buildReportProcess2() {
      //year/month values to loop
      //you always need all months from the curent year and previous year because of the timeline on the report
      //all POP and YOY ranges will fall within these ranges

      let yearMonthDayValues = this.reportCore.getYearMonthDayValuesForKPIRange()

      for (let i = 0; i < yearMonthDayValues.length; i++) {
        this.buildReportProcess3(yearMonthDayValues[i])
      }
    },

    buildReportProcess1() {
      const auth = useAuth()

      if (!auth.hasPermission('read_budget_sale_report_all_owners')) {
        this.ownerFilter = auth.user.name

        if (auth.hasRole('staff')) {
          //  console.log(this.reportCore.dataStaffs)
          this.ownerFilter = this.reportCore.dataStaffs[auth.user.name]?.parentName
        }
      }



      this.reportCore.calculateGrowthDateRanges()

      this.unmappedKPIOwners = markRaw({})
      this.unmappedKPILeadGroups = markRaw({})

      this.ownersExcludedByStartDate = markRaw({})
      this.ownersExcludedByStartDateButValid = markRaw({})

      this.tableDataOwnersObj = markRaw({})
      this.tableDataOwnersBudgetsObj = markRaw({})
      this.tableDataLocationsObj = markRaw({})
      this.tableDataSalesPersonsObj = markRaw({})
      this.tableDataLeadGroupsObj = markRaw({})

      this.timeLineDataObj = markRaw({})

      //get all days in the current single month selection
      //use dateRangeEnd to know current month

      for (let i = 0; i < this.reportCore.dateRangeEnd.day; i++) {
        let timeLineDay = (this.timeLineDataObj[i + 1] = {})
        let dateTemp = new SimpleDate(
          this.reportCore.dateRangeEnd.year,
          this.reportCore.dateRangeEnd.month,
          i + 1
        )
        this.reportCore.getPredictiveIndexRollingStartEnds(timeLineDay, dateTemp, true)
        timeLineDay.dataRolling22 = {}
        timeLineDay.dataRolling30 = {}
        timeLineDay.dataRolling60 = {}
        timeLineDay.data = {}
      }
      //console.log(this.timeLineDataObj)

      //-------------------------------------------------------------------

      this.metrics = markRaw({})
      this.metrics.rangeCurrent = {}
      this.metrics.rangeSub1 = {}
      this.metrics.rangeSub2 = {}

      this.dataKPI2Store.clearDebugInfo()

      this.buildReportProcess2() // buildReportProcess 3,4,5 ,6called from here

      this.buildReportProcess7()

      this.dataReportKPIVerbose.invalidate({queryKey:"KPIVerboseQuery1"},{ownerFilter:this.ownerFilter,leadGroupFilter:this.leadGroupFilter});
     

      this.reportCore.setLoadHeavy(false)
    },

    getMonthName(monthIndex) {
      if (monthIndex === 0) return 'Jan'
      if (monthIndex === 1) return 'Feb'
      if (monthIndex === 2) return 'Mar'
      if (monthIndex === 3) return 'Apr'
      if (monthIndex === 4) return 'May'
      if (monthIndex === 5) return 'Jun'
      if (monthIndex === 6) return 'Jul'
      if (monthIndex === 7) return 'Aug'
      if (monthIndex === 8) return 'Sep'
      if (monthIndex === 9) return 'Oct'
      if (monthIndex === 10) return 'Nov'
      if (monthIndex === 11) return 'Dec'
    },

    buildScoreCards() {
      this.scorecardLabelsSales = {
        subValue1: 'yoy',
        subValue2: 'budget',
        subMetric1: 'YOY',
        subMetric2: 'Budget % Delta'
      }
      this.scorecardLabelsVariance = {
        subValue1: '',
        subValue2: 'budget',
        subMetric1: '',
        subMetric2: 'Budget % Delta'
      }
      this.scorecardLabelsOwners = {
        subValue1: '',
        subValue2: 'owners total',
        subMetric1: '',
        subMetric2: 'Percent'
      }
      this.scorecardLabelsPredictiveIndex = {
        subValue1: 'est appointments',
        subValue2: 'est jobs sold',
        subMetric1: '',
        subMetric2: 'Budget % Delta'
      }

      this.scoreCards.sales_legacy = useScoreCardBudgetSales(
        'Sales Legacy',
        this,

        this.ownerTableFilterValueLEGACY
      )
      this.scoreCards.sales_new = useScoreCardBudgetSales(
        'Sales New',
        this,

        this.ownerTableFilterValueNEW
      )

      let title = 'All'
      if (this.ownerFilter) {
        title = this.ownerFilter
      }
      if (this.locationFilter) {
        title = this.locationFilter
      }
      if (this.leadGroupFilter) {
        title = this.leadGroupFilter
      }

      this.scoreCards.sales_all = useScoreCardBudgetSales(
        `Sales ${title}`,
        this,

        this.ownerTableFilterValueALL
      )

      this.scoreCards.variance_legacy = useScoreCardBudgetVariance(
        'Variance Legacy',
        this,

        this.ownerTableFilterValueLEGACY
      )

      this.scoreCards.variance_new = useScoreCardBudgetVariance(
        'Variance New',
        this,

        this.ownerTableFilterValueNEW
      )

      this.scoreCards.variance_all = useScoreCardBudgetVariance(
        `Variance ${title}`,
        this,

        this.ownerTableFilterValueALL
      )

      this.scoreCards.owners_over = useScoreCardBudgetOwners(
        'Owners Over',
        this,

        'over'
      )

      this.scoreCards.owners_under = useScoreCardBudgetOwners('Owners Under', this, 'under')

      this.scoreCards.predictive_index_30 = useScoreCardBudgetPredictiveIndex(
        `Predictive Index 30`,
        this,
        '30'
      )

      this.scoreCards.predictive_index_60 = useScoreCardBudgetPredictiveIndex(
        `Predictive Index 60`,
        this,
        '60'
      )

      this.scoreCards.predictive_index_summed_30 = useScoreCardBudgetPredictiveIndexSummed(
        `Predictive Index 30 Summed`,
        this,
        '30'
      )

      this.scoreCards.predictive_index_summed_60 = useScoreCardBudgetPredictiveIndexSummed(
        `Predictive Index 60 Summed`,
        this,
        '60'
      )

      //sales_legacy
      //sales_new
      //sales_all
      //variance_legacy
      //variance_new
      //variance_all
      //owners_over
      //owners_under
    },

    buildMetricsTables(metrics, targetData) {
      let ob = {}

      useBuildMetricDataTables2(ob, 'Owner', 'STRING', metrics, 'OWNER')

      useBuildMetricDataTables2(ob, 'Location', 'STRING', metrics, 'LOCATION')
      useBuildMetricDataTables2(ob, 'Lead Group', 'STRING', metrics, 'LEAD_GROUP')

      useBuildMetricDataTables2(ob, 'Sales This Year', 'USD', metrics, 'SALES_SUM')
      useBuildMetricDataTables2(ob, 'Sales Last Year', 'USD', metrics, 'SALES_SUM_YOY')
      useBuildMetricDataTables2(ob, 'Sales Budget', 'USD', metrics, 'BUDGET')
      useBuildMetricDataTables2(ob, 'Budget % Delta', '%delta', metrics, ['SALES_SUM', 'BUDGET'])
      useBuildMetricDataTables2(ob, 'Predictive Index 30', 'USD', metrics, 'PREDICTIVE_INDEX_30')
      useBuildMetricDataTables2(ob, 'Predictive Index 60', 'USD', metrics, 'PREDICTIVE_INDEX_60')

      useBuildMetricDataTables2(ob, 'YOY', '%', metrics, 'YOY')

      targetData.push(ob)
    },

    doubleRaf(callback, ...args) {
      requestAnimationFrame(() => {
        requestAnimationFrame(() => {
          try {
            callback(...args)
          } catch (error) {
            useErrorHandler().processScriptError(error)
          }
        })
      })
    },

    buildReport() {
      this.reportCore.setLoadHeavy(true, 'Budget Report', "process")
      this.doubleRaf(() => this.buildReportProcess1())
    }
  }
})
