<template>
  <div
    class="landing"
    :class="{ light: theme.themeKey === 'light', dark: theme.themeKey === 'dark' }"
  >
    <!-- toast component -->
    <Toast />
    <!-- vue-router -->
    <router-view></router-view>
  </div>
</template>

<script setup>
import { onBeforeMount,watch } from "vue";
import axios from "axios";
import { useTheme } from "@/stores/Theme.js";
import { useReportCore } from '@/stores/ReportCore.js'

import { useApplicationStore } from '@/stores/ApplicationStore.js'
import { useGlobalInjects } from '@/stores/GlobalInjects.js'
import { useRoute } from 'vue-router';
import { useRouter } from 'vue-router';
import Toast from "@/components/app/ToastComponent.vue";
const theme = useTheme();
const reportCore = useReportCore()
const applicationStore = useApplicationStore();

const globalInjects = useGlobalInjects();
const route = useRoute();
const router = useRouter();
globalInjects.route = route
globalInjects.router = router


// lifecycle hooks
onBeforeMount(() => {
  axios.defaults.baseURL = import.meta.env.VITE_APP_BASE_URL;
 
 // axios.defaults.baseURL = window.location_url;
  //loads the theme or sets a default if non found
  theme.readTheme();

 
 
});


watch(() => route.path, (newPath, oldPath) => {
  reportCore.clearPendingActions()
  applicationStore.updateUIStates()
    
    });

</script>

<style>
@import "@/assets/styles/variables.css";
@import "@/assets/styles/quickstyles.css";
@import "@/assets/styles/ui.css";
@import "@/assets/styles/defaultstyles.css";
@import "@/assets/styles/primevuecustoms.css";
#app {
  font-family: Roboto, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  width: 100%;
  height: 100%;
  display: flex;
  flex-grow: 1;
  flex-basis: 100%;
  flex-wrap: wrap;
}
.navbar-bg-1{
   background-color: var(--navbar-bg-1);
}
.nav-color-1{
   color: var(--nav-color-1);
}


.landing {
  display: flex;
  flex-grow: 1;
  flex-basis: 100%;
  flex-wrap: wrap;
}
html {
  height: 100%;
}
body {
  width: 100%;
  height: inherit;
  margin: 0;
  padding: 0;
  --bs-primary: #44acff;
}
.content-bg-1 {
  background-color: var(--content-bg-1);
}
.content-bg-2 {
  background-color: var(--content-bg-2);
}
.content-bg-3 {
  background-color: var(--content-bg-3);
}
.content-bg-4 {
  background-color: var(--content-bg-4);
}
.content-bg-5 {
  background-color: var(--content-bg-5);
}
.content-bg-6 {
  background-color: var(--content-bg-6);
}

.content-bg-1-inverted{
   background-color: var(--content-bg-1-inverted);
}
.icon-color-1 {
  color: var(--icon-color-1);
}


.icon-color-2 {
  color: var(--icon-color-2);
}
.text-color-1 {
  color: var(--text-color-1);
}
.text-color-2 {
  color: var(--text-color-2) !important;
}
.text-color-3 {
  color: var(--text-color-3);
}
.button-minimal {
  background-color: transparent !important;
  border: 0 !important;
  line-height:1
}
.button-sidenav:hover {
  background-color: #ffffff2d !important;
 
}
.button-sidenav:focus {
  background-color: #ffffff2d !important;
 
}
.btn-no-focus-shadow {
   
    box-shadow:none !important;
}
.text-align-left {
  text-align: left;
}
body{
  --bs-body-bg: #c1c1c130;
  --bs-secondary-bg: #c1c1c130
}



.btn-primary {
  color: #fff;
  background-color: var(--bs-primary) !important;
  border-color: var(--bs-primary) !important;
}
.form-control {
  border-right: 0 !important;
  border-left: 0 !important;
  font-size: 0.9rem !important;
  /**border-top: 0 !important;**/
  background-color: #c1c1c130 ;
  color: var(--text-color-2) !important;
  border-color: #bcc0c5 !important;
  border-radius: var(--bs-border-radius) !important;
}

.form-control:focus {
   
    box-shadow: none !important;
}



.form-select {
  border-right: 0 !important;
  border-left: 0 !important;
  /**border-top: 0 !important;**/
  background-color: #c1c1c130 !important;
  color: var(--text-color-2) !important;
  border-color: #bcc0c5 !important;


}

.dark .form-select {
  --bs-form-select-bg-img: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'><path fill='none' stroke='white' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='m2 5 6 6 6-6'/></svg>");

}
.light .form-select {
  --bs-form-select-bg-img: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'><path fill='none' stroke='grey' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='m2 5 6 6 6-6'/></svg>");
}


.form-select option {
  /**background-color: #c1c1c130 !important;**/
  color: #393939 !important;
}



.fs-7 {
  font-size: 0.95rem !important;
}
.fs-8 {
  font-size: 0.9rem !important;
}
.fs-9 {
  font-size: 0.85rem !important;
}
.fs-10 {
  font-size: 0.8rem !important;
}
.form-label {
  font-size: 0.85rem !important;
}
.icon {
  font-size: 20px;
  
}
.content-border-1 {
  border: var(--content-border-1);
}
.content-border-b-1{
  border-bottom:var(--content-border-b-1);
}

.content-header-1 {
  background-color: var(--content-header-bg-1);
  padding-top: 10px;
  padding-bottom: 10px;
  color: var(--content-header-1-color) !important;
}

.font-weight-black{
font-weight: 900!important;
}

.color-inherit{
      color: inherit !important
}

.listing-tools-item{
 
  max-width:200px;
}

.icon-c1 {
  color: var(--icon-color-3);
}


.icon-c2 {
 color: var(--icon-color-4);
}


.icon-c3 {
 color: var(--icon-color-5);
}

.icon-ms24{
  max-width:24px;
  max-height:24px;
}




</style>
