<template>
  <div ref="domElement" class="w-100 mx-auto" :class="applicationStore.getPadding">
    <div
      class="content-bg-11"
      :class="{
        'pa-4': !applicationStore.grid.tabletAndUnder,
        'elevation-2': !applicationStore.grid.tabletAndUnder,
        'rounded-2': !applicationStore.grid.tabletAndUnder
      }"
    >
      <div class="pa-4 pt-1 fs-3 text-color-6 text-align-left fw-bold">Users</div>

      <b-button
        v-if="false"
        style="width: 100%; max-width: 280px"
        class="mt-2 p-1 fs-6 fw-bolder px-3"
        variant="primary"
        @click="createError"
        >create error</b-button
      >

      <ContentExpansionHeader
        @click="expandSectionMassInvite = !expandSectionMassInvite"
        label="Mass Invite"
        :expanded="expandSectionMassInvite"
      />

      <div class="pa-4 mb-8 content-bg-12" v-if="expandSectionMassInvite">
        <div
          class="content-bg-11 w-100 d-flex flex-column justify-content-start gap-2"
          :class="{
            'pa-4': !applicationStore.grid.tabletAndUnder,
            'elevation-2': !applicationStore.grid.tabletAndUnder,
            'rounded-2': !applicationStore.grid.tabletAndUnder
          }"
        >
          <div class="fs-5 mb-2 text-align-left fw-bold text-color-1">Select Users to invite :</div>
          <div class="fs-8 text-align-left text-color-1">
            Sending an invite will generate a new password to be used to login and will email this
            password to the user/s
          </div>

          <div class="flex-ui-1" style="align-items: center">
            <Checkbox
              v-model="filterInviteListTOUnInvited"
              inputId="filterowneroptions"
              :binary="true"
              @change="filterInviteListOption"
            />
            <label for="filterowneroptions" class="fs-8 text-color-1 text-type-10 ml-2">
              filter to uninvited
            </label>
          </div>

          <MultiSelect
            v-model="selectedUsersInvite"
            :options="allUsersNames"
            placeholder="Select Owners"
            :maxSelectedLabels="0"
            class="mt-2"
            style="width: 250px; max-width: 250px"
          />

          <b-button
            class="mt-2 p-1 fs-6 fw-bolder px-3"
            variant="primary"
            @click="sendMassInvite"
            style="width: 250px; max-width: 250px"
            >invite</b-button
          >
        </div>
      </div>

      <ContentExpansionHeader
        @click="expandSectionCreate = !expandSectionCreate"
        label="Create"
        :expanded="expandSectionCreate"
      />

      <div class="pa-4 mb-8 content-bg-12" v-if="expandSectionCreate">
        <div
          class="content-bg-11 w-100"
          :class="{
            'pa-4': !applicationStore.grid.tabletAndUnder,
            'elevation-2': !applicationStore.grid.tabletAndUnder,
            'rounded-2': !applicationStore.grid.tabletAndUnder
          }"
        >
          <DataEditGenericComponent
            @submitemit="onSubmitCreate"
            :useSubmit="true"
            :payload="createModelFieldSet"
          />
        </div>
      </div>

      <ContentExpansionHeader
        @click="expandSectionListing = !expandSectionListing"
        label="Listing"
        :expanded="expandSectionListing"
      />

      <div v-if="expandSectionListing">
        <div
          style="position: relative"
          class="text-align-left pa-2 content-border-b-1"
          :class="{
            'ui-bg-4': !record.toggleEdit,
            'content-bg-12': record.toggleEdit,
            'mb-1': !record.toggleEdit,
            'my-4': record.toggleEdit
          }"
          v-for="record in records.value"
          :key="'gbc_' + record.id + ' ' + recordsKeyCurrent"
        >
          <div
            :data-id="record.id"
            style="cursor: pointer"
            @click="onHandleExpand(record, $event)"
            class="d-flex align-items-center h6 text-align-left fw-bold m-0 text-color-1"
          >
            <div
              class="m-0"
              :class="{
                'text-color-2': record.toggleEdit,
                h4: record.toggleEdit,
                h6: !record.toggleEdit,
                'mb-2': record.toggleEdit
              }"
            >
              {{ record.name }}
            </div>
            <span v-if="record.alert" class="h6 m-0 ms-2 mdi icon icon-color-11 mdi-alert"></span>
            <span
              v-if="!record.active"
              class="h6 m-0 ms-2 mdi icon icon-color-11 mdi-account-lock"
            ></span>
          </div>
          <div v-if="record.toggleEdit">
            <TabView v-model:activeIndex="tabIndexActive">
              <TabPanel header="Profile">
                <div
                  class="w-100"
                  :class="{
                    'pa-4': !applicationStore.grid.tabletAndUnder,
                    'elevation-2': !applicationStore.grid.tabletAndUnder,
                    'rounded-2': !applicationStore.grid.tabletAndUnder
                  }"
                  v-for="(fieldsetitem, index) in record.fieldSets"
                  :key="'fsi_' + record.id + '_' + index"
                >
                  <DataEditGenericComponent
                    @submitemit="onSubmitExisting"
                    @deleteemit="onDeleteExisting"
                    :useSubmit="true"
                    :useDelete="true"
                    :payload="fieldsetitem"
                  />
                </div>
              </TabPanel>
              <TabPanel header="Permissions" v-if="auth.hasPermission('manage_permissions')">
                <div
                  class="w-100"
                  :class="{
                    'pa-4': !applicationStore.grid.tabletAndUnder,
                    'elevation-2': !applicationStore.grid.tabletAndUnder,
                    'rounded-2': !applicationStore.grid.tabletAndUnder
                  }"
                >
                  <div class="fs-5 mb-2 text-align-left fw-bold text-color-1">
                    Permissions via User :
                  </div>

                  <b-form-group
                    label="permissions"
                    label-for="permission-selection"
                    class="fs-9 text-align-left text-color-1"
                  >
                    <MultiSelect
                      v-model="record.userPermissionStrings"
                      :options="permissionOptionsStrings"
                      placeholder="Select Premissions"
                      :maxSelectedLabels="0"
                      style="width: 250px; max-width: 250px"
                      @update:modelValue="onUpdateUserPermissions($event, record)"
                    />
                  </b-form-group>
                  <div>
                    <div
                      class="pa-2 content-border-b-1 content-bg-4 mb-1 d-flex flex-wrap align-items-center gap-3"
                      v-for="(userPermission, index) in record.userPermissions"
                      :key="'fsuserpermission_' + record.id + '_' + index"
                    >
                      <div class="text-color-1 me-auto" style="min-width: 200px">
                        {{ userPermission.value }}
                      </div>
                      <div class="flex-ui-1" style="align-items: center">
                        <Checkbox
                          v-model="userPermission.PermissionUser.revoked"
                          :inputId="'userpermission' + index"
                          :binary="true"
                          @update:modelValue="submitUpdateUserPermission($event, record)"
                        />
                        <label
                          :for="'userpermission' + index"
                          class="fs-8 text-color-1 text-type-10 ml-2"
                        >
                          revoked
                        </label>
                      </div>

                      <button
                        class="button-minimal"
                        @click="deleteUserPermission($event, record, userPermission)"
                      >
                        <span class="mdi icon nav-color-1 mdi-delete"></span>
                      </button>
                    </div>
                  </div>

                  <div
                    class="px-4 my-12"
                    style="height: 1px; background-color: var(--content-bg-5-inverted)"
                  ></div>

                  <div class="fs-5 mb-2 text-align-left fw-bold text-color-1">
                    Permissions via Roles :
                  </div>
                  <div
                    class="mb-2"
                    style="word-break: break-word"
                    v-for="(fieldsetitem, index) in record.fieldSets[0].fields.find(
                      (element) => element.label === 'role'
                    ).modelValue"
                    :key="'fsrole_' + record.id + '_' + index"
                  >
                    <div class="fs-6 text-align-left fw-bold text-color-1">
                      Role: {{ fieldsetitem }}
                    </div>

                    <div
                      v-for="(permissionitem, index2) in auth.rolesAndPermissionsSystem.roles.find(
                        (element) => element.value === fieldsetitem
                      ).Permissions"
                      :key="
                        'fspermission_' + record.id + '_' + permissionitem.id + '_' + index + index2
                      "
                    >
                      <div class="ms-2 fs-8 text-align-left text-color-1">
                        {{ permissionitem.value }}
                      </div>
                    </div>
                  </div>
                </div>
              </TabPanel>
              <TabPanel v-if="auth.hasPermission('invite_users')" header="Invite">
                <div
                  class="w-100"
                  :class="{
                    'pa-4': !applicationStore.grid.tabletAndUnder,
                    'elevation-2': !applicationStore.grid.tabletAndUnder,
                    'rounded-2': !applicationStore.grid.tabletAndUnder
                  }"
                >
                  <div>
                    <div class="fs-6 text-align-left text-color-1">
                      {{ record.inviteStatusblurb }}
                    </div>
                    <div
                      class="mt-2 fs-8 text-align-left text-color-1"
                      v-if="record.inviteSentDate"
                    >
                      Sent on: {{ record.inviteSentDate }}
                    </div>
                    <b-button
                      class="mt-2 p-1 fs-6 fw-bolder px-3"
                      variant="primary"
                      @click="sendInvite($event, record)"
                      >{{ record.inviteSentDate ? 'send again' : 'send' }}</b-button
                    >
                  </div>
                </div>
              </TabPanel>

              <TabPanel
                v-if="
                  auth.hasPermission('manage_aliases') && auth.hasRole('owner', record.userRoles)
                "
                header="Aliases Owner"
              >
                <div
                  class="w-100"
                  :class="{
                    'pa-4': !applicationStore.grid.tabletAndUnder,
                    'elevation-2': !applicationStore.grid.tabletAndUnder,
                    'rounded-2': !applicationStore.grid.tabletAndUnder
                  }"
                >
                  <div>
                    <div class="mb-4 fs-8 text-align-left text-color-1">
                      Add any aliases to this dimension. Theses aliases will be used by the backend
                      and frontend for name mapping data points from various data sources.
                    </div>
                    <div>
                      <div
                        class="pa-2 content-border-b-1 content-bg-4 mb-1 d-flex flex-wrap align-items-center gap-3"
                        v-for="(alias, index) in record.aliasesOwner"
                        :key="'alias_' + record.id + '_' + index"
                      >
                        <div class="me-auto text-color-2 fw-bold" style="min-width: 200px">
                          {{ alias.name }}
                        </div>

                        <button
                          class="button-minimal"
                          @click="deleteAliasOwner($event, record, alias.name)"
                        >
                          <span class="mdi icon nav-color-1 mdi-delete"></span>
                        </button>
                      </div>
                    </div>

                    <b-form-input
                      class="mt-4 text-color-1"
                      style="max-width: 280px"
                      :id="'new_alias_input'"
                      v-model="record.new_alias"
                      trim
                    ></b-form-input>

                    <b-button
                      style="width: 100%; max-width: 280px"
                      class="mt-2 p-1 fs-6 fw-bolder px-3"
                      variant="primary"
                      @click="addAliasOwner($event, record)"
                      >Add Alias</b-button
                    >
                  </div>
                </div>
              </TabPanel>

              <TabPanel
                v-if="
                  auth.hasPermission('manage_aliases') &&
                  auth.hasRole('salesperson', record.userRoles)
                "
                header="Aliases Sales Person"
              >
                <div
                  class="w-100"
                  :class="{
                    'pa-4': !applicationStore.grid.tabletAndUnder,
                    'elevation-2': !applicationStore.grid.tabletAndUnder,
                    'rounded-2': !applicationStore.grid.tabletAndUnder
                  }"
                >
                  <div>
                    <div class="mb-4 fs-8 text-align-left text-color-1">
                      Add any aliases to this dimension. Theses aliases will be used by the backend
                      and frontend for name mapping data points from various data sources.
                    </div>
                    <div>
                      <div
                        class="pa-2 content-border-b-1 content-bg-4 mb-1 d-flex flex-wrap align-items-center gap-3"
                        v-for="(alias, index) in record.aliasesSalesPerson"
                        :key="'alias_' + record.id + '_' + index"
                      >
                        <div class="me-auto text-color-2 fw-bold" style="min-width: 200px">
                          {{ alias.name }}
                        </div>

                        <button
                          class="button-minimal"
                          @click="deleteAliasSalesPerson($event, record, alias.name)"
                        >
                          <span class="mdi icon nav-color-1 mdi-delete"></span>
                        </button>
                      </div>
                    </div>

                    <b-form-input
                      class="mt-4 text-color-1"
                      style="max-width: 280px"
                      :id="'new_alias_input'"
                      v-model="record.new_alias"
                      trim
                    ></b-form-input>

                    <b-button
                      style="width: 100%; max-width: 280px"
                      class="mt-2 p-1 fs-6 fw-bolder px-3"
                      variant="primary"
                      @click="addAliasSalesPerson($event, record)"
                      >Add Alias</b-button
                    >
                  </div>
                </div>
              </TabPanel>

              <TabPanel
                v-if="
                  auth.hasPermission('manage_aliases') && auth.hasRole('staff', record.userRoles)
                "
                header="Aliases Staff"
              >
                <div
                  class="w-100"
                  :class="{
                    'pa-4': !applicationStore.grid.tabletAndUnder,
                    'elevation-2': !applicationStore.grid.tabletAndUnder,
                    'rounded-2': !applicationStore.grid.tabletAndUnder
                  }"
                >
                  <div>
                    <div class="mb-4 fs-8 text-align-left text-color-1">
                      Add any aliases to this dimension. Theses aliases will be used by the backend
                      and frontend for name mapping data points from various data sources.
                    </div>
                    <div>
                      <div
                        class="pa-2 content-border-b-1 content-bg-4 mb-1 d-flex flex-wrap align-items-center gap-3"
                        v-for="(alias, index) in record.aliasesStaff"
                        :key="'alias_' + record.id + '_' + index"
                      >
                        <div class="me-auto text-color-2 fw-bold" style="min-width: 200px">
                          {{ alias.name }}
                        </div>

                        <button
                          class="button-minimal"
                          @click="deleteAliasStaff($event, record, alias.name)"
                        >
                          <span class="mdi icon nav-color-1 mdi-delete"></span>
                        </button>
                      </div>
                    </div>

                    <b-form-input
                      class="mt-4 text-color-1"
                      style="max-width: 280px"
                      :id="'new_alias_input'"
                      v-model="record.new_alias"
                      trim
                    ></b-form-input>

                    <b-button
                      style="width: 100%; max-width: 280px"
                      class="mt-2 p-1 fs-6 fw-bolder px-3"
                      variant="primary"
                      @click="addAliasStaff($event, record)"
                      >Add Alias</b-button
                    >
                  </div>
                </div>
              </TabPanel>

              <TabPanel
                v-if="auth.hasPermission('manage_user_default_data_source')"
                header="data source"
              >
                <div
                  class="w-100"
                  :class="{
                    'pa-4': !applicationStore.grid.tabletAndUnder,
                    'elevation-2': !applicationStore.grid.tabletAndUnder,
                    'rounded-2': !applicationStore.grid.tabletAndUnder
                  }"
                >
                  <div class="mt-3 text-type-11 text-color-1 p-0">select default data source:</div>
                  <div class="justify-content-start mt-1 mb-2 d-inline-flex flex-column gap-1">
                    <div
                      class="d-flex align-items-center"
                      @click="invalidateDefaultDataSource(record, reportCore.DATA_SOURCE_CORE)"
                      style="cursor: pointer"
                    >
                      <span
                        class="mdi icon text-color-1"
                        :class="{
                          'mdi-radiobox-marked':
                            record.defaultDataSource?.value === reportCore.DATA_SOURCE_CORE,
                          'mdi-radiobox-blank':
                            record.defaultDataSource?.value !== reportCore.DATA_SOURCE_CORE
                        }"
                      ></span>
                      <div for="datasource1" class="fs-8 text-color-2 text-type-10 ml-2">CORE</div>
                    </div>

                    <div
                      class="d-flex align-items-center"
                      @click="invalidateDefaultDataSource(record, reportCore.DATA_SOURCE_SM)"
                      style="cursor: pointer"
                    >
                      <span
                        class="mdi icon text-color-1"
                        :class="{
                          'mdi-radiobox-marked':
                            record.defaultDataSource?.value === reportCore.DATA_SOURCE_SM,
                          'mdi-radiobox-blank':
                            record.defaultDataSource?.value !== reportCore.DATA_SOURCE_SM
                        }"
                      ></span>
                      <div for="datasource1" class="fs-8 text-color-2 text-type-10 ml-2">SM</div>
                    </div>

                    <div
                      class="d-flex align-items-center"
                      @click="invalidateDefaultDataSource(record, reportCore.DATA_SOURCE_BLEND)"
                      style="cursor: pointer"
                    >
                      <span
                        class="mdi icon text-color-1"
                        :class="{
                          'mdi-radiobox-marked':
                            record.defaultDataSource?.value === reportCore.DATA_SOURCE_BLEND,
                          'mdi-radiobox-blank':
                            record.defaultDataSource?.value !== reportCore.DATA_SOURCE_BLEND
                        }"
                      ></span>
                      <div for="datasource1" class="fs-8 text-color-2 text-type-10 ml-2">
                        BLENDED
                      </div>
                    </div>
                  </div>

                  <div
                    v-if="record.defaultDataSource?.value === reportCore.DATA_SOURCE_BLEND"
                    class="mt-3 mb-3 d-flex align-items-center justify-content-start"
                    :class="{ 'flex-column': applicationStore.grid.tabletAndUnder }"
                  >
                    <p class="text-type-9 text-color-5 p-0 mb-0 mr-2 fs-8">Blend Date</p>
                    <Calendar
                      panelClass="min-w-min"
                      v-model="record.defaultDataSource.blend_date_model"
                      @date-select="onDateSelectionBlend(record)"
                      selectionMode="single"
                      :manualInput="false"
                      inputId="range"
                    />
                  </div>
                </div>
              </TabPanel>
              <TabPanel v-if="auth.hasPermission('login_without_password')" header="login">
                <div
                  class="w-100"
                  :class="{
                    'pa-4': !applicationStore.grid.tabletAndUnder,
                    'elevation-2': !applicationStore.grid.tabletAndUnder,
                    'rounded-2': !applicationStore.grid.tabletAndUnder
                  }"
                >
                  <b-button
                    class="p-1 fs-4 fw-bolder w-100"
                    variant="primary"
                    @click="loginWithoutPassword(record)"
                    >login as this user</b-button
                  >
                </div>
              </TabPanel>

              <TabPanel
                v-if="
                  auth.hasPermission('manage_owner_royalty_percentages') &&
                  auth.hasRole('owner', record.userRoles)
                "
                header="Owner Royalty Percentages"
              >
                <div
                  class="w-100"
                  :class="{
                    'pa-4': !applicationStore.grid.tabletAndUnder,
                    'elevation-2': !applicationStore.grid.tabletAndUnder,
                    'rounded-2': !applicationStore.grid.tabletAndUnder
                  }"
                >
                  <div>
                    <div
                      v-for="(royalty, index) in record.ownerRoyaltyPercentages"
                      :key="'royalty_' + record.id + '_' + index"
                      class="pa-2 content-border-b-1 fs-6 fw-bold text-color-1 px-2 ui-bg-4 d-flex flex-wrap align-items-center gap-3"
                    >
                      <div class="d-flex align-items-center">
                        <label
                          style="width: 100px"
                          class="fs-8 mr-2"
                          :for="'royalty_percent_' + index + royalty.contact_id"
                          >{{ getRoyaltyLabel(royalty) }}</label
                        >

                        <div style="width: 100px">
                          <b-form-input
                            :id="'royalty_percent_' + index + royalty.contact_id"
                            v-model="royalty.percent"
                            @focus="cacheInputRoyaltyPercent(royalty)"
                            @blur="validateInputRoyaltyPercent(royalty)"
                            @keydown.enter="validateInputRoyaltyPercent(royalty)"
                            trim
                            class="input-bg-color-1"
                          ></b-form-input>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </TabPanel>
            </TabView>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import {
  onMounted,
  onUpdated,
  onUnmounted,
  ref,
  nextTick,
  toRaw,
  computed,
  watch,
  reactive
} from 'vue'

import { useRequests } from '@/stores/Requests.js'
import { useRouter, useRoute } from 'vue-router'
import { useURLS } from '@/stores/URLS.js'
import { useAuth } from '@/stores/Auth.js'
import { useApplicationStore } from '@/stores/ApplicationStore.js'
import { useScrollToElement } from '@/scripts/utils/ScrollToElement.js'
import { useToasts } from '@/stores/Toasts.js'
import DataEditGenericComponent from '@/components/ui/DataEditGenericComponent.vue'
import ContentExpansionHeader from '@/components/ui/ContentExpansionHeader.vue'
import { useReportCore } from '@/stores/ReportCore.js'
import { useDataPresetsTablesStore } from '@/stores/DataPresetsTablesStore.js'
import { useStoreManagement } from '@/stores/StoreManagement.js'
import SimpleDate from '@/scripts/utils/SimpleDate.js'
import { useDataPresetsReportFiltersStore } from '@/stores/DataPresetsReportFiltersStore.js'

const applicationStore = useApplicationStore()
const urls = useURLS()
const dataPresetsTablesStore = useDataPresetsTablesStore()
const dataPresetsReportFiltersStore = useDataPresetsReportFiltersStore()
const route = useRoute()
const auth = useAuth()
const records = reactive({ value: {} })
const results = ref()
const router = useRouter()
const toasts = useToasts()
var onUpdatedConsumed = false
var resultID = null
var currentSelected = null
const domElement = ref()
const expandSectionCreate = ref(false)
const expandSectionMassInvite = ref(false)
const expandSectionListing = ref(true)
const reportCore = useReportCore()
const createModelFieldSet = ref()
const requests = useRequests()
const recordsKeyCurrent = ref(0)
const selectedUsersInvite = ref([])
const allUsersNames = ref([])
const filterInviteListTOUnInvited = ref(false)
const tabIndexActive = ref(0)

var ownerOptions = []

function cacheInputRoyaltyPercent(royalty) {
  console.log('cacheInputRoyaltyPercent')
  console.log(royalty)
  royalty.cachedValue = royalty.percent
}

function validateInputRoyaltyPercent(royalty) {
  if (royalty.percent === '' || isNaN(royalty.percent)) {
    royalty.percent = royalty.cachedValue
    return
  }

  if (royalty.cachedValue != royalty.percent) {
    royalty.cachedValue = royalty.percent

    updateRoyalty(royalty)
  }
}

async function updateRoyalty(royalty) {
  reportCore.setLoadHeavy(true, 'Owner Percent', 'update')

  let url = urls.getURL('user_management', 'updateOwnerRoyaltyPercentage')
  let data = {}
  data.id = royalty.id
  data.percent = royalty.percent

  var formData = data || {}
  let result
  try {
    result = await requests.post({
      url: url,
      data: formData
    })
  } catch (error) {
    console.log(error)
    reportCore.setLoadHeavy(false)
    return
  }
  if (result) {
    reportCore.setLoadHeavy(false)
    toasts.setSuccessMessage(result.data.message, 1000)
  }
}

async function loginWithoutPassword(record) {
  requests
    .post({
      url: urls.getURL('auth', 'loginWithoutPassword'),
      data: { email: record.email, urlParams: route.query }
    })
    .then((response) => {
      useStoreManagement().resetStoresAll()

      auth.setToken(response.data.token)
      auth.setRolesAndPermissions(response.data.userRolesAndPermissions)
      auth.setRolesAndPermissionsSystem(response.data.systemRolesAndPermissions)
      reportCore.processSystemDimensions(response.data.systemDimensions)
      auth.setTokenHeader()
      auth.addTokenInvalidate()
      auth.startTokenRefreshTracking()
      auth.setUserInfo(response.data.userInfo)
      dataPresetsTablesStore.process(response.data.userPresetsTables)
      dataPresetsReportFiltersStore.process(response.data.userReportFilterPresets)
      router.push({ path: '/application' })
      applicationStore.processNewUI()
    })
}

function onDateSelectionBlend(record) {
  let sd = new SimpleDate()
  sd.getValuesFromDate(record.defaultDataSource.blend_date_model)
  record.defaultDataSource.blend_date = sd.getDateString()
  updateDefaultDataSource(record, record.defaultDataSource.value)
}

async function invalidateDefaultDataSource(record, defaultDataSourceValue) {
  let defaultDataSourceValueTemp = record.defaultDataSource.value

  if (defaultDataSourceValueTemp === defaultDataSourceValue) {
    record.defaultDataSource.value = null
  } else {
    record.defaultDataSource.value = defaultDataSourceValue
  }

  updateDefaultDataSource(record)
}

async function updateDefaultDataSource(record) {
  let url = urls.getURL('user_management', 'setDefaultDataSource')
  let data = {}
  data.defaultDataSource = {}
  data.defaultDataSource.value = record.defaultDataSource?.value
  data.defaultDataSource.blend_date = record.defaultDataSource?.blend_date
  data.id = record.id

  var formData = data || {}
  let result
  try {
    result = await requests.post({
      url: url,
      data: formData
    })
  } catch (error) {
    console.log(error)
    reportCore.setLoadHeavy(false)
    return
  }
  if (result) {
    toasts.setSuccessMessage(result.data.message, 1000)
  }
}

async function addAliasOwner($event, record) {
  reportCore.setLoadHeavy(true, 'Alias Owner', 'create')
  const requests = useRequests()
  var formData = { id: record.id, owner_id: record.owner_id, alias: record.new_alias }
  let result
  try {
    result = await requests.post({
      url: urls.getURL('user_management', 'addAliasOwner'),
      data: formData
    })
  } catch (error) {
    console.log(error)
    reportCore.setLoadHeavy(false)
    return
  }
  if (result) {
    reportCore.setLoadHeavy(false)
    onUpdatedConsumed = false
    loadDataValidate()
    resultID = result.data.id
    toasts.setSuccessMessage(result.data.message)
  }
}

async function deleteAliasOwner($event, record, alias) {
  reportCore.setLoadHeavy(true, 'Alias Owner', 'delete')
  const requests = useRequests()
  var formData = { id: record.id, owner_id: record.owner_id, alias }
  let result
  try {
    result = await requests.post({
      url: urls.getURL('user_management', 'deleteAliasOwner'),
      data: formData
    })
  } catch (error) {
    console.log(error)
    reportCore.setLoadHeavy(false)
    return
  }
  if (result) {
    reportCore.setLoadHeavy(false)
    onUpdatedConsumed = false
    loadDataValidate()
    resultID = result.data.id
    toasts.setSuccessMessage(result.data.message)
  }
}

//---------------------------------------------------------------

async function addAliasSalesPerson($event, record) {
  reportCore.setLoadHeavy(true, 'Alias Sales Person', 'create')
  const requests = useRequests()
  var formData = { id: record.id, sales_person_id: record.sales_person_id, alias: record.new_alias }
  let result
  try {
    result = await requests.post({
      url: urls.getURL('user_management', 'addAliasSalesPerson'),
      data: formData
    })
  } catch (error) {
    console.log(error)
    reportCore.setLoadHeavy(false)
    return
  }
  if (result) {
    reportCore.setLoadHeavy(false)
    onUpdatedConsumed = false
    loadDataValidate()
    resultID = result.data.id
    toasts.setSuccessMessage(result.data.message)
  }
}

async function deleteAliasSalesPerson($event, record, alias) {
  reportCore.setLoadHeavy(true, 'Alias Sales Person', 'delete')
  const requests = useRequests()
  var formData = { id: record.id, sales_person_id: record.sales_person_id, alias }
  let result
  try {
    result = await requests.post({
      url: urls.getURL('user_management', 'deleteAliasSalesPerson'),
      data: formData
    })
  } catch (error) {
    console.log(error)
    reportCore.setLoadHeavy(false)
    return
  }
  if (result) {
    reportCore.setLoadHeavy(false)
    onUpdatedConsumed = false
    loadDataValidate()
    resultID = result.data.id
    toasts.setSuccessMessage(result.data.message)
  }
}

//---------------------------------------------------------------

async function addAliasStaff($event, record) {
  reportCore.setLoadHeavy(true, 'Alias Staff', 'create')
  const requests = useRequests()
  var formData = { id: record.id, staff_id: record.staff_id, alias: record.new_alias }
  let result
  try {
    result = await requests.post({
      url: urls.getURL('user_management', 'addAliasStaff'),
      data: formData
    })
  } catch (error) {
    console.log(error)
    reportCore.setLoadHeavy(false)
    return
  }
  if (result) {
    reportCore.setLoadHeavy(false)
    onUpdatedConsumed = false
    loadDataValidate()
    resultID = result.data.id
    toasts.setSuccessMessage(result.data.message)
  }
}

async function deleteAliasStaff($event, record, alias) {
  reportCore.setLoadHeavy(true, 'Alias Staff', 'delete')
  const requests = useRequests()
  var formData = { id: record.id, staff_id: record.staff_id, alias }
  let result
  try {
    result = await requests.post({
      url: urls.getURL('user_management', 'deleteAliasStaff'),
      data: formData
    })
  } catch (error) {
    console.log(error)
    reportCore.setLoadHeavy(false)
    return
  }
  if (result) {
    reportCore.setLoadHeavy(false)
    onUpdatedConsumed = false
    loadDataValidate()
    resultID = result.data.id
    toasts.setSuccessMessage(result.data.message)
  }
}

//---------------------------------------------------------------
function getRoyaltyLabel(record) {
  let start = record.bracket_start
  if (record.bracket_start !== 0) {
    start /= 1000000
    start += 'M'
  }
  let end = record.bracket_end / 1000000 + 'M'
  if (record.bracket_end === 1000000000) {
    return `>${record.bracket_start / 1000000}M`
  } else {
    return `${start}-${end}`
  }
}
async function sendMassInvite() {
  reportCore.setLoadHeavy(true, 'User Invites', 'submit')
  const requests = useRequests()
  var formData = { usersToInvite: selectedUsersInvite.value }
  let result
  try {
    result = await requests.post({
      url: urls.getURL('user_management', 'sendMassInvite'),
      data: formData
    })
  } catch (error) {
    console.log(error)
    reportCore.setLoadHeavy(false)
    return
  }
  if (result) {
    reportCore.setLoadHeavy(false)
    toasts.setSuccessMessage(result.data.message)
  }
}

const permissionOptions = [...auth.rolesAndPermissionsSystem.permissions]
const permissionOptionsStrings = []
permissionOptions.forEach((element) => {
  permissionOptionsStrings.push(element.value)
})

//---------------------------------------------------------------------------------------

onUnmounted(() => {
  records.value = {}
  results.value = null
  currentSelected = null
  resultID = null
  onUpdatedConsumed = false
})

//---------------------------------------------------------------------------------------

function onUpdateUserPermissions($event, record) {
  record.userPermissionStrings.forEach((element) => {
    let permissionItem = permissionOptions.find((permission) => {
      return permission.value === element
    })

    let userPermissionItem = record.userPermissions.find((permission) => {
      return permission.value === element
    })
    if (!userPermissionItem) {
      userPermissionItem = { ...permissionItem }
      userPermissionItem.PermissionUser = { revoked: false }
      record.userPermissions.push(userPermissionItem)
    }
  })

  submitUpdateUserPermission(null, record)
}

//---------------------------------------------------------------------------------------

function deleteUserPermission($event, record, permission) {
  record.userPermissions = record.userPermissions.filter((item) => item.value !== permission.value)
  record.userPermissionStrings = record.userPermissionStrings.filter(
    (item) => item !== permission.value
  )

  submitUpdateUserPermission(null, record)
}

//---------------------------------------------------------------------------------------

async function sendInvite($event, record) {
  reportCore.setLoadHeavy(true, 'User Invite', 'submit')
  const requests = useRequests()
  var formData = record || {}
  let result
  try {
    result = await requests.post({
      url: urls.getURL('user_management', 'sendInvite'),
      data: formData
    })
  } catch (error) {
    console.log(error)
    reportCore.setLoadHeavy(false)

    return
  }
  if (result) {
    reportCore.setLoadHeavy(false)
    onUpdatedConsumed = false
    loadDataValidate()
    resultID = result.data.id
    toasts.setSuccessMessage(result.data.message)
  }
}

async function submitUpdateUserPermission($event, record) {
  reportCore.setLoadHeavy(true, 'User Permission', 'update')
  const requests = useRequests()
  var formData = record || {}
  let result
  try {
    result = await requests.post({
      url: urls.getURL('user_management', 'updatePermissionUser'),
      data: formData
    })
  } catch (error) {
    console.log(error)
    reportCore.setLoadHeavy(false)
    loadDataValidate()

    return
  }
  if (result) {
    reportCore.setLoadHeavy(false)
    onUpdatedConsumed = false
    loadDataValidate()
    resultID = result.data.id
    toasts.setSuccessMessage(result.data.message)
  }
}

//---------------------------------------------------------------------------------------

//---------------------------------------------------------------------------------------

function onSubmitCreate(payload) {
  let fieldsWrite = [...payload.fields]
  let d = fieldsWrite.find(
    (element) => element.modelTable === 'dynamic' && element.modelKey === 'started_at'
  )

  if (d.modelValue) {
    let simpleDate = new SimpleDate()
    simpleDate.getValuesFromDate(d.modelValue)
    d.modelValue = simpleDate.getDateString()
  }

  //trim name

  let n = fieldsWrite.find(
    (element) => element.modelTable === 'User' && element.modelKey === 'name'
  )

  if (n.modelValue) {
    n.modelValue = n.modelValue.trim()
  }

  updateData(urls.getURL('user_management', 'add'), { fields: fieldsWrite })
}

//---------------------------------------------------------------------------------------

function onSubmitExisting(payload) {
  let fieldsWrite = [...payload.fields]

  let d = fieldsWrite.find(
    (element) => element.modelTable === 'dynamic' && element.modelKey === 'started_at'
  )
  if (d.modelValue) {
    let simpleDate = new SimpleDate()
    simpleDate.getValuesFromDate(d.modelValue)
    d.modelValue = simpleDate.getDateString()
  }

  resultID = results.value.users[payload.resultItemIndex].id
  updateData(urls.getURL('user_management', 'update'), {
    user: { modelID: resultID },
    fields: fieldsWrite
  })
}

//---------------------------------------------------------------------------------------

async function onDeleteExisting(payload) {
  let result = await reportCore.modalConfirmCancelStart('do you want to delete this user?')
  if (result) {
    let fieldsWrite = [...payload.fields]
    resultID = results.value.users[payload.resultItemIndex].id
    updateData(urls.getURL('user_management', 'delete'), {
      user: { modelID: resultID },
      fields: fieldsWrite
    })
  } else {
    //
  }
}

//---------------------------------------------------------------------------------------

async function updateData(url, data) {
  reportCore.setLoadHeavy(true, 'User Item', 'update')
  const requests = useRequests()
  var formData = data || {}
  let result
  try {
    result = await requests.post({
      url: url,
      data: formData
    })
  } catch (error) {
    console.log(error)
    reportCore.setLoadHeavy(false)
    loadDataValidate()

    return
  }
  if (result) {
    reportCore.setLoadHeavy(false)
    onUpdatedConsumed = false
    loadDataValidate()
    resultID = result.data.id
    toasts.setSuccessMessage(result.data.message)
  }
}

//---------------------------------------------------------------------------------------

async function loadData(url, data) {
  reportCore.setLoadHeavy(true, 'User Data')
  const requests = useRequests()
  var formData = data || {}
  let result
  try {
    result = await requests.post({
      url: url,
      data: formData
    })
  } catch (error) {
    console.log(error)
    reportCore.setLoadHeavy(false)
    return
  }
  if (result) {
    results.value = result.data
    await reportCore.loadSystemDimensions()
    processData(result.data)
    reportCore.setLoadHeavy(false)

    reportCore.refreshKPIAgain = true
  }
}

//---------------------------------------------------------------------------------------
function onHandleExpand(record, event) {
  if (currentSelected) {
    currentSelected.toggleEdit = false
  }
  currentSelected = record
  currentSelected.toggleEdit = true

  useScrollToElement(event.currentTarget.offsetParent, toRaw(applicationStore.contentDomElement))
}

//---------------------------------------------------------------------------------------

onUpdated(() => {
  onUpdatedAction()
})

//---------------------------------------------------------------------------------------

async function onUpdatedAction() {
  if (onUpdatedConsumed) return
  if (resultID) {
    onUpdatedConsumed = true
    const domElement = await findElementByDataId(resultID)
    if (domElement) {
      let record = records.value.find((element) => element.id === resultID)
      onHandleExpand(record, { currentTarget: domElement })
    } else {
      resultID = null
    }
  }
}

//---------------------------------------------------------------------------------------

const findElementByDataId = async (value) => {
  await nextTick() // Wait for the next DOM update

  return domElement.value.querySelector(`[data-id="${value}"]`)
}

//---------------------------------------------------------------------------------------

onMounted(() => {
  loadDataValidate()
})

//---------------------------------------------------------------------------------------

function createFieldset1(fieldSet, obj, index, resultItem) {
  fieldSet.fields = []

  //handle all fields

  //---------------------------------------------------

  let field = {}
  field.vif = true
  field.attributes = {}
  field.label = 'name'
  field.modelKey = 'name'
  field.modelTable = 'User'
  field.modelValue = obj?.name
  field.modelID = obj?.id
  field.component = 'b-form-input'
  field.index = `user_${index}_${fieldSet.fields.length}`
  fieldSet.fields.push(field)

  //---------------------------------------------------

  field = {}
  field.vif = true
  field.attributes = {}
  field.label = 'email'
  field.modelKey = 'value'
  field.modelTable = 'Email'
  let emailVal = null
  let emailID = null
  if (obj?.Emails?.length) {
    emailVal = obj.Emails[0].value
    emailID = obj.Emails[0].id
  } else {
    if (resultItem) {
      resultItem.alert = true
    }
  }

  field.modelValue = emailVal
  field.modelID = emailID
  if (!emailVal) {
    if (resultItem) {
      resultItem.alert = true
    }
  }
  field.component = 'b-form-input'
  field.index = `user_${index}_${fieldSet.fields.length}`
  fieldSet.fields.push(field)

  //---------------------------------------------------

  field = {}
  field.vif = true
  field.attributes = {}
  field.label = 'role'

  field.modelKey = 'value'
  field.modelTable = 'Role'
  let roles = []
  if (obj?.Roles) {
    obj.Roles.forEach((element) => {
      roles.push(element.value)
    })
  }
  field.modelValue = roles
  let rolesRef = reactive(field)
  field.modelID = obj?.id
  field.attributes.optionDisabled = (option) => {
    if (option === 'super_admin') {
      return !auth.hasPermission('assign_role_super_admin')
    }

    return false
  }
  field.attributes.options = ['super_admin', 'admin', 'owner', 'salesperson', 'staff']
  field.attributes.class = 'w-100 form-control d-inline-flex'

  field.component = 'MultiSelect'
  field.index = `user_${index}_${fieldSet.fields.length}`
  fieldSet.fields.push(field)

  //--------------------------------------------------

  field = {}
  field.vif = true
  field.attributes = {}
  field.attributes.binary = true
  field.label = 'active account'
  field.modelKey = 'active'
  field.modelTable = 'User'

  field.modelValue = obj?.active
  field.modelID = obj?.id
  field.component = 'checkbox'
  field.index = `user_${index}_${fieldSet.fields.length}`
  fieldSet.fields.push(field)

  //---------------------------------------------------

  /* if (!obj?.Owner?.started_at) {
      if (resultItem) {
        resultItem.alert = true
      }
    }*/

  field = {}
  field.attributes = {}
  field.label = 'started_at'
  field.vif = true
  field.vif = computed(() => {
    return rolesRef.modelValue.find(
      (element) => element === 'owner' || element === 'salesperson' || element === 'staff'
    )
  })
  field.modelKey = 'started_at'
  field.modelTable = 'dynamic'

  if (obj?.Owner) {
    if (obj?.Owner?.started_at) {
      let simpleDate = new SimpleDate()
      simpleDate.getValuesFromDateString(obj?.Owner?.started_at)
      field.modelValue = simpleDate.getDateString()
    } else {
      field.modelValue = null
    }

    field.modelID = obj?.Owner?.id
  }

  if (obj?.SalesPerson) {
    if (obj?.SalesPerson?.started_at) {
      let simpleDate = new SimpleDate()
      simpleDate.getValuesFromDateString(obj?.SalesPerson?.started_at)
      field.modelValue = simpleDate.getDateString()
    } else {
      field.modelValue = null
    }

    field.modelID = obj?.SalesPerson?.id
  }

  if (obj?.Staff) {
    if (obj?.Staff?.started_at) {
      let simpleDate = new SimpleDate()
      simpleDate.getValuesFromDateString(obj?.Staff?.started_at)
      field.modelValue = simpleDate.getDateString()
    } else {
      field.modelValue = null
    }

    field.modelID = obj?.Staff?.id
  }

  field.component = 'calendar'
  field.attributes.view = 'month'
  field.attributes.dateFormat = 'yy-mm-dd'
  field.index = `user_${index}_${fieldSet.fields.length}`
  fieldSet.fields.push(field)

  //---------------------------------------------------

  //---------------------------------------------------

  field = {}
  field.vif = computed(() => {
    return rolesRef.modelValue.find(
      (element) => element === 'owner' || element === 'salesperson' || element === 'staff'
    )
  })
  field.attributes = {}
  field.attributes.binary = true
  field.label = 'exclude from reports'
  field.modelKey = 'exclude_from_reports'
  field.modelTable = 'dynamic'
  field.modelValue = false

  if (obj?.Owner) {
    field.modelValue = obj?.Owner?.exclude_from_reports

    field.modelID = obj?.Owner?.id
  }

  if (obj?.SalesPerson) {
    field.modelValue = obj?.SalesPerson?.exclude_from_reports

    field.modelID = obj?.SalesPerson?.id
  }

  if (obj?.Staff) {
    field.modelValue = obj?.Staff?.exclude_from_reports

    field.modelID = obj?.Staff?.id
  }

  field.component = 'checkbox'
  field.index = `user_${index}_${fieldSet.fields.length}`
  fieldSet.fields.push(field)

  //--------------------------------------------------

  field = {}
  field.vif = computed(() => {
    return rolesRef.modelValue.find((element) => {
      return element === 'salesperson' || element === 'staff'
    })
  })
  field.attributes = {}
  field.label = 'owner'
  field.modelKey = 'owner_id'
  field.modelTable = 'dynamic'

  if (obj?.SalesPerson) {
    field.modelValue = obj?.SalesPerson?.owner_id
    field.modelID = obj?.id
  }

  if (obj?.Staff) {
    field.modelValue = obj?.Staff?.owner_id
    field.modelID = obj?.id
  }

  field.attributes.options = ownerOptions
  field.attributes['value-field'] = 'owner_id'
  field.component = 'b-form-select'
  field.index = `owner_salesperson_${index}_${fieldSet.fields.length}`
  fieldSet.fields.push(field)
}

function filterInviteListOption() {
  let allUsersNamesLocal = []
  records.value.forEach((element) => {
    if (filterInviteListTOUnInvited.value) {
      if (!element.inviteSentDate) {
        if (element.active) {
          allUsersNamesLocal.push(element.name)
        }
      }
    } else {
      if (element.active) {
        allUsersNamesLocal.push(element.name)
      }
    }
  })
  //need to clear selections because list has possibly changed
  selectedUsersInvite.value = []
  allUsersNames.value = allUsersNamesLocal
}

function processData(result) {
  ownerOptions = []

  for (const [index, obj] of result.owners.entries()) {
    ownerOptions.push({ text: obj.User.name, owner_id: obj.id })
  }

  let fieldSet = {}
  fieldSet.key = 'User'
  createFieldset1(fieldSet, null, 'create', null)
  createModelFieldSet.value = fieldSet

  let resultsParsed = []
  let allUsersNamesLocal = []
  for (const [index, obj] of result.users.entries()) {
    obj.name = obj.name.trim()

    //result item
    let resultItem = {}
    resultItem.id = obj.id
    resultItem.userPermissions = obj.Permissions

    resultItem.userRoles = obj.Roles
    resultItem.userPermissionStrings = []
    obj.Permissions.forEach((element) => {
      resultItem.userPermissionStrings.push(element.value)
    })
    resultItem.active = obj.active
    if (obj.active) {
      //dont push inactive accounts
      allUsersNamesLocal.push(obj.name)
    }
    resultItem.name = obj.name

    if (obj?.Emails?.length) {
      resultItem.email = obj.Emails[0].value
    }

    resultItem.toggleEdit = false

    let date_used = obj.UserInvite?.date_used
    resultItem.showInvitePanel = true
    resultItem.inviteStatusblurb = obj.UserInvite?.date_sent
      ? 'User has been invited.'
      : 'User has not been invited yet.'
    let sent_date = obj.UserInvite?.date_sent
    if (sent_date) {
      let sd = new SimpleDate()
      sd.getValuesFromDateString(sent_date)
      resultItem.inviteSentDate = sd.getDateTimeString()
    }

    resultItem.owner_id = obj.Owner?.id
    resultItem.sales_person_id = obj.SalesPerson?.id
    resultItem.staff_id = obj.Staff?.id

    //resultItem.parent_owner_id = obj.SalesPerson?.owner_id

    resultItem.aliasesOwner = obj.Owner?.DimensionAliasOwners
    resultItem.aliasesSalesPerson = obj.SalesPerson?.DimensionAliasSalesPeople
    resultItem.aliasesStaff = obj.SalesPerson?.DimensionAliasStaffs
    resultItem.ownerRoyaltyPercentages = obj.Owner?.OwnerRoyaltyPercentages

    if (obj.Owner) {
      let systemOwner = reportCore.dataOwners[obj.name]
      resultItem.defaultDataSource = systemOwner.UserDefaultDataSource
    }

    if (obj.SalesPerson) {
      let systemSalesPerson = reportCore.dataSalesPersons[obj.name]
      resultItem.defaultDataSource = systemSalesPerson.UserDefaultDataSource
    }

    if (obj.Staff) {
      let systemStaff = reportCore.dataStaffs[obj.name]
      resultItem.defaultDataSource = systemStaff.UserDefaultDataSource
    }

    if (resultID) {
      if (resultItem.id === resultID) {
        resultItem.toggleEdit = true
      }
    }
    resultItem.fieldSets = []
    let fieldSet = {}
    fieldSet.key = 'User'
    fieldSet.resultItemIndex = index

    createFieldset1(fieldSet, obj, index, resultItem)

    //do i need to list the locations here .. seems not used by dean
    /* if (obj.Owner?.Locations?.length) {
       let field = {}
       field.vif = true
       field.attributes = {}
       field.label = 'Location'
       field.attributes.payload = []
       for (let location of obj.Owner.Locations) {
         let val = location.name
         
         field.attributes.payload.push(val)
       }
 
       field.component = 'SimpleList'
 
       field.index = `user_${obj.id}_${index}_${fieldSet.fields.length}`
       fieldSet.fields.push(field)
       //
     }*/

    resultItem.fieldSets.push(fieldSet)

    //finally push resultItem
    resultsParsed.push(resultItem)
  }
  recordsKeyCurrent.value++
  records.value = resultsParsed

  allUsersNames.value = allUsersNamesLocal
}

//---------------------------------------------------------------------------------------

watch(() => route.query.filter, loadDataValidate)

function loadDataValidate() {
  if (route.path !== '/application/user_management') {
    reportCore.setLoadHeavy(false)
    return
  }

  loadData(urls.getURL('user_management', 'find'), { filter: route.query.filter || 'owner' })
}

//---------------------------------------------------------------------------------------
</script>
