import { markRaw } from 'vue'
import { useURLS } from '@/stores/URLS.js'
import { useReportCore } from '@/stores/ReportCore.js'
import SimpleDate from '@/scripts/utils/SimpleDate'
export default class KPIVerboseQuery3Class {
    constructor() {
     
    }

   

    async invalidate(model) {
     
        return await this.fetchData(model)
     
    }

    processData(result,model) {
    //  console.log('this.processData()')
    //  console.log(result)

      let storage = (model.processedData = markRaw({}))

      let i = result.length
      while (i--) {
        let element = result[i]

        let owner = storage[element.owner]
        if (!owner) {
          owner = storage[element.owner] = {}
          owner.contacts = {}
        }

        //contact on owner , not date related in storage , so finds contact kpi regardless of date filtering
        let contactObj = owner.contacts[element.contact_id]
        if (!contactObj) {
          contactObj = owner.contacts[element.contact_id] = {}
          contactObj.name = element.contact
          contactObj.id = element.contact_id
         
          contactObj.hasValidProposalDeclined = false
         
          contactObj.metricScore = 0
          contactObj.leadgroup = element.lead_group
          contactObj.sourceName = element.source === 'kpiVerbose' ? 'CORE' : 'SM'
          if (contactObj.sourceName === 'SM') {
            contactObj.location = element.location
          }
          contactObj.metricValids = [
            { label: 'L', valid: null },
            { label: 'A', valid: null },
            { label: 'IA', valid: null },
            { label: 'Q', valid: null },
            { label: 'S', valid: null },
            { label: 'S', valid: null }
          ]

          contactObj.kpiDataVerbose = []
        }

        if (element.related_date) {
          let sd = new SimpleDate()
          sd.getValuesFromDateString(element.related_date)
          element.related_date = sd
        } else {
          element.related_date = 'none'
        }

        //some hacks on element.metric_type
        if (element.metric_type.indexOf('proposalstatus') !== -1) {
          element.metric_type = element.metric_type.replace('proposalstatus_', 'proposal ')
        }

        if (element.metric_type.indexOf('extservice') !== -1) {
          element.metric_type = element.metric_type.replace('extservice', 'service ')
        }

        let relatedIDProp = 'related id'
        if (element.metric_type === 'lead') {
          relatedIDProp = 'lead id'
        }
        if (element.metric_type === 'appointment') {
          relatedIDProp = 'appt id'
        }
        if (element.metric_type === 'issued_appointment') {
          relatedIDProp = 'issued appt id'
        }
        if (element.metric_type === 'quote') {
          relatedIDProp = 'quote id'
        }
        if (element.metric_type === 'sale') {
          relatedIDProp = 'job id'
        }
        if (element.metric_type.indexOf('proposal') !== -1) {
          relatedIDProp = 'proposal id'
        }

        if (element.metric_type.indexOf('service') !== -1) {
          relatedIDProp = 'service id'
        }

        let relatedDateProp = 'related date'

        if (element.metric_type === 'quote') {
          relatedDateProp = 'accepted date'
        }
        if (element.metric_type === 'sale') {
          relatedDateProp = 'job date'
        }
        if (element.metric_type === 'proposal accepted') {
          relatedDateProp = 'accepted date'
        }

        if (element.metric_type.indexOf('service') !== -1) {
          relatedDateProp = 'service date'
        }

        if (element.date) {
          let sd = new SimpleDate()
          sd.getValuesFromDateString(element.date)
          element.date = sd
        }

        let kpiDataVerboseItem = {}
        kpiDataVerboseItem.date = element.date
        kpiDataVerboseItem.metric_type = element.metric_type
        kpiDataVerboseItem.metric = element.metric
        kpiDataVerboseItem.salesPerson = element.sales_person
        kpiDataVerboseItem[relatedIDProp] = element.related_id
        kpiDataVerboseItem[relatedDateProp] = element.related_date

        if (element.metric_type.indexOf('proposal') !== -1) {
          kpiDataVerboseItem.orderIndex = 4
        }

        contactObj.kpiDataVerbose.push(kpiDataVerboseItem)

        if (element.metric_type === 'lead') {
          if (!contactObj.metricValids[0].valid) {
            contactObj.metricScore++
          }
          kpiDataVerboseItem.orderIndex = 0
          contactObj.metricValids[0].valid = true //removed for now as leads are aggregated
        }
        if (element.metric_type === 'appointment') {
          if (!contactObj.metricValids[1].valid) {
            contactObj.metricScore++
          }
          kpiDataVerboseItem.orderIndex = 1
          contactObj.metricValids[1].valid = true
        }
        if (element.metric_type === 'issued_appointment') {
          if (!contactObj.metricValids[2].valid) {
            contactObj.metricScore++
          }
          kpiDataVerboseItem.orderIndex = 2
          contactObj.metricValids[2].valid = true
        }
        if (element.metric_type === 'quote') {
          if (!contactObj.metricValids[3].valid) {
            contactObj.metricScore++
          }
          kpiDataVerboseItem.orderIndex = 3
          contactObj.metricValids[3].valid = true
        }

        if (element.metric_type === 'sale') {
          if (!contactObj.metricValids[4].valid) {
            contactObj.metricScore += 10
          }
          kpiDataVerboseItem.orderIndex = 5
          contactObj.metricValids[4].valid = true
          contactObj.hasValidSale = true
        }

        if (
          element.metric_type === 'service Services' ||
          element.metric_type === 'service Change Order'
        ) {
          if (!contactObj.metricValids[5].valid) {
            contactObj.metricScore++
          }
          kpiDataVerboseItem.orderIndex = 6
          contactObj.metricValids[5].valid = true
        }

        //handle services idividualy as well to set is valid

       

        if (element.metric_type.indexOf('proposal declined') !== -1) {
            contactObj.hasValidProposalDeclined = true
          }
      }

      for (let ownerKey in storage) {
        let ownerItem = storage[ownerKey]

        for (let contactKey in ownerItem.contacts) {
          let contactItem = ownerItem.contacts[contactKey]
          if (contactItem.kpiDataVerbose) {
            contactItem.kpiDataVerbose.sort((a, b) => {
              const dataDiff = b.date.valueOf() - a.date.valueOf()
              if (dataDiff !== 0) {
                return dataDiff // Primary sort by date
              }
              //secondary sort by orderIndex , this allows kpi items that sahre a same date to still be displayed in order of a projects general flow
              return b.orderIndex - a.orderIndex
            })

            contactItem.kpiDataVerbose.forEach((element, index) => {
              delete element.orderIndex


              if (contactItem.hasValidProposalDeclined) {
               
                if (element.metric_type.indexOf('proposal declined') !== -1) {
                  if (!contactItem.proposalDeclinedKPIItemIndexs) {
                    contactItem.proposalDeclinedKPIItemIndexs = []
                  }
                  contactItem.sortDateValueOf = Math.max(contactItem.sortDateValueOf||0,element.date.valueOf())
                  contactItem.proposalDeclinedKPIItemIndexs.push(index)
                }
               
              }


             

             
             
            })
          }
        }
      }

      return storage
    }

  

    async fetchData(model) {
     // console.log('this.fetchData()')

      

      let reportCore = markRaw(useReportCore())
      let urls = markRaw(useURLS())

      if (model.optionsCache.salesPersonFilter) {
        if (model.optionsCache.salesPersonFilter.indexOf('NO Sales Person') !== -1) {
          model.optionsCache.salesPersonFilter = 'null'
        }
      }

      if (model.optionsCache.leadGroupFilter) {
        if (model.optionsCache.leadGroupFilter.indexOf('NO Leadgroup') !== -1) {
          model.optionsCache.leadGroupFilter = 'null'
        }
      }

      if (!model.optionsCache.metricFilters) {
        model.optionsCache.metricFilters = [
          'sale',
          'extserviceService',
          'extserviceChange Order'
        ]
      }

      


     

      var data = {
        ownerFilter: model.optionsCache.ownerFilter,
        leadGroupFilter: model.optionsCache.leadGroupFilter,
        salesPersonFilter: model.optionsCache.salesPersonFilter,
        dateRangeStart: reportCore.dateRangeStart.toString(),
        dateRangeEnd: reportCore.dateRangeEnd.toString(),
        metricFilters: model.optionsCache.metricFilters
      }

      if (reportCore.useDataSourceOverride) {
        if (reportCore.dataSourceCurrent === 'DATA_SOURCE_BLEND') {
          data.blendDateOverride = reportCore.dataBlendDate
        }
        data.sourceOverride = reportCore.dataSourceCurrent
      }

      var urlToUse = urls.getURL('kpi_verbose', 'reportCancellations')
      try {
        let result = await reportCore.loadServerData(urlToUse, data)
        if (result) {
          this.processData(result,model)
        }
      } catch (error) {
        console.log(error)
      }
    }
  }